import { ToastController, AlertController, Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
// Execute a function only when certain events on certain target element have or have not occured within given timeout.
import { Idle } from 'idlejs';
import {
  parseISO,
  differenceInYears,
  addYears,
  differenceInMonths,
  addMonths,
  differenceInDays,
  addDays,
} from 'date-fns';
import * as $ from 'jquery';
import { Keyboard } from '@ionic-native/keyboard/ngx';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  // Pagination
  page: number = 0;
  count: number = 0;
  tableSize: number = 15;
  tableSizes: any = [15, 20, 25, 30];

  isDesktop = true;
  isTablet = false;

  momentjs: any = moment;

  currentDate: string = this.momentjs(new Date()).format('YYYYMMDD');
  currentDate1: string = this.momentjs(new Date()).format('YYYY-MM-DD');

  // Recupère le compte : Administrateur ou SECRETAIRE
  userId: string;
  name: string;
  username: string;
  email: string;
  compte: string;
  agentSante: string;
  medecin: string;
  onm: string;

  // Collapse
  isVisible = false;
  chevron = 'chevron-forward-outline';

  isVisibleDM = false;

  changeType() {
    this.isVisibleDM = !this.isVisibleDM;
  }

  constructor(
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private platform: Platform,
    private keyboard: Keyboard
  ) {}

  checkInteractive(action: any) {
    const idle = new Idle()
      .whenNotInteractive()
      .within(10)
      .do(() => action)
      .start();
  }

  getSession() {
    this.userId = localStorage.getItem('userId');
    this.name = localStorage.getItem('name');
    this.username = localStorage.getItem('username');
    this.email = localStorage.getItem('email');
    this.compte = localStorage.getItem('compte');
    this.agentSante = localStorage.getItem('agentSante');
    this.medecin = localStorage.getItem('medecin');
    this.onm = localStorage.getItem('onm');
  }

  public async toastError(a) {
    const toast = await this.toastCtrl.create({
      message: a,
      duration: 1500,
      position: 'bottom',
      color: 'danger',
      cssClass: 'toast-class',
    });
    await toast.present();
  }

  async toast(a) {
    const toast = await this.toastCtrl.create({
      message: a,
      duration: 1000,
      position: 'bottom',
      color: 'success',
      cssClass: 'toast-class',
    });
    toast.present();
  }

  async alert(a, b, c) {
    const alert = await this.alertCtrl.create({
      header: a,
      message: b,
      backdropDismiss: false,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Réessayer',
          handler: () => {
            c;
          },
        },
      ],
    });

    await alert.present();
  }

  async presentAlert(a, b) {
    const alert = await this.alertCtrl.create({
      header: a,
      message: b,
      cssClass: 'alert-class',
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }
  toastAffiche() {
    this.toastError(
      "Vous ne pouvez pas effectuer cette opération car vous n'êtes pas le prescripteur"
    );
  }

  fullAge(date) {
    const result = [];
    const now = new Date();
    let age = parseISO(date);

    const years = differenceInYears(now, age);
    if (years > 0) {
      result.push(`${years} ans`);
      age = addYears(age, years);
    }

    const months = differenceInMonths(now, age);
    if (months > 0) {
      result.push(`${months} mois`);
      age = addMonths(age, months);
    }

    const days = differenceInDays(now, age);
    if (days > 0) {
      result.push(`${days} jours`);
    }

    return result.join(', ');
  }

  age(date) {
    let result = 0;
    const now = new Date();
    let age = parseISO(date);

    let years = differenceInYears(now, age);
    let months = differenceInMonths(now, age);
    let days = differenceInDays(now, age);
    if (years > 0) {
      result = years;
      age = addYears(age, years);
    } else if (years == 0 && months > 0) {
      result = months / 12;
      age = addMonths(age, months);
    } else if (years == 0 && months == 0 && days > 0) {
      result = days / 365;
      age = addDays(age, days);
    }

    return result;
  }

  onTableDataChange(event: any, service: any) {
    this.page = event;
    service;
  }

  onTableSizeChange(event: any, service: any) {
    this.tableSize = event.target.value;
    this.page = 1;
    service;
  }

  onClearSearch(ev, service: any) {
    // Reset the field
    ev.target.value = '';
    service;
  }

  dateFormat(date) {
    return this.momentjs(date).format('YYYYMMDD');
    // return format(date, 'yyyyMMdd');
  }

  formatMois(date) {
    return this.momentjs(date).format('MMMM YYYY');
    // return format(date, 'mmmm');
  }

  formatAnnee(date) {
    return this.momentjs(date).format('YYYY');
    // return format(new Date(date), 'yyyy');
  }

  formatDate(date) {
    return this.momentjs(date).format('YYYY-MM-DD');
    // return format(date, 'yyyy-MM-dd');
  }

  formatD(date) {
    return this.momentjs(date).format('DD/MM/YYYY');
  }

  formatMY(date) {
    return this.momentjs(date).format('MMM YYYY');
  }

  formatHeure(date) {
    return this.momentjs(date).format('HH:mm');
  }

  formatDateHour(date) {
    return this.momentjs(date).format('DD MMM YYYY à HH:mm');
  }

  checkTablet() {
    this.platform.ready().then(() => {
      if (this.platform.is('tablet')) {
        this.isDesktop = false;
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
    });
  }

  checkPlatform() {
    this.platform.ready().then(() => {
      if (this.platform.is('desktop')) {
        this.isDesktop = true;
        this.isTablet = true;
      } else {
        this.isDesktop = false;

        if (this.platform.is('android')) {
          this.keyboard.onKeyboardShow().subscribe((e) => {
            const offset = $(document.activeElement).offset().top;
            let height = (offset - e.keyboardHeight) * -1;
            height = height > 0 ? 0 : height;
            $('body').animate({ marginTop: height + 'px' }, 100);
          });
          this.keyboard.onKeyboardHide().subscribe((e) => {
            $('body').animate({ marginTop: 0 + 'px' }, 100);
          });
        }
      }
    });
  }

  // Helper function to format if you don't use moment.js or something alike
  private Formatdate(date: Date) {
    const day = (date && date.getDate()) || -1;
    const dayWithZero = day.toString().length > 1 ? day : '0' + day;
    const month = (date && date.getMonth() + 1) || -1;
    const monthWithZero = month.toString().length > 1 ? month : '0' + month;
    const year = (date && date.getFullYear()) || -1;

    return `${year}-${monthWithZero}-${dayWithZero}`;
  }
}
