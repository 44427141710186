import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { ExamenService } from 'src/app/services/examen.service';
import { PatientService } from 'src/app/services/patient.service';
import { RMAService } from 'src/app/services/rma.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ProtocoleComponent } from '../../protocole/protocole.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-actes-medicaux',
  templateUrl: './actes-medicaux.component.html',
  styleUrls: ['./actes-medicaux.component.scss'],
})
export class ActesMedicauxComponent implements OnInit {
  readonly = true;
  users$ = [];
  constructor(
    public accessProviders: AccessProviders,
    public utils: UtilsService,
    private modalCtrl: ModalController,
    public examen: ExamenService,
    private storage: StorageService,
    public patientService: PatientService,
    public userService: UserService,
    private rmaService: RMAService
  ) {
    this.examen.readonly = true;
    this.checkCTR();
  }

  ngOnInit() {
    this.storage.removeData('protocole');
    // this.examen.clearInput();
    this.userService.loadPS();

    this.userService._ps$.subscribe((users: any[]) => {
      this.users$ = users;
    });
  }

  checkCTR() {
    // CTR
    if (this.patientService.nbDossierPatient != 0) {
      this.examen.getDossier();
      this.examen.dateConsult = '';
      this.examen.csID = 0; // IdConsultation
      this.examen.isControle = true;
      this.examen.isUpdate = false;
      this.examen.motif = 'Acte medical';
      this.examen.diagnostic = 'AM';
    } else {
      this.utils.toastError(
        "Le patient n'a pas encore un dossier.\nVous ne pouvez pas effectuer cette opération"
      );
    }
  }

  async openProtocole() {
    const modal = await this.modalCtrl.create({
      component: ProtocoleComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
    }
  }

  addConsult() {
    this.examen.crudConsult('CREATE');
    this.rmaService.addRMA_CTR(this.examen.dateConsult);
  }
}
