import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-dossier',
  templateUrl: './dossier.component.html',
  styleUrls: ['./dossier.component.scss'],
})
export class DossierComponent implements OnInit {
  patient: any; 

  isVisible = true;
  visibility = 'eye-outline';

  constructor(
    public utils: UtilsService,
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) { 
    this.patient = this.navParams.get('patient');
  }

  ngOnInit() { }

  dismiss(){
    this.modalCtrl.dismiss(null, '');
  }
}
