import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { RMAService } from 'src/app/services/rma.service';
import { UtilsService } from 'src/app/services/utils.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import domtoimage from 'dom-to-image';

@Component({
  selector: 'app-rma-list',
  templateUrl: './rma-list.component.html',
  styleUrls: ['./rma-list.component.scss'],
})
export class RmaListComponent implements OnInit {
  @ViewChild('pdfTable') pdfTable!: ElementRef;
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  weekStartsOn = DAYS_OF_WEEK.MONDAY;

  activeIndex = 1;
  times: string = '1';
  rupture: any;

  constructor(public rmaService: RMAService, public utils: UtilsService) {
    this.loadRMA(format(Date.now(), 'yyyyMM'));

    // this.utils.checkTablet();
  }

  ngOnInit() {}

  print(printSectionId: string) {
    let popupWinindow;
    let innerContents = document.getElementById(printSectionId).innerHTML;
    popupWinindow = window.open(
      '',
      '_blank',
      'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
    );
    popupWinindow.document.open();
    popupWinindow.document.write(
      '<html><head><link href="assets/css/bootstrap.min.css" rel="stylesheet" /><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' +
        innerContents +
        '</html>'
    );
    popupWinindow.document.close();
  }

  generate() {
    const doc = new jsPDF('p', 'mm', 'a4');

    // Simple html example
    autoTable(doc, { html: '#sectionPrint' });

    doc.save('table.pdf');
  }

  async printPage() {
    const doc = new jsPDF('p', 'mm', 'a4');
    const marginsParent = { top: 105, bottom: 35, left: 15, right: 15 };
    doc.setFontSize(5);
    doc.setPage(1);

    var coolCanvas = document.getElementById(
      'coolCanvas'
    )! as HTMLCanvasElement;
    var newCanvasImg = coolCanvas!.toDataURL('image/png', 1.0)!;
    doc.addImage(newCanvasImg, 'PNG', 10, 10, 190, 90);

    let page = document.getElementById(`sectionPrint`) as HTMLTableElement;
    autoTable(doc, {
      html: page,
      margin: marginsParent,
      useCss: false,
      theme: 'plain',
      styles: {
        lineColor: [44, 62, 80],
        lineWidth: 1,
      },
      headStyles: {
        fillColor: [241, 196, 15],
        fontSize: 15,
      },
      footStyles: {
        fillColor: [241, 196, 15],
        fontSize: 15,
      },
      bodyStyles: {
        fillColor: [52, 73, 94],
        textColor: 240,
      },
      alternateRowStyles: {
        fillColor: [74, 96, 117],
      },
    });
    await doc.output('dataurlnewwindow', { filename: 'listagem.pdf' });
  }

  public downloadAsPDF2() {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      table: {
        width: 100,
      },
    });
    const documentDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      content: html,
      // style: {
      //   header: {
      //     fontSize: 22,
      //     bold: true,
      //   },
      //   b: { bold: true },
      //   strong: { bold: true },
      //   u: { decoration: 'underline' },
      //   s: { decoration: 'lineThrough' },
      //   em: { italics: true },
      //   i: { italics: true },
      //   h1: { fontSize: 22, bold: true, marginBottom: 5 },
      //   h2: { fontSize: 20, bold: true, marginBottom: 5 },
      //   h3: { fontSize: 18, bold: true, marginBottom: 5 },
      //   h4: { fontSize: 16, bold: true, marginBottom: 5 },
      //   h5: { fontSize: 14, bold: true, marginBottom: 5 },
      //   h6: { fontSize: 12, bold: true, marginBottom: 5 },
      //   a: { color: 'blue', decoration: 'underline' },
      //   strike: { decoration: 'lineThrough' },
      //   p: { fontSize: 12, bold: true, margin: [0, 5, 0, 10] },
      //   ul: { marginBottom: 5 },
      //   li: { marginLeft: 5 },
      //   table: { marginBottom: 5 },
      //   th: { bold: true, fillColor: '#EEEEEE' },
      //   // path: './rma.page.scss',
      // },
    };
    pdfMake.createPdf(documentDefinition).download();
  }

  public downloadAsPDF() {
    let div = this.pdfTable.nativeElement;

    var img: any;
    var filename;
    var newImage: any;

    domtoimage
      .toPng(div, { bgcolor: '#fff' })

      .then(function (dataUrl) {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;

        img.onload = function () {
          var pdfWidth = img.width;
          var pdfHeight = img.height;

          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image

          var doc;

          if (pdfWidth > pdfHeight) {
            doc = new jsPDF('l', 'px', [pdfWidth, pdfHeight]);
          } else {
            doc = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);
          }

          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();

          doc.addImage(newImage, 'PNG', 10, 10, width, height);
          filename = 'mypdf_' + '.pdf';
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
  }

  public exportHtmlToPDF() {
    let data = this.pdfTable.nativeElement;

    html2canvas(data).then((canvas) => {
      let docWidth = 208;
      let docHeight = (canvas.height * docWidth) / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      let doc = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      doc.addImage(contentDataURL, 'PNG', 0, position, docWidth, docHeight);

      doc.save('exportedPdf.pdf');
    });
  }

  Export2Word(element, filename = '') {
    var preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = '</body></html>';
    var html = preHtml + document.getElementById(element).innerHTML + postHtml;

    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    var url =
      'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + '.doc' : 'document.doc';

    // Create download link element
    var downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    // if (navigator.msSaveOrOpenBlob) {
    //   navigator.msSaveOrOpenBlob(blob, filename);
    // } else {
    // Create a link to the file
    downloadLink.href = url;

    // Setting the file name
    downloadLink.download = filename;

    //triggering the function
    downloadLink.click();
    // }

    document.body.removeChild(downloadLink);
  }

  setIndex(index) {
    this.activeIndex = index;
  }

  getValue(times) {
    console.log(times.target.value);
  }

  loadRMA(date) {
    this.rmaService.totalNC = 0;
    this.rmaService.totalTR = 0;
    this.rmaService.totalCTR = 0;
    this.rmaService.totalGEN = 0;
    this.rmaService.totalD = 0;
    this.rmaService.totalP = 0;

    this.rmaService.totalF1 = 0;
    this.rmaService.totalRDT1 = 0;
    this.rmaService.totalMicro1 = 0;
    this.rmaService.total101_1 = 0;
    this.rmaService.total110_1 = 0;
    this.rmaService.total111_1 = 0;
    this.rmaService.totalMicro_p1 = 0;
    this.rmaService.totalM1 = 0;
    this.rmaService.totalFm1 = 0;
    this.rmaService.totalACT1 = 0;
    this.rmaService.totalPrima1 = 0;
    this.rmaService.totalArte_suppo1 = 0;
    this.rmaService.totalArte_cp1 = 0;

    this.rmaService.totalF2 = 0;
    this.rmaService.totalRDT2 = 0;
    this.rmaService.totalMicro2 = 0;
    this.rmaService.total101_2 = 0;
    this.rmaService.total110_2 = 0;
    this.rmaService.total111_2 = 0;
    this.rmaService.totalMicro_p2 = 0;
    this.rmaService.totalM2 = 0;
    this.rmaService.totalFm2 = 0;
    this.rmaService.totalACT2 = 0;
    this.rmaService.totalPrima2 = 0;
    this.rmaService.totalArte_suppo2 = 0;
    this.rmaService.totalArte_cp2 = 0;

    this.rmaService.totalF3 = 0;
    this.rmaService.totalRDT3 = 0;
    this.rmaService.totalMicro3 = 0;
    this.rmaService.total101_3 = 0;
    this.rmaService.total110_3 = 0;
    this.rmaService.total111_3 = 0;
    this.rmaService.totalMicro_p3 = 0;
    this.rmaService.totalM3 = 0;
    this.rmaService.totalFm3 = 0;
    this.rmaService.totalACT3 = 0;
    this.rmaService.totalPrima3 = 0;
    this.rmaService.totalArte_suppo3 = 0;
    this.rmaService.totalArte_cp3 = 0;

    this.rmaService.totalF4 = 0;
    this.rmaService.totalRDT4 = 0;
    this.rmaService.totalMicro4 = 0;
    this.rmaService.total101_4 = 0;
    this.rmaService.total110_4 = 0;
    this.rmaService.total111_4 = 0;
    this.rmaService.totalMicro_p4 = 0;
    this.rmaService.totalM4 = 0;
    this.rmaService.totalFm4 = 0;
    this.rmaService.totalACT4 = 0;
    this.rmaService.totalPrima4 = 0;
    this.rmaService.totalArte_suppo4 = 0;
    this.rmaService.totalArte_cp4 = 0;

    this.rmaService.totalF5 = 0;
    this.rmaService.totalRDT5 = 0;
    this.rmaService.totalMicro5 = 0;
    this.rmaService.total101_5 = 0;
    this.rmaService.total110_5 = 0;
    this.rmaService.total111_5 = 0;
    this.rmaService.totalMicro_p5 = 0;
    this.rmaService.totalM5 = 0;
    this.rmaService.totalFm5 = 0;
    this.rmaService.totalACT5 = 0;
    this.rmaService.totalPrima5 = 0;
    this.rmaService.totalArte_suppo5 = 0;
    this.rmaService.totalArte_cp5 = 0;

    this.rmaService.totalF = 0;
    this.rmaService.totalRDT = 0;
    this.rmaService.totalMicro = 0;
    this.rmaService.total101 = 0;
    this.rmaService.total110 = 0;
    this.rmaService.total111 = 0;
    this.rmaService.totalMicro_p = 0;
    this.rmaService.totalM = 0;
    this.rmaService.totalFm = 0;
    this.rmaService.totalACT = 0;
    this.rmaService.totalPrima = 0;
    this.rmaService.totalArte_suppo = 0;
    this.rmaService.totalArte_cp = 0;

    this.rmaService.getRMA_A(date);
    this.rmaService.getRMA_B(date);
    this.rmaService.getRMA_C(date);
    this.rmaService.getRMA_MC(date);
    this.rmaService.getRMA_TRAUMA(date);
    this.rmaService.getSUM_NC(date);
    this.rmaService.getSUM_AC(date);
    this.rmaService.getSUM_CTR(date);
    this.rmaService.getSUM_GEN(date);
    this.rmaService.getSUM_PCIME(date);
    this.rmaService.getSUM_PALU(date);
    this.rmaService.getSUM_PALU_1(date);
    this.rmaService.getSUM_PALU_2(date);
    this.rmaService.getSUM_PALU_3(date);
    this.rmaService.getSUM_PALU_4(date);
    this.rmaService.getSUM_PALU_5(date);
  }

  exportHTML() {
    var header =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'>" +
      "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
    var footer = '</body></html>';
    var sourceHTML =
      header + document.getElementById('docHtml').innerHTML + footer;

    var source =
      'data:application/vnd.ms-word;charset=utf-8,' +
      encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement('a');
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = 'document.doc';
    fileDownload.click();
    document.body.removeChild(fileDownload);
  }
}
