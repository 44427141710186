import { PatientService } from 'src/app/services/patient.service';
import { ModalController, NavParams } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class OptionComponent implements OnInit {
  @Input() patient: any;

  constructor(
    private modalCtrl: ModalController,
    public patientService: PatientService,
    private navParams: NavParams
  ) {
    this.patientService.patient = this.navParams.get('patient');
  }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
