import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import PizZipUtils from 'pizzip/utils/index.js';
import { CertificatDisplayComponent } from './../certificat-display/certificat-display.component';
import {
  EditorChangeContent,
  EditorChangeSelection,
  QuillEditorComponent,
} from 'ngx-quill';
import { saveAs } from 'file-saver';
import Delta from 'quill-delta';
import * as quillToWord from 'quill-to-word';
import { StorageService } from './../../../../services/storage.service';
import * as moment from 'moment';
import { format } from 'date-fns';
import { UploadService } from './../../../../services/upload.service';
import { FilesService } from './../../../../services/files.service';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

@Component({
  selector: 'app-certificat',
  templateUrl: './certificat.component.html',
  styleUrls: ['./certificat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CertificatComponent implements OnInit {
  @ViewChild(QuillEditorComponent) editor: QuillEditorComponent;
  data = null;
  content;
  nomFichier;
  patient = {
    id: '',
    nom: '',
  };

  momentjs: any = moment;

  constructor(
    private modalCtrl: ModalController,
    public utils: UtilsService,
    private storage: StorageService,
    private uploadService: UploadService,
    private fileService: FilesService
  ) {
    this.storage.getData('p_id').then((p: any) => {
      this.patient.nom = p.nom;
      this.patient.id = p.id;
    });
  }

  ngOnInit(): void {}

  ionViewDidEnter() {}

  addContent() {
    // // this.editor.quillEditor.setText('');
    // this.content = '<p><b>Test</b></p>';
    // //  //fill this variable with what comes from your database
    // localStorage.setItem('html', this.content.html);

    // // this.editor.quillEditor.setText(this.content);
    // this.editor.quillEditor.setHTML(this.content);

    this.editor.quillEditor.updateContents(
      new Delta()
        .delete(5)
        .insert('<p><b>bTest</b></p>')
        .retain(1, { bold: true })
    );
  }

  async apercu() {
    const modal = await this.modalCtrl.create({
      component: CertificatDisplayComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        data: this.data,
      },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'cs-modal-web' : 'win4-modal-mobile',
    });

    await modal.present();
  }

  private quillToWordConfig: quillToWord.Config = {
    exportAs: 'blob',
  };
  async exportWord() {
    this.nomFichier = `${this.patient.nom}-${format(
      new Date(),
      'yyMMddHMS'
    )}.docx`;

    const delta = this.editor.quillEditor.getContents();
    const blob = await quillToWord.generateWord(delta, this.quillToWordConfig);
    saveAs(blob, `${this.nomFichier}`);
    this.uploadFile();
  }

  created(event: any) {
    console.log(event);
    var html = localStorage.getItem('html');
    if (html != null) {
      event.root.innerHTML = html;
    }
  }

  changedEditor(event: EditorChangeContent | EditorChangeSelection) {}

  contentChanged(obj: any) {
    localStorage.setItem('html', obj.html);
  }

  uploadFile() {
    // Upload file
    const formData = new FormData();
    formData.append('id', this.patient.id);
    formData.append('filename', this.nomFichier);

    this.uploadService.uploadFile(formData).subscribe(
      (res) => {
        this.fileService.nomFichier = res.filename;

        // save document
        this.fileService.saveFile();
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
