import { Component, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { RMAService } from 'src/app/services/rma.service';

@Component({
  selector: 'app-frequentation',
  templateUrl: './frequentation.component.html',
  styleUrls: ['./frequentation.component.scss'],
})
export class FrequentationComponent implements OnInit {
  constructor(public rmaService: RMAService) {}

  ngOnInit() {}
}
