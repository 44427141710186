import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-table-patient',
  templateUrl: './table-patient.component.html',
  styleUrls: ['./table-patient.component.scss'],
})
export class TablePatientComponent implements OnInit {
  patients: any = [];
  momentjs: any = moment;

  constructor(
    public accessProviders: AccessProviders,
    public platform: Platform,
    public router: Router,
    public utils: UtilsService,
    public patientService: PatientService
  ) {
    this.utils.checkPlatform();
    this.patientService.load10Last();
  }

  ngOnInit(): void {
    this.patientService._lastPatients$.subscribe((patients: any[]) => {
      this.patients = patients;
    });
  }
}
