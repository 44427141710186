import { ATCDService } from 'src/app/services/atcd.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Router } from '@angular/router';
import { AtcdCrudComponent } from './../../crud/atcd-crud/atcd-crud.component';
import { Component, Input, OnInit } from '@angular/core';
import { NavParams, ModalController, Platform } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-atcd',
  templateUrl: './atcd.component.html',
  styleUrls: ['./atcd.component.scss'],
})
export class AtcdComponent implements OnInit {
  @Input() patient: any;
  isEmpty: boolean = true;
  visible = true;

  readonly = true;

  isVisible = false;
  chevron = 'chevron-forward-outline';

  constructor(
    public accessProviders: AccessProviders,
    public platform: Platform,
    private navParams: NavParams,
    public utils: UtilsService,
    public atcdService: ATCDService,
    private storage: StorageService
  ) {
    this.utils.checkPlatform();
    this.storage.getData('p_id').then((p: any) => {
      this.patient = p;

      // For ATCD Service
      this.atcdService.patient = this.patient;
      this.atcdService.patientID = this.patient.id;
      this.atcdService.loadATCD();
    });
  }

  async ngOnInit() {
    this.utils.getSession();
  }

  collapse() {
    this.isVisible = !this.isVisible;
    if (this.isVisible == true) {
      this.chevron = 'chevron-down-outline';
      this.atcdService.initATCD();
    } else {
      this.chevron = 'chevron-forward-outline';
    }
  }

  updateATCD() {
    this.readonly = !this.readonly;
  }

  save() {
    if (this.readonly == true) {
      this.utils.toastError('Pas de modification à enregistrer');
    } else {
      this.atcdService.crudAction();
      this.readonly = true;
      this.isVisible = false;
    }
  }
}
