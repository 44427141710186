import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss'],
})
export class PatientInfoComponent implements OnInit {
  patient;
  constructor(
    private modalCtrl: ModalController,
    public utils: UtilsService,
    private navParams: NavParams
  ) {
    this.patient = this.navParams.get('patient');
  }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
