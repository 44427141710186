import { AppService } from './../../../services/app.service';
import { UserService } from './../../../services/user.service';
import { UtilsService } from './../../../services/utils.service';
import { AccessProviders } from './../../../providers/access-providers.service';
import {
  ModalController,
  NavParams,
  LoadingController,
  AlertController,
} from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-assign-rdv',
  templateUrl: './assign-rdv.component.html',
  styleUrls: ['./assign-rdv.component.scss'],
})
export class AssignRdvComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public utils: UtilsService,
    public userService: UserService,
    public appService: AppService
  ) {
    // Recupere RDV
    this.appService.rdv = this.navParams.get('rdv');
  }

  ngOnInit() {
    this.userService.loadDoctors();
  }
}
