import { Component, OnInit } from '@angular/core';
import { RMAService } from 'src/app/services/rma.service';

@Component({
  selector: 'app-trauma',
  templateUrl: './trauma.component.html',
  styleUrls: ['./trauma.component.scss'],
})
export class TraumaComponent implements OnInit {
  constructor(public rmaService: RMAService) {}

  ngOnInit() {}
}
