import { EncaissementComponent } from './../components/caisse/encaissement/encaissement.component';
import {
  ModalController,
  LoadingController,
  AlertController,
} from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { Injectable } from '@angular/core';
import { NgxNumToWordsService, SUPPORTED_LANGUAGE } from 'ngx-num-to-words';
import * as moment from 'moment';
import { ProductService } from './product.service';
import { CaisseSortieCrudComponent } from '../components/caisse/caisse-sortie-crud/caisse-sortie-crud.component';
import { format } from 'date-fns';
import { log } from './../../../node_modules/echarts/lib/util/log';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CaisseService {
  date1;
  date2;
  dateRange;
  isEmpty = false;
  date;
  total = 0;
  totalCS = 0;
  totalActe = 0;
  totalAutre = 0;
  totalMedoc = 0;
  mois = [];

  totalLtr = '';

  // Caisse Detail
  totalD = 0;
  totalDLtr = '';

  pointOfSales = [];
  isLoading = true;
  isCEEmpty = false;
  isCSEmpty = false;
  isCCEmpty = false;
  totalG = 0;
  totalGLtr = '';
  totalL = '';
  caisseJ = [];
  totalRecette = 0;
  totalDepense = 0;
  totalCreance = 0;
  caisses = [];
  caisse10J = [];

  numberInWords!: string;
  lang: SUPPORTED_LANGUAGE = 'fr';

  momentjs: any = moment;

  others: any = [];
  filteredData = [];
  filteredCreance = [];

  caisseEntrees = []; // CE
  caisseSorties = []; // CS
  caisseCreances = [];
  caisseHistorique = [];

  // Caisse Detail
  caisseDetail = [];
  caisseD = [];
  caisseDID;

  detail = {
    date: '',
    designation: '',
    qte: '',
    pu: '',
    montant: '',
    p_id: '',
  };

  sortie = {
    date: '',
    motif: '',
    montant: '',
  };

  // Grand total
  totalRec = 0;
  totalDep = 0;
  totalConsult = 0;
  totalAct = 0;
  totalOther = 0;
  totalMed = 0;

  // Caisse 10 jours
  totalRec10 = 0;
  totalDep10 = 0;

  ////////// OBSERVABLES /////////////
  _pointOfSales = new BehaviorSubject<any[]>([]);
  _caisseJ = new BehaviorSubject<any[]>([]);
  _caisses = new BehaviorSubject<any[]>([]);
  _caisse10J = new BehaviorSubject<any[]>([]);
  _caisseEntrees = new BehaviorSubject<any[]>([]);
  _caisseSorties = new BehaviorSubject<any[]>([]);
  _caisseCreances = new BehaviorSubject<any[]>([]);

  _totalRecette = new BehaviorSubject<number>(0);
  _totalDepense = new BehaviorSubject<number>(0);
  _total = new BehaviorSubject<number>(0);
  _totalG = new BehaviorSubject<number>(0);

  _totalCS = new BehaviorSubject<number>(0);
  _totalActe = new BehaviorSubject<number>(0);
  _totalMedoc = new BehaviorSubject<number>(0);
  _totalAutre = new BehaviorSubject<number>(0);

  //////////// ASSECEURS ////////////
  get _pointOfSales$() {
    return this._pointOfSales.asObservable();
  }

  get _caisseJ$() {
    return this._caisseJ.asObservable();
  }

  get _caisses$() {
    return this._caisses.asObservable();
  }

  get _caisse10J$() {
    return this._caisse10J.asObservable();
  }

  get _caisseEntrees$() {
    return this._caisseEntrees.asObservable();
  }

  get _caisseSorties$() {
    return this._caisseSorties.asObservable();
  }

  get _caisseCreances$() {
    return this._caisseCreances.asObservable();
  }

  get _totalRecette$() {
    return this._totalRecette.asObservable();
  }

  get _totalDepense$() {
    return this._totalDepense.asObservable();
  }

  get _total$() {
    return this._total.asObservable();
  }

  get _totalG$() {
    return this._totalG.asObservable();
  }

  get _totalCS$() {
    return this._totalCS.asObservable();
  }

  get _totalActe$() {
    return this._totalActe.asObservable();
  }

  get _totalMedoc$() {
    return this._totalMedoc.asObservable();
  }

  get _totalAutre$() {
    return this._totalAutre.asObservable();
  }
  //////////// ASSECEURS ////////////

  constructor(
    private accessProviders: AccessProviders,
    public ngxNumToWordsService: NgxNumToWordsService,
    private utils: UtilsService,
    public productService: ProductService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController
  ) {
    this.date = this.utils.currentDate;
  }

  async openCaisse(totalG) {
    const modal = await this.modalCtrl.create({
      component: EncaissementComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        total: totalG,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'caisse-web' : 'caisse-mobile',
    });

    await modal.present();
  }

  async openCaisseSortie() {
    const modal = await this.modalCtrl.create({
      component: CaisseSortieCrudComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win2-modal-web' : 'win2-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.loadCS(this.sortie.date);
    }
  }

  async loadPOS() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL',
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.pointOfSales = [];
          for (const data of res.result) {
            this.total = 0;
            this.totalCS = 0;
            this.totalActe = 0;
            this.totalAutre = 0;
            this.totalMedoc = 0;
            this.totalLtr = '';

            this.pointOfSales.push(data);
            this._pointOfSales.next(this.pointOfSales);
            this.pointOfSales.forEach((e) => {
              this.total += +e.montant;

              this.totalCS += +e.consultation;
              this.totalActe += +e.acte;
              this.totalAutre += +e.autre_caisse;
              this.totalMedoc += +e.medoc;

              // Caisse details
              this.detail.designation += +e.designation;
              this.detail.qte += +e.qte;
              this.detail.pu += +e.prix;
              this.detail.montant += +e.montant;
            });
            this.totalLtr = this.ngxNumToWordsService.inWords(
              this.total,
              this.lang
            );

            this._total.next(this.total);
            this._totalCS.next(this.totalCS);
            this._totalActe.next(this.totalActe);
            this._totalMedoc.next(this.totalMedoc);
            this._totalAutre.next(this.totalAutre);

            this.isLoading = false;
          }
          resolve(true);
        });
    });
  }

  async loadCaisseDetail(date, pID) {
    this.caisseDetail = [];
    this.totalD = 0;

    return new Promise((resolve) => {
      const body = {
        action: 'GET_CAISSE_DETAIL',
        date: date,
        p_id: pID,
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          for (const data of res.result) {
            this.caisseDetail.push(data);
            this.caisseDetail.forEach((e) => {
              this.totalD += +e.montant;
            });
            this.totalDLtr = this.ngxNumToWordsService.inWords(
              this.totalD,
              this.lang
            );
            this.isLoading = false;
          }
          resolve(true);
        });
    });
  }

  async caisseSortie() {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise((_resolve) => {
      const body = {
        action: 'ADD_SORTIE',
        date: this.sortie.date,
        motif: this.sortie.motif,
        montant: this.sortie.montant,
      };
      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          if (res.success === true) {
            const body = {
              action: 'CAISSE_JOUR',
              date: this.momentjs(this.sortie.date).format('YYYYMMDD'),
              consultation: 0,
              acte: 0,
              autre_caisse: 0,
              medoc: 0,
              recette: 0,
              depense: this.sortie.montant,
            };
            this.accessProviders.postData(body, 'caisse.php').subscribe();
            this.caisseJour(this.sortie.date);
            loader.dismiss();
            this.modalCtrl.dismiss(null, 'crud');
          }
        });
    });
  }

  updateCaisseDetail(date, pID) {
    return new Promise(() => {
      const body = {
        action: 'UPDATE_DETAIL',
        p_id: pID,
        date: date,
      };

      this.accessProviders.postData(body, 'caisse.php').subscribe();
    });
  }

  async addArticle(type, article, qte) {
    if (type === 'medoc') {
      if (article.qteEnStock < qte) {
        this.utils.presentAlert(
          'Erreur',
          'La quantité que vous avez entrer est superieure à la quantité en Stock. \n\nVeuillez saisir une quantité inferieur ou égale au quantité en Stock'
        );
      } else {
        return new Promise(() => {
          const body = {
            action: 'CRUD',
            pID: article.pID,
            designation: article.libProd,
            prix: article.prixVente,
            qte: qte,
            montant: article.prixVente * qte,
            consultation: 0,
            acte: 0,
            autre_caisse: 0,
            medoc: article.prixVente * qte,
            typeID: 2,
            crudAct: 'CREATE',
          };

          this.accessProviders
            .postData(body, 'caisse.php')
            .subscribe((res: any) => {
              if (res.success === true) {
                this.updateStock(article, qte);
                this.loadPOS();
                this.productService.getProduits();

                // Caiisse Detail
                return new Promise(() => {
                  const body = {
                    action: 'ADD_CAISSE_DETAIL',
                    p_id: 'J',
                    designation: article.libProd,
                    prix: article.prixVente,
                    qte: qte,
                    montant: article.prixVente * qte,
                  };

                  this.accessProviders.postData(body, 'caisse.php').subscribe();
                });
              }
            });
        });
      }
    } else if (type === 'consult') {
      return new Promise(() => {
        const body = {
          action: 'CRUD',
          pID: article.id,
          designation: article.libelle,
          prix: article.tarif,
          qte: qte,
          montant: article.tarif * qte,
          consultation: article.tarif * qte,
          acte: 0,
          autre_caisse: 0,
          medoc: 0,
          typeID: 1,
          crudAct: 'CREATE',
        };

        this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
          this.loadPOS();

          // Caiisse Detail
          return new Promise(() => {
            const body = {
              action: 'ADD_CAISSE_DETAIL',
              p_id: 'J',
              designation: article.libelle,
              prix: article.tarif,
              qte: qte,
              montant: article.tarif * qte,
            };

            this.accessProviders.postData(body, 'caisse.php').subscribe();
          });
        });
      });
    } else if (type === 'acte') {
      return new Promise(() => {
        const body = {
          action: 'CRUD',
          pID: article.id,
          designation: article.libelle,
          prix: article.tarif,
          qte: qte,
          montant: article.tarif * qte,
          consultation: 0,
          acte: article.tarif * qte,
          autre_caisse: 0,
          medoc: 0,
          typeID: 1,
          crudAct: 'CREATE',
        };

        this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
          this.loadPOS();

          // Caiisse Detail
          return new Promise(() => {
            const body = {
              action: 'ADD_CAISSE_DETAIL',
              p_id: 'J',
              designation: article.libelle,
              prix: article.tarif,
              qte: qte,
              montant: article.tarif * qte,
            };

            this.accessProviders.postData(body, 'caisse.php').subscribe();
          });
        });
      });
    } else if (type === 'autre') {
      return new Promise(() => {
        const body = {
          action: 'CRUD',
          pID: article.id,
          designation: article.libelle,
          prix: qte,
          qte: 1,
          montant: qte,
          consultation: 0,
          acte: 0,
          autre_caisse: qte,
          medoc: 0,
          typeID: 1,
          crudAct: 'CREATE',
        };

        this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
          this.loadPOS();

          // Caiisse Detail
          return new Promise(() => {
            const body = {
              action: 'ADD_CAISSE_DETAIL',
              p_id: 'J',
              designation: article.libelle,
              prix: qte,
              qte: 1,
              montant: qte,
            };

            this.accessProviders.postData(body, 'caisse.php').subscribe();
          });
        });
      });
    }
  }

  async updateArticle(type, article, montant, id) {
    if (type === 'medoc') {
      return new Promise(() => {
        const body = {
          action: 'CRUD',
          id: id,
          prix: montant,
          qte: article.qte,
          montant: montant * article.qte,
          medoc: montant * article.qte,
          consultation: 0,
          acte: 0,
          autre_caisse: 0,
          crudAct: 'UPDATE',
        };

        this.accessProviders
          .postData(body, 'caisse.php')
          .subscribe((res: any) => {
            if (res.success === true) {
              this.loadPOS();
              this.productService.getProduits();

              // Caiisse Detail
              return new Promise(() => {
                const body = {
                  action: 'UPDATE_CAISSE_DETAIL',
                  p_id: 'J',
                  designation: article.designation,
                  // date: this.momentjs(new Date()).format('YYYYMMDD'),
                  prix: montant,
                  montant: montant * article.qte,
                };

                this.accessProviders.postData(body, 'caisse.php').subscribe();
              });
            }
          });
      });
    } else if (type === 'consult') {
      return new Promise(() => {
        const body = {
          action: 'CRUD',
          id: id,
          prix: montant,
          qte: article.qte,
          montant: montant * article.qte,
          consultation: montant * article.qte,
          acte: 0,
          autre_caisse: 0,
          medoc: 0,
          crudAct: 'UPDATE',
        };

        this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
          this.loadPOS();

          // Caiisse Detail
          return new Promise(() => {
            const body = {
              action: 'UPDATE_CAISSE_DETAIL',
              p_id: 'J',
              designation: article.designation,
              // date: this.momentjs(new Date()).format('YYYYMMDD'),
              prix: montant,
              montant: montant * article.qte,
            };

            this.accessProviders.postData(body, 'caisse.php').subscribe();
          });
        });
      });
    } else if (type === 'acte') {
      return new Promise(() => {
        const body = {
          action: 'CRUD',
          id: id,
          prix: montant,
          qte: article.qte,
          montant: montant * article.qte,
          acte: montant * article.qte,
          consultation: 0,
          autre_caisse: 0,
          medoc: 0,
          crudAct: 'UPDATE',
        };

        this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
          this.loadPOS();

          // Caiisse Detail
          return new Promise(() => {
            const body = {
              action: 'UPDATE_CAISSE_DETAIL',
              p_id: 'J',
              designation: article.designation,
              // date: this.momentjs(new Date()).format('YYYYMMDD'),
              prix: montant,
              montant: montant * article.qte,
            };

            this.accessProviders.postData(body, 'caisse.php').subscribe();
          });
        });
      });
    } else if (type === 'autre') {
      return new Promise(() => {
        const body = {
          action: 'CRUD',
          id: id,
          prix: montant,
          qte: article.qte,
          montant: montant,
          autre_caisse: montant,
          acte: 0,
          consultation: 0,
          medoc: 0,
          crudAct: 'UPDATE',
        };

        this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
          this.loadPOS();

          // Caiisse Detail
          return new Promise(() => {
            const body = {
              action: 'UPDATE_CAISSE_DETAIL',
              p_id: 'J',
              designation: article.designation,
              // date: this.momentjs(new Date()).format('YYYYMMDD'),
              prix: montant,
              montant: montant,
            };

            this.accessProviders.postData(body, 'caisse.php').subscribe();
          });
        });
      });
    }
  }

  async updateCaisseCreance(date, pID, dateRegle, remarque) {
    return new Promise(() => {
      const body = {
        action: 'UPDATE_CAISSE_CREANCE',
        date: date,
        p_id: pID,
        reglement: 1,
        dateReglement: dateRegle,
        remarque: remarque,
      };

      this.accessProviders.postData(body, 'caisse.php').subscribe();
    });
  }

  async addCaisseCreance(date, pID, montant, paye, reste) {
    return new Promise(() => {
      const body = {
        action: 'ADD_CAISSE_CREANCE',
        date: date,
        p_id: pID,
        montant: montant,
        paye: paye,
        reste: reste,
      };

      this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
        this.clear();
      });
    });
  }

  async addCaisseHistorique(date, pID, paye, reste, aRendre, totalP) {
    return new Promise(() => {
      const body = {
        action: 'ADD_CAISSE_HISTORIQUE',
        date: date,
        p_id: pID,
        paye: paye,
        reste: reste,
        aRendre: aRendre,
        total: totalP,
      };

      this.accessProviders.postData(body, 'caisse.php').subscribe();
    });
  }

  async addCaisse(date, pID, libelle, montant, remise, totalP) {
    return new Promise(() => {
      const body = {
        action: 'ADD_CAISSE',
        date: date,
        p_id: pID,
        libelle: libelle,
        montant: montant,
        remise: remise,
        total: totalP,
      };

      this.accessProviders.postData(body, 'caisse.php').subscribe();
    });
  }

  async deleteArticle(pos) {
    if (pos.typeID === '2') {
      return new Promise(() => {
        const body = {
          action: 'DELETE_DETAIL',
          designation: pos.designation,
        };

        this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
          return new Promise(async () => {
            const body = {
              action: 'DELETE',
              id: pos.id,
              typeID: 2,
            };

            this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
              this.updateUpStock(pos.pID, pos.qte);
              this.loadPOS();
              this.total = 0;
              this._total.next(this.total);
              this.totalLtr = '';
              this.productService.getProduits();
            });
          });
        });
      });
    } else if (pos.typeID === '1') {
      return new Promise(() => {
        const body = {
          action: 'DELETE_DETAIL',
          designation: pos.designation,
        };

        this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
          return new Promise(async () => {
            const body = {
              action: 'DELETE',
              id: pos.id,
              typeID: 1,
            };

            this.accessProviders.postData(body, 'caisse.php').subscribe(() => {
              this.updateUpStock(pos.pID, pos.qte);
              this.loadPOS();
              this.total = 0;
              this._total.next(this.total);
              this.totalLtr = '';
              this.productService.getProduits();
            });
          });
        });
      });
    }
  }

  async updateStock(article, qte) {
    return new Promise(() => {
      const body = {
        action: 'CRUD',
        crudAct: 'QTE_DEC',
        produit_id: article.pID,
        qteSortie: qte,
      };

      this.accessProviders.postData(body, 'stock.php').subscribe();
    });
  }

  async updateUpStock(id, qte) {
    return new Promise(() => {
      const body = {
        action: 'CRUD',
        crudAct: 'QTE_INC',
        produit_id: id,
        qteEntree: qte,
      };

      this.accessProviders.postData(body, 'stock.php').subscribe();
    });
  }

  addCaisseJour(date, reste) {
    return new Promise(async () => {
      const body = {
        action: 'CAISSE_JOUR',
        date: this.momentjs(date).format('YYYYMMDD'),
        consultation: this.totalCS - reste,
        acte: this.totalActe,
        autre_caisse: this.totalAutre,
        medoc: this.totalMedoc,
        recette:
          this.totalCS -
          reste +
          this.totalActe +
          this.totalAutre +
          this.totalMedoc,
        depense: 0,
      };
      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.clear();
        });
    });
  }

  updateCaisseJour(date, montant) {
    return new Promise(async () => {
      const body = {
        action: 'CAISSE_JOUR',
        date: this.momentjs(date).format('YYYYMMDD'),
        consultation: 0,
        acte: 0,
        autre_caisse: montant,
        medoc: 0,
        recette: montant,
        depense: 0,
      };
      this.accessProviders.postData(body, 'caisse.php').subscribe((_) => {
        this.clear();
      });
    });
  }

  clear() {
    return new Promise(async () => {
      const body = {
        action: 'DELETE_ALL',
      };
      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.loadPOS();
          this.total = 0;
          this.totalLtr = '';

          this._total.next(this.total);
        });
    });
  }

  async caisseJour(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_CAISSE_JOUR',
        date: date,
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.caisseJ = [];
          if (res.result.length == 0) {
            this.totalG = 0;
            this._totalG.next(this.totalG);

            this.totalRecette = 0;
            this._totalRecette.next(this.totalRecette);

            this.totalDepense = 0;
            this._totalDepense.next(this.totalDepense);
          } else {
            for (const data of res.result) {
              this.totalG = 0;
              this.totalRecette = 0;
              this.totalDepense = 0;
              this.totalCS = 0;
              this.totalActe = 0;
              this.totalAutre = 0;
              this.totalMedoc = 0;
              this.totalL = '';

              this.caisseJ.push(data);
              this._caisseJ.next(this.caisseJ);

              this.caisseJ.forEach((e) => {
                this.totalRecette += +e.recette;
                this.totalDepense += +e.depense;
                this.totalG += +e.recette - +e.depense;
                this.totalCS += +e.cs;
                this.totalActe += +e.acte;
                this.totalAutre += +e.autre;
                this.totalMedoc += +e.medoc;
              });
              this.totalL = this.ngxNumToWordsService.inWords(
                this.totalG,
                this.lang
              );

              this.isLoading = false;
              this._totalRecette.next(this.totalRecette);
              this._totalDepense.next(this.totalDepense);
              this._totalG.next(this.totalG);
              this._totalCS.next(this.totalCS);
              this._totalActe.next(this.totalActe);
              this._totalMedoc.next(this.totalMedoc);
              this._totalAutre.next(this.totalAutre);
            }
            resolve(true);
          }
        });
    });
  }

  clearGT() {
    this.totalRec = 0;
    this.totalDep = 0;
    this.totalConsult = 0;
    this.totalAct = 0;
    this.totalOther = 0;
    this.totalMed = 0;
  }

  async caisseDate1(date1) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_CAISSE',
        date: date1,
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.caisses = [];
          if (res.result == '') {
            this.isEmpty = true;
            this.isLoading = false;
          } else {
            this.isEmpty = false;
            for (const data of res.result) {
              this.clearGT();
              this.caisses.push(data);
              this._caisses.next(this.caisses);

              this.caisses.forEach((e) => {
                this.totalRec += +e.recette;
                this.totalDep += +e.depense;
                this.totalConsult += +e.cs;
                this.totalAct += +e.acte;
                this.totalOther += +e.autre;
                this.totalMed += +e.medoc;
              });
              this.isLoading = false;
            }
            resolve(true);
          }
        });
    });
  }

  async caisseDate2(date1, date2) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_CAISSE_RANGE',
        date1: date1,
        date2: date2,
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.caisses = [];
          if (res.result == '') {
            this.isEmpty = true;
            this.isLoading = false;
          } else {
            this.isEmpty = false;
            for (const data of res.result) {
              this.clearGT();

              this.caisses.push(data);

              this.caisses.forEach((e) => {
                this.totalRec += +e.recette;
                this.totalDep += +e.depense;
                this.totalConsult += +e.cs;
                this.totalAct += +e.acte;
                this.totalOther += +e.autre;
                this.totalMed += +e.medoc;
              });
              this.isLoading = false;
            }
            resolve(true);
          }
        });
    });
  }

  async caisseDate3(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_CAISSE_MONTH',
        month: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.caisses = [];
          if (res.result == '') {
            this.isEmpty = true;
            this.isLoading = false;
          } else {
            this.isEmpty = false;
            for (const data of res.result) {
              this.clearGT();

              this.caisses.push(data);

              this.caisses.forEach((e) => {
                this.totalRec += +e.recette;
                this.totalDep += +e.depense;
                this.totalConsult += +e.cs;
                this.totalAct += +e.acte;
                this.totalOther += +e.autre;
                this.totalMed += +e.medoc;
              });
              this.isLoading = false;
            }
            resolve(true);
          }
        });
    });
  }

  async caisseDate4(year) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_CAISSE_YEAR',
        year: year,
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.caisses = [];
          if (res.result == '') {
            this.isEmpty = true;
            this.isLoading = false;
          } else {
            this.isEmpty = false;
            for (const data of res.result) {
              this.clearGT();

              this.caisses.push(data);

              this.caisses.forEach((e) => {
                this.totalRec += +e.recette;
                this.totalDep += +e.depense;
                this.totalConsult += +e.cs;
                this.totalAct += +e.acte;
                this.totalOther += +e.autre;
                this.totalMed += +e.medoc;
              });
              this.isLoading = false;
            }
            resolve(true);
          }
        });
    });
  }

  async caisse10Jour() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_CAISSE_10',
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.caisse10J = [];
          if (res.result == '') {
            this.isEmpty = true;
            this.isLoading = false;
          } else {
            this.isEmpty = false;
            for (const data of res.result) {
              this.clearGT();

              this.caisse10J.push(data);
              this._caisse10J.next(this.caisse10J);

              this.caisse10J.forEach((e) => {
                this.totalRec10 += +e.recette;
                this.totalDep10 += +e.depense;
              });
              this.isLoading = false;
            }
            console.log('Caisse 10J : ', this.caisse10J);
            resolve(true);
          }
        });
    });
  }

  async loadOthers() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL_AUTRE',
      };

      this.accessProviders
        .postData(body, 'prestation.php')
        .subscribe((res: any) => {
          for (const data of res.result) {
            this.others.push(data);
            this.filteredData = this.others;
            this.isLoading = false;
          }
          resolve(true);
        });
    });
  }

  // Entree Caisse
  async loadCE(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_REC',
        dateCaisse: date,
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.caisseEntrees = [];
          if (res.result == '') {
            this.isCEEmpty = true;
            this.isLoading = false;
          } else {
            this.isCEEmpty = false;
            for (const data of res.result) {
              this.caisseEntrees.push(data);
              this._caisseEntrees.next(this.caisseEntrees);

              this.isLoading = false;
            }
            resolve(true);
          }
        });
    });
  }

  // Sortie Caisse
  async loadCS(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_DEP',
        dateCaisse: date,
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.caisseSorties = [];
          if (res.result == '') {
            this.isCSEmpty = true;
            this.isLoading = false;
          } else {
            this.isCSEmpty = false;
            for (const data of res.result) {
              this.caisseSorties.push(data);
              this._caisseSorties.next(this.caisseSorties);

              this.isLoading = false;
            }

            resolve(true);
          }
        });
    });
  }

  // Caisse Creance
  async loadCreance() {
    this.caisseCreances = [];
    return new Promise((resolve) => {
      const body = {
        action: 'GET_CREANCE',
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this.isCCEmpty = true;
            this.isLoading = false;
          } else {
            this.isCCEmpty = false;
            for (const data of res.result) {
              this.caisseCreances.push(data);
              this._caisseCreances.next(this.caisseCreances);

              this.filteredCreance = this.caisseCreances;
              this.isLoading = false;
            }
            resolve(true);
          }
        });
    });
  }

  // Caisse Historique
  async loadHistorique(date, pID) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_HISTORIQUE',
        date: date,
        p_id: pID,
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          this.caisseHistorique = [];
          for (const data of res.result) {
            this.caisseHistorique.push(data);
            this.isLoading = false;
          }

          resolve(true);
        });
    });
  }

  filterListCreance(evt) {
    this.caisseCreances = this.filteredCreance;
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this.caisseCreances = this.filteredCreance.filter(
      (item: { p_id: string }) => {
        return item.p_id.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      }
    );
  }

  async deleteCaisse(id, date, montant) {
    return new Promise(async () => {
      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        message: 'Voulez-vous vraiment supprimer ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              return new Promise(async () => {
                const body = {
                  action: 'SORTIE_CAISSE_JOUR',
                  date: date,
                  montant: montant,
                };

                this.accessProviders
                  .postData(body, 'caisse.php')
                  .subscribe((res: any) => {
                    if (res.success === true) {
                      const bodySortie = {
                        action: 'DELETE_SORTIE',
                        id: id,
                      };
                      this.accessProviders
                        .postData(bodySortie, 'caisse.php')
                        .subscribe((res: any) => {
                          if (res.success === true) {
                            this.loadCS(date);
                            this.caisseJour(date);
                          }
                        });
                    }
                  });
              });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });
      await alert.present();
    });
  }

  async getCaisseMois(date) {
    this.mois = [];
    this.totalG = 0;
    this.totalRecette = 0;
    this.totalDepense = 0;
    this.totalCS = 0;
    this.totalActe = 0;
    this.totalAutre = 0;
    this.totalMedoc = 0;
    this.totalL = '';

    return new Promise((resolve) => {
      let body = {
        action: 'GET_CAISSE_MOIS',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders
        .postData(body, 'caisse.php')
        .subscribe((res: any) => {
          for (const data of res.result) {
            this.mois.push(data);

            this.mois.forEach((e) => {
              this.totalRecette += +e.recette;
              this.totalDepense += +e.depense;
              this.totalG += +e.recette - e.depense;
              this.totalCS += +e.cs;
              this.totalActe += +e.acte;
              this.totalAutre += +e.autre;
              this.totalMedoc += +e.medoc;
            });

            this._totalRecette.next(this.totalRecette);
            this._totalDepense.next(this.totalDepense);

            this.isLoading = false;
          }
          resolve(true);
        });
    });
  }
}
