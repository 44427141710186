import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CaisseService } from 'src/app/services/caisse.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-caisse-detail',
  templateUrl: './caisse-detail.component.html',
  styleUrls: ['./caisse-detail.component.scss'],
})
export class CaisseDetailComponent implements OnInit {
  detail;
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public caisseService: CaisseService,
    public utils: UtilsService
  ) {
    this.detail = this.navParams.get('detail');

    this.caisseService.loadCaisseDetail(this.detail.date, this.detail.p_id);
    this.caisseService.loadHistorique(this.detail.date, this.detail.p_id);
  }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
