import { AppService } from './../../../services/app.service';
import { UserService } from './../../../services/user.service';
import { UtilsService } from './../../../services/utils.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CalendarEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { isSameMonth, isSameDay } from 'date-fns';

import { Subject } from 'rxjs';
import { DetailEventComponent } from '../../appointments/detail-event/detail-event.component';

interface Appointment {
  id: number;
  title: string;
  dateTime: string;
}

@Component({
  selector: 'app-rdv-calendar',
  templateUrl: './rdv-calendar.component.html',
  styleUrls: ['./rdv-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RdvCalendarComponent implements OnInit {
  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  users = [];
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  activeDayIsOpen: boolean = false;

  refresh = new Subject<void>();

  constructor(
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public userService: UserService,
    public appService: AppService
  ) {
    this.utils.getSession();
    this.utils.checkPlatform();
  }

  ngOnInit(): void {
    this.userService.loadDoctors();
    this.appService.fetchEvents();
  }

  async dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ app: Appointment }>[];
  }): Promise<void> {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  async eventClicked(event): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: DetailEventComponent,
      animated: true,
      mode: 'ios',
      componentProps: { event: event },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win1-detail-web' : 'win1-detail-mobile',
    });
    await modal.present();
  }
}
