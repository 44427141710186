import { UtilsService } from 'src/app/services/utils.service';
import { CaisseService } from './../../../services/caisse.service';
import { Component, OnInit } from '@angular/core';

import { SUPPORTED_LANGUAGE } from 'ngx-num-to-words';
import { OpenPriceComponent } from './../open-price/open-price.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-point-of-sale',
  templateUrl: './point-of-sale.component.html',
  styleUrls: ['./point-of-sale.component.scss'],
})
export class PointOfSaleComponent implements OnInit {
  numberInWords!: string;
  lang: SUPPORTED_LANGUAGE = 'fr';
  total = 0;
  pointOfSales = [];

  constructor(
    public utils: UtilsService,
    public caisseService: CaisseService,
    private modalCtrl: ModalController
  ) {
    this.utils.checkPlatform();
    this.caisseService.loadPOS();
  }

  ngOnInit() {
    this.caisseService._total$.subscribe((total: any) => {
      this.total = total;
    });

    this.caisseService._pointOfSales$.subscribe((pos: any[]) => {
      this.pointOfSales = pos;
    });
  }

  async updatePOS(pos) {
    const modal = await this.modalCtrl.create({
      component: OpenPriceComponent,
      animated: true,
      mode: 'ios',
      componentProps: { article: pos },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win9-modal-web' : 'win9-modal-mobile',
    });
    await modal.present();
  }
}
