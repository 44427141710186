import { AlertController } from '@ionic/angular';
import { UtilsService } from './utils.service';
import { AccessProviders } from '../providers/access-providers.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DashService {
  rdvUser = 0;
  rdvAdmin = 0;
  isLoading = true;
  nbCs: number;

  constructor(public utils: UtilsService, private alertCtrl: AlertController) {}

  async alertAfficheForUser() {
    const alert = await this.alertCtrl.create({
      header: 'Consultation',
      message: "Vous n'avez pas de consultation à afficher aujourd'hui !",
      backdropDismiss: true,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    alert.present();
  }

  async alertAfficheConsult() {
    const alert = await this.alertCtrl.create({
      header: 'Consultation',
      message: 'Pas de consultation!',
      backdropDismiss: true,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    alert.present();
  }

  async alertAfficheForConsult() {
    const alert = await this.alertCtrl.create({
      header: 'Consultation',
      message: "Pas de consultation à afficher aujourd'hui !",
      backdropDismiss: true,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    alert.present();
  }
}
