import { PDFService } from './../../../../services/pdf.service';
import { ConsultationService } from 'src/app/services/consultation.service';
import { ATCDService } from 'src/app/services/atcd.service';
import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  Platform,
  ModalController,
  NavParams,
} from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';

// PDFMake
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { UtilsService } from 'src/app/services/utils.service';
import { PatientCrudComponent } from '../../crud/patient-crud/patient-crud.component';
import { StorageService } from 'src/app/services/storage.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  @Input() patient: any;
  momentjs: any = moment;

  isVisible = false;
  chevron = 'chevron-forward-outline';

  dismiss() {
    this.modalCtrl.dismiss(null, '');
  }

  // PDFMake
  pdfObj = null;
  consultations: any = [];
  atcds: any = [];
  nom: string;

  constructor(
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public pdfService: PDFService,
    private atcdService: ATCDService,
    private consultationService: ConsultationService,
    private storage: StorageService
  ) {
    utils.checkPlatform();
    this.storage.getData('p_id').then((p: any) => {
      this.patient = p;
    });
  }

  collapse() {
    this.isVisible = !this.isVisible;
    if (this.isVisible == true) {
      this.chevron = 'chevron-down-outline';
    } else {
      this.chevron = 'chevron-forward-outline';
    }
  }

  ngOnInit() {}
}
