import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prestation',
  templateUrl: './prestation.component.html',
  styleUrls: ['./prestation.component.scss'],
})
export class PrestationComponent implements OnInit {
  segmentModel = 'cs';
  constructor() {}

  ngOnInit() {}

  segmentChanged(e) {}
}
