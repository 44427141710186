import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { CaisseService } from 'src/app/services/caisse.service';

@Component({
  selector: 'app-open-price',
  templateUrl: './open-price.component.html',
  styleUrls: ['./open-price.component.scss'],
})
export class OpenPriceComponent implements OnInit {
  montant = 0;
  article: any;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    public caisseService: CaisseService
  ) {
    this.article = this.navParams.get('article');
    this.montant = this.article.montant;
  }

  ngOnInit() {}

  async dismiss() {
    this.modalCtrl.dismiss(null, '');
  }

  updateArticle(montant) {
    if (this.article.acte !== '0') {
      this.caisseService.updateArticle(
        'acte',
        this.article,
        montant,
        this.article.id
      );
    } else if (this.article.consultation !== '0') {
      this.caisseService.updateArticle(
        'consult',
        this.article,
        montant,
        this.article.id
      );
    } else if (this.article.medoc !== '0') {
      this.caisseService.updateArticle(
        'medoc',
        this.article,
        montant,
        this.article.id
      );
    } else if (this.article.autre_caisse !== '0') {
      this.caisseService.updateArticle(
        'autre',
        this.article,
        montant,
        this.article.id
      );
    }

    this.modalCtrl.dismiss(null, 'crud');
  }
}
