import { Component, OnInit } from '@angular/core';
import { CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { RMAService } from 'src/app/services/rma.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-rha-list',
  templateUrl: './rha-list.component.html',
  styleUrls: ['./rha-list.component.scss'],
})
export class RhaListComponent implements OnInit {
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  constructor(public rmaService: RMAService, public utils: UtilsService) {}

  ngOnInit() {}
}
