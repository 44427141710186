import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-ophtalmo',
  templateUrl: './ophtalmo.component.html',
  styleUrls: ['./ophtalmo.component.scss'],
})
export class OphtalmoComponent implements OnInit {
  readonly1 = true;
  readonly2 = true;
  disabled = true;

  opht = {
    avod1: '',
    avog1: '',
    avod2: '',
    avog2: '',
    axeOD: '',
    cylOD: '',
    sphOD: '',
    axeOG: '',
    cylOG: '',
    sphOG: '',
    p: '',
    add: '',
    note: '',
  };

  constructor(
    private modalCtrl: ModalController,
    private storage: StorageService
  ) {}

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

  activecylOD(axeOD) {
    if (axeOD !== '') {
      this.readonly1 = false;
    }
  }

  activecylOG(axeOG) {
    if (axeOG !== '') {
      this.readonly2 = false;
    }
  }

  addOpht() {
    this.storage.setData('ophtalmo', this.opht);

    this.modalCtrl.dismiss(null, 'crud');
  }
}
