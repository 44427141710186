import { Component, OnInit } from '@angular/core';
import { RMAService } from 'src/app/services/rma.service';

@Component({
  selector: 'app-other-case',
  templateUrl: './other-case.component.html',
  styleUrls: ['./other-case.component.scss'],
})
export class OtherCaseComponent implements OnInit {
  constructor(public rmaService: RMAService) {}

  ngOnInit() {}
}
