import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  @Input('img') img: any;
  @ViewChild(IonSlides) slides: IonSlides;

  urlFile = `${this.urlService.server}uploadFile/`;

  sliderOpts = {
    zoom: true,
  };

  constructor(
    private modalCtrl: ModalController,
    private urlService: UrlService
  ) {}

  ngOnInit() {}

  ionViewDidEnter() {
    // this.slides.update();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async zoom(zoomIn: boolean) {
    const slider = await this.slides.getSwiper();
    const zoom = slider.zoom;
    zoomIn ? zoom.in() : zoom.out();
  }
}
