import { Component, OnInit } from '@angular/core';
import { RMAService } from 'src/app/services/rma.service';

@Component({
  selector: 'app-pcime',
  templateUrl: './pcime.component.html',
  styleUrls: ['./pcime.component.scss'],
})
export class PcimeComponent implements OnInit {
  constructor(public rmaService: RMAService) {}

  ngOnInit() {}
}
