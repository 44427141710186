import { ModalController, AlertController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { Injectable } from '@angular/core';
import { DecreaseStockComponent } from '../components/stock/decrease-stock/decrease-stock.component';
import { IncreaseStockComponent } from '../components/stock/increase-stock/increase-stock.component';
import { AddProductComponent } from '../components/stock/add-product/add-product.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductService {
  categories: any = [];
  produits: any = [];
  entreeStocks: any = [];
  nbentreeStock: string;
  sortieStocks: any = [];
  nbsortieStock: string;
  filteredData = [];
  produit$: number;

  constructor(
    private accessProviders: AccessProviders,
    public utils: UtilsService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {}

  ////////// OBSERVABLES /////////////
  _produits = new BehaviorSubject<any[]>([]);
  _nbProduit = new BehaviorSubject<number>(0);
  _entreeStock = new BehaviorSubject<any[]>([]);
  _sortieStock = new BehaviorSubject<any[]>([]);
  _isLoading = new BehaviorSubject<boolean>(true);
  _isEmpty = new BehaviorSubject<boolean>(false);
  _isDownStockEmpty = new BehaviorSubject<boolean>(false);
  _isUpStockEmpty = new BehaviorSubject<boolean>(false);

  //////////// ASSECEURS ////////////
  get _produits$() {
    return this._produits.asObservable();
  }

  get _entreeStock$() {
    return this._entreeStock.asObservable();
  }

  get _sortieStock$() {
    return this._sortieStock.asObservable();
  }

  get _nbProduit$() {
    return this._nbProduit.asObservable();
  }

  get _isLoading$() {
    return this._isLoading.asObservable();
  }

  get _isEmpty$() {
    return this._isEmpty.asObservable();
  }

  get _isDownStockEmpty$() {
    return this._isDownStockEmpty.asObservable();
  }

  get _isUpStockEmpty$() {
    return this._isUpStockEmpty.asObservable();
  }
  //////////// ASSECEURS ////////////

  refreshData() {
    this.getProduits();
  }

  async nbProduit() {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_PRODUCT',
      };

      this.accessProviders.postData(body, 'stock.php').subscribe((res: any) => {
        this.produit$ = res;
        this._nbProduit.next(this.produit$);
        this._isLoading.next(false);
      });
    });
  }

  async loadCategories() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL_CATEGORIE',
      };

      this.accessProviders.postData(body, 'stock.php').subscribe((res: any) => {
        this.categories = [];
        for (const data of res.result) {
          this.categories.push(data);
        }
        resolve(true);
      });
    });
  }
  async getProduits() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL_PRODUCT',
      };

      this.accessProviders.postData(body, 'stock.php').subscribe((res: any) => {
        this.produits = [];
        if (res.result == '') {
          this._isEmpty.next(true);
          this._isLoading.next(false);
        } else {
          this._isEmpty.next(false);
          for (const data of res.result) {
            this.produits.push(data);
            this.filteredData = this.produits;
            this._produits.next(this.produits);
            this._isLoading.next(false);
          }
          resolve(true);
        }
      });
    });
  }

  filterList(evt) {
    this._produits.next(this.filteredData);
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._produits.next(
      this.filteredData.filter(
        (item: { refProd: string; libProd: string; description: string }) => {
          return (
            item.refProd.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            item.libProd.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            item.description.toLowerCase().indexOf(searchTerm.toLowerCase()) >
              -1
          );
        }
      )
    );
  }

  delProduit(id) {
    return new Promise(async () => {
      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        message:
          'Voulez-vous vraiment supprimer ce produit et de tous ses stocks ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              // Suppression de ses stocks
              return new Promise(async () => {
                const body = {
                  action: 'DELETE_FOR_PRODUCT',
                  produit_id: id,
                };

                this.accessProviders
                  .postData(body, 'stock.php')
                  .subscribe(() => {
                    return new Promise(async () => {
                      const body = {
                        action: 'DELETE',
                        produit_id: id,
                      };
                      this.accessProviders
                        .postData(body, 'stock.php')
                        .subscribe((res: any) => {
                          if (res.success === true) {
                            this.utils.toast('Suppression éffectuée');
                            this.refreshData();
                          } else {
                            this.utils.toastError('Erreur de suppression');
                          }
                        });
                    });
                  });
              });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });
      await alert.present();
    });
  }

  async increase(product) {
    const modal = await this.modalCtrl.create({
      component: IncreaseStockComponent,
      animated: true,
      mode: 'ios',
      componentProps: { product: product },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win11-modal-web' : 'win11-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }

  async decrease(product) {
    const modal = await this.modalCtrl.create({
      component: DecreaseStockComponent,
      animated: true,
      mode: 'ios',
      componentProps: { product: product },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win12-modal-web' : 'win12-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }

  async openCrud(id) {
    const modal = await this.modalCtrl.create({
      component: AddProductComponent,
      animated: true,
      mode: 'ios',
      componentProps: { id: id },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win13-modal-web' : 'win13-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }

  ////////// SORTIE STOCK ////////////
  async loadSortieStocks() {
    this.sortieStocks = [];
    return new Promise((resolve) => {
      const body = {
        action: 'GET_DOWN_STOCK',
      };

      this.accessProviders.postData(body, 'stock.php').subscribe((res: any) => {
        if (res.result == '') {
          this._isDownStockEmpty.next(true);
          this._isLoading.next(false);
        } else {
          for (const data of res.result) {
            this.sortieStocks.push(data);
            this.filteredData = this.sortieStocks;
            this._sortieStock.next(this.sortieStocks);
            this._isLoading.next(false);
          }
          resolve(true);
        }
      });
    });
  }

  filterListOut(evt) {
    this._sortieStock.next(this.filteredData);
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._sortieStock.next(
      this.filteredData.filter((item: { libProd: string }) => {
        return (
          item.libProd.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      })
    );
  }

  delsortieStock(id) {
    return new Promise(async () => {
      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        message:
          'Voulez-vous vraiment supprimer ce sortieStock et de tous ses stocks ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              // Suppression de ses stocks
              return new Promise(async () => {
                const body = {
                  action: 'DELETE_FOR_PRODUCT',
                  sortieStock_id: id,
                };

                this.accessProviders
                  .postData(body, 'stock.php')
                  .subscribe(() => {
                    return new Promise(async () => {
                      const body = {
                        action: 'DELETE',
                        sortieStock_id: id,
                      };
                      this.accessProviders
                        .postData(body, 'stock.php')
                        .subscribe((res: any) => {
                          if (res.success === true) {
                            this.utils.toast('Suppression éffectuée');
                            this.refreshData();
                          } else {
                            this.utils.toastError('Erreur de suppression');
                          }
                        });
                    });
                  });
              });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });
      await alert.present();
    });
  }

  /////////////ENTREE EN STOCK/////////////////
  async loadEntreeStocks() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_UP_STOCK',
      };

      this.accessProviders.postData(body, 'stock.php').subscribe((res: any) => {
        this.entreeStocks = [];
        if (res.result == '') {
          this._isUpStockEmpty.next(true);
          this._isLoading.next(false);
        } else {
          for (const data of res.result) {
            this.entreeStocks.push(data);
            this.filteredData = this.entreeStocks;
            this._entreeStock.next(this.entreeStocks);
            this._isLoading.next(false);
          }
          resolve(true);
        }
      });
    });
  }

  filterListIn(evt) {
    this._entreeStock.next(this.filteredData);

    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._entreeStock.next(
      this.filteredData.filter((item: { libProd: string }) => {
        return (
          item.libProd.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      })
    );
  }
}
