import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compte-rendu',
  templateUrl: './compte-rendu.component.html',
  styleUrls: ['./compte-rendu.component.scss'],
})
export class CompteRenduComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
