import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-param-gen',
  templateUrl: './param-gen.component.html',
  styleUrls: ['./param-gen.component.scss'],
})
export class ParamGenComponent implements OnInit {
  readonly1 = true;
  readonly2 = true;

  soins;
  extractions;

  param = {
    TAG: '',
    TAD: '',
    FC: '',
    temp: '',
    poids: '',
    taille: '',
    autre: '',
  };

  constructor(
    private modalCtrl: ModalController,
    private storage: StorageService
  ) {}

  ngOnInit() {}

  addParam() {
    this.storage.setData('paramG', this.param);

    this.modalCtrl.dismiss(null, 'crud');
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
  addSoin() {
    this.readonly1 = !this.readonly1;
  }

  addExtraction() {
    this.readonly2 = !this.readonly2;
  }
}
