import { StockService } from './../../../services/stock.service';
import { Component, OnInit } from '@angular/core';
import {
  LoadingController,
  ModalController,
  NavParams,
  Platform,
} from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-decrease-stock',
  templateUrl: './decrease-stock.component.html',
  styleUrls: ['./decrease-stock.component.scss'],
})
export class DecreaseStockComponent implements OnInit {
  product;
  isDesktop = true;
  momentjs: any = moment;

  disabledButton;
  isEdit = false;

  constructor(
    public utils: UtilsService,
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public stockService: StockService
  ) {
    // Recupere ID si existe
    this.product = this.navParams.get('product');
    this.stockService.sortie.product = this.product.libProd;
    this.stockService.clearS();

    this.utils.checkPlatform();
  }

  ngOnInit() {}

  ionViewDidEnter() {
    this.disabledButton = false;
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  formatDate(date) {
    return this.momentjs(date).format('YYYYMMDD');
  }

  decrease() {
    this.stockService.decrease(this.product, this.utils.userId);
  }
}
