import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recap-deces',
  templateUrl: './recap-deces.component.html',
  styleUrls: ['./recap-deces.component.scss'],
})
export class RecapDecesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
