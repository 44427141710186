import { MedocService } from './../../../services/medoc.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-posologie',
  templateUrl: './posologie.component.html',
  styleUrls: ['./posologie.component.scss'],
})
export class PosologieComponent implements OnInit {
 
  medoc$: any; 

  constructor(public utils: UtilsService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public medocService: MedocService
  ) {
    this.medoc$ = this.navParams.get('medoc');
    this.medocService.posologie.medoc = this.medoc$.nomCom;
    this.medocService.medocId = this.medoc$.id;
   }
  
  ngOnInit(): void {}

  dismiss() {
    this.modalCtrl.dismiss();
  }  
}
