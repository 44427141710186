import { StockService } from './../../../services/stock.service';
import { Component, OnInit } from '@angular/core';
import {
  LoadingController,
  ModalController,
  NavParams,
  Platform,
} from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-increase-stock',
  templateUrl: './increase-stock.component.html',
  styleUrls: ['./increase-stock.component.scss'],
})
export class IncreaseStockComponent implements OnInit {
  product;
  momentjs: any = moment;

  disabledButton;
  isEdit = false;

  produit = {
    datePeremp: '',
  };

  constructor(
    public utils: UtilsService,
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public stockService: StockService
  ) {
    this.product = this.navParams.get('product');
    this.stockService.entree.product = this.product.libProd;
    this.stockService.clearE();
    this.utils.checkPlatform();
  }

  ngOnInit() {}

  ionViewDidEnter() {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  formatDate(date) {
    return this.momentjs(date).format('YYYYMMDD');
  }

  increase() {
    this.stockService.increase(this.product);
    this.updateProduct();
  }

  async updateProduct() {
    return new Promise(() => {
      const body = {
        action: 'CRUD',
        id: this.product.pID,
        datePeremp: this.produit.datePeremp,
        crudAct: 'UPDATE_DP',
      };

      this.accessProviders.postData(body, 'stock.php').subscribe();
    });
  }
}
