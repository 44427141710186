import { ProductService } from './../../../services/product.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddQteComponent } from './../add-qte/add-qte.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { CaisseService } from 'src/app/services/caisse.service';

@Component({
  selector: 'app-table-product',
  templateUrl: './table-product.component.html',
  styleUrls: ['./table-product.component.scss'],
})
export class TableProductComponent implements OnInit {
  item = {
    title: 'Produit non trouvé',
  };

  qte = 1;
  searchInput;

  produits = [];
  isLoading: boolean;
  isEmpty: boolean;

  constructor(
    public accessProviders: AccessProviders,
    public utils: UtilsService,
    public router: Router,
    public caisseService: CaisseService,
    public productService: ProductService,
    private modalCtrl: ModalController
  ) {
    this.productService.getProduits();

    this.utils.checkPlatform();
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();

    this.productService._produits$.subscribe((produits: any[]) => {
      this.produits = produits;
    });

    this.productService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });

    this.productService._isEmpty$.subscribe((isEmpty: any) => {
      this.isEmpty = isEmpty;
    });
  }

  async ionViewDidEnter() {
    // this.productService.getProduits();
  }

  search(ev) {
    this.searchInput = ev.target.value;
  }

  async openQte(produit) {
    const modal = await this.modalCtrl.create({
      component: AddQteComponent,
      animated: true,
      mode: 'ios',
      componentProps: { article: produit },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win9-modal-web' : 'win9-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role === 'crud') {
      this.productService.getProduits();
      this.searchInput = '';
    }
  }
}
