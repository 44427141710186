import { Component, OnInit } from '@angular/core';
import { ProtocoleService } from 'src/app/services/protocole.service';
import { ModalController, NavParams } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-crud-protocole',
  templateUrl: './crud-protocole.component.html',
  styleUrls: ['./crud-protocole.component.scss'],
})
export class CrudProtocoleComponent implements OnInit {
  constructor(
    public protocoleService: ProtocoleService,
    private modalCtrl: ModalController,
    private storage: StorageService,
    private navParams: NavParams
  ) {
    this.protocoleService.id = this.navParams.get('id');
  }

  ngOnInit() {}

  ionViewDidEnter() {
    if (this.protocoleService.id !== 0) {
      this.protocoleService.loadSingleProtocole(this.protocoleService.id);
    } else {
      this.storage.removeData('protocole');
      this.clearInput();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  clearInput() {
    this.protocoleService.protocole.libelle = '';
    this.protocoleService.protocole.clinique = '';
    this.protocoleService.protocole.paraclinique = '';
    this.protocoleService.protocole.cat = '';
  }

  crudProtocole(a) {
    this.protocoleService.crudProtocole(a);
    this.modalCtrl.dismiss(null, 'close');
  }
}
