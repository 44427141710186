import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consultation-type',
  templateUrl: './consultation-type.component.html',
  styleUrls: ['./consultation-type.component.scss'],
})
export class ConsultationTypeComponent implements OnInit {
  activeIndex = 0;

  menus = [
    { title: 'Consultation' },
    { title: 'Contrôle' },
    { title: 'Dentisterie' },
    { title: 'Surveillance nutritionnelle' },
    { title: 'CPN' },
    {title : 'Maternité'},
    { title: 'PEV' },
    { title: 'Vaccination Femme/Fille' },
    { title: 'Planning Familial' },
    {title : 'Visite Scolaire'},
  ];
  
  constructor() { }

  ngOnInit() {}

  setIndex(index) {
    this.activeIndex = index;
  }
}
