import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import * as moment from 'moment';
import { SearchDiagComponent } from '../components/patients/crud/search-diag/search-diag.component';
import { AccessProviders } from '../providers/access-providers.service';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';
import { RMAService } from 'src/app/services/rma.service';
import { PatientService } from 'src/app/services/patient.service';
import { AutreCasComponent } from '../components/patients/crud/autre-cas/autre-cas.component';
import { AddScolComponent } from '../components/patients/crud/add-scol/add-scol.component';

@Injectable({ providedIn: 'root' })
export class ExamenService {
  momentjs: any = moment;
  readonly = true;
  age;
  csID = null;
  userId;

  consultations = [];
  patient: any;
  IdPatient = null;
  dossiers = [];

  lastId: number;

  // Dossier
  noDossier: string;
  sousDossier: number;
  dossier: string;
  LastDossier: number;

  // Consultation
  idDossier: string = '';
  dateConsult: string = '';
  motif: string = '';
  hdm: string = '';
  clinique: string = '';
  paraclinique: string = '';
  CAT: string = '';
  diagnostic: string = '';
  rdv: string = '';
  prescripteur: string = '';

  isControle: boolean;
  isUpdate: boolean;

  currentDate: string = this.momentjs(new Date()).format('YYYY-MM-DD');

  // Explorations fonctionnelles
  exploID: string = '';
  title: string = '';
  content: string = '';

  constructor(
    public accessProviders: AccessProviders,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    public utils: UtilsService,
    private modalCtrl: ModalController,
    private storage: StorageService,
    private rmaService: RMAService,
    public patientService: PatientService
  ) {
    // this.calculAge();
  }

  ngOnInit() {
    this.checkUpdate();
  }

  checkUpdate() {
    if (this.csID !== 0) {
      this.loadSingleConsultation();
      this.isUpdate = true;
    }
  }

  clearInput() {
    // Examen
    this.prescripteur = '';
    this.dateConsult = '';
    this.motif = '';
    this.hdm = '';
    this.clinique = '';
    this.paraclinique = '';
    this.CAT = '';
    this.diagnostic = '';

    // this.storage.removeData('p_id');
    this.storage.removeData('pt');
  }

  calculAge() {
    this.age = this.utils.age(this.patient.dateNaiss);
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  getNoDossier(date) {
    if (date !== '' && date <= this.currentDate) {
      this.dateConsult = date;
      this.readonly = false;

      if (!this.isControle) {
        this.noDossier =
          'D' +
          this.momentjs(date).format('YYMMDD') +
          '-' +
          this.momentjs().format('hmmss');
      } else {
        this.getDossier();
      }
    } else if (date !== '' && date > this.currentDate) {
      this.utils.toastError(
        'La date doit être inférieure ou égale au date du jour.'
      );
      date = '';
      this.noDossier = '';
      return;
    }
  }

  async loadSingleConsultation() {
    return new Promise((_resolve) => {
      const body = {
        action: 'LOAD_SINGLE_CONSULTATION',
        consultation_id: this.csID,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (const data of res.result) {
            this.idDossier = data.dID;
            this.dateConsult = data.dateCS;
            this.motif = data.motif;
            this.hdm = data.hdm;
            this.clinique = data.clinique;
            this.paraclinique = data.paraclinique;
            this.diagnostic = data.diagnostic;
            this.CAT = data.CAT;
          }
        });
    });
  }

  async getDossier() {
    return new Promise((_resolve) => {
      const body = {
        action: 'GET_DOSSIER',
        patient_id: this.IdPatient,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (const data of res.result) {
            this.noDossier = data.noDossier;
            this.dossier = data.dossier;
            this.LastDossier = data.id;
          }
        });
    });
  }

  async crudExploFn(a) {
    if (this.utils.agentSante === '0' && this.prescripteur === '') {
      this.utils.toastError('Vous devez selectionner le Prescripteur');
    } else if (this.title === '') {
      this.utils.toastError("Vous devez ajouter le type d'examen");
    } else if (this.content === '') {
      this.utils.toastError("Vous devez remplir le resultat d'examen");
    } else {
      const loader = await this.loadingCtrl.create({
        message: 'Veuillez patientez ...',
        spinner: 'bubbles',
      });
      await loader.present();

      // Ajout dossier
      return new Promise((_resolve) => {
        const body = {
          action: 'CRUD_DOSSIER',
          patient_id: this.IdPatient,
          noDossier: this.noDossier,
        };

        this.accessProviders
          .postData(body, 'dossier.php')
          .subscribe((res: any) => {
            if (res.success === true) {
              // Last ID Dossier
              const body = {
                action: 'GET_LAST_RECORD',
                patient_id: this.IdPatient,
              };

              this.accessProviders
                .postData(body, 'dossier.php')
                .subscribe((res: any) => {
                  for (const data of res.result) {
                    this.LastDossier = parseInt(data.id);
                  }

                  return new Promise((_resolve) => {
                    const body = {
                      action: 'CRUD_EXPLO',
                      id: this.exploID,
                      patient_id: this.IdPatient,
                      date: this.dateConsult,
                      title: this.title,
                      content: this.content,
                      dossier_id: this.LastDossier,
                      crudAct: a,
                    };

                    this.accessProviders
                      .postData(body, 'consultation.php')
                      .subscribe((res: any) => {
                        if (res.success === true) {
                          this.title = '';
                          this.content = '';

                          loader.dismiss();
                          this.utils.toast(res.msg);
                        } else {
                          loader.dismiss();

                          this.presentAlert(res.msg, a);
                        }
                      });
                  });
                });
            }
          });
      });
    }
  }

  async crudConsult(a) {
    if (!this.isUpdate) {
      // Nouvelle Consultation ou Contrôle
      if (this.dateConsult === '') {
        this.utils.toastError('Vous devez seléctionner une date');
      } else if (this.utils.agentSante === '0' && this.prescripteur === '') {
        this.utils.toastError('Vous devez selectionner le Prescripteur');
      } else if (this.motif === '') {
        this.utils.toastError('Vous devez ajouter un Motif de consultation');
      } else {
        const loader = await this.loadingCtrl.create({
          message: 'Veuillez patientez ...',
          spinner: 'bubbles',
        });
        await loader.present();
        if (!this.isControle) {
          // Nouvelle consultation
          // Ajout dossier
          return new Promise((_resolve) => {
            const body = {
              action: 'CRUD_DOSSIER',
              patient_id: this.IdPatient,
              noDossier: this.noDossier,
            };

            this.accessProviders
              .postData(body, 'dossier.php')
              .subscribe((res: any) => {
                if (res.success === true) {
                  // Last ID Dossier
                  const body = {
                    action: 'GET_LAST_RECORD',
                    patient_id: this.IdPatient,
                  };

                  this.accessProviders
                    .postData(body, 'dossier.php')
                    .subscribe((res: any) => {
                      for (const data of res.result) {
                        this.LastDossier = parseInt(data.id);
                      }

                      // Consultation
                      this.consultation(a, loader);
                    });
                }
              });
          });
        } else {
          // Controle
          // Consultation
          this.consultation(a, loader);
        }
      }
    } else {
      // Modification
      const loader = await this.loadingCtrl.create({
        message: 'Veuillez patientez ...',
        spinner: 'bubbles',
      });
      await loader.present();

      // Consultation
      return new Promise((_resolve) => {
        const body = {
          action: 'CRUD_CONSULTATION',
          id: this.csID,
          motif: this.motif,
          hdm: this.hdm,
          clinique: this.clinique,
          paraclinique: this.paraclinique,
          diagnostic: this.diagnostic,
          rdv: this.rdv,
          CAT: this.CAT,
          crudAct: a,
        };

        this.accessProviders.postData(body, 'consultation.php').subscribe(
          (res: any) => {
            if (res.success === true) {
              this.clearInput();
              loader.dismiss();
              this.utils.toast(res.msg);
              this.modalCtrl.dismiss('', 'crud');
              this.patientService.getConsult(this.idDossier);
            } else {
              loader.dismiss();
              this.presentAlert(res.msg, a);
            }
          },
          (_err) => {
            loader.dismiss();
            this.presentAlert('Consultation', a);
          }
        );
      });
    }
  }

  consultation(a, loader) {
    if (this.utils.medecin === '0') {
      this.userId = this.prescripteur;
    } else {
      this.userId = this.utils.userId;
    }

    return new Promise((_resolve) => {
      const body = {
        action: 'CRUD_CONSULTATION',
        id: this.csID,
        dateCS: this.momentjs(this.dateConsult).format('YYYYMMDD'),
        motif: this.motif,
        hdm: this.hdm,
        clinique: this.clinique,
        paraclinique: this.paraclinique,
        CAT: this.CAT,
        diagnostic: this.diagnostic,
        rdv: this.rdv,
        patient_id: this.IdPatient,
        dossier_id: this.LastDossier,
        prescripteur_id: this.userId,
        crudAct: a,
      };

      this.accessProviders.postData(body, 'consultation.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            this.clearInput();
            loader.dismiss();
            this.utils.toast(res.msg);
          } else {
            loader.dismiss();
            this.presentAlert(res.msg, a);
          }
        },
        (_err) => {
          loader.dismiss();
          this.presentAlert('Consultation', a);
        }
      );
    });
  }

  clearMotif() {
    this.motif = '';
  }

  clearHDM() {
    this.hdm = '';
  }

  clearClinique() {
    this.clinique = '';
  }

  clearParaclinique() {
    this.paraclinique = '';
  }

  clearDiag() {
    this.diagnostic = '';
  }

  clearCAT() {
    this.CAT = '';
  }

  async presentAlert(a, b) {
    const alert = await this.alertCtrl.create({
      header: a,
      backdropDismiss: false,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'Réessayer',
          handler: () => {
            this.crudConsult(b);
          },
        },
        {
          text: 'Annuler',
          role: 'cancel',
          handler: (_blah) => {},
        },
      ],
    });

    await alert.present();
  }

  async addRMA_AUTRE(d_id) {
    this.calculAge();

    this.rmaService.addRMA_AUTRE(
      this.patient.sexe,
      this.age,
      d_id,
      this.dateConsult
    );
  }

  async addRMA_PALU(
    fievre,
    rdt,
    micro,
    _101,
    _110,
    _111,
    micro_p,
    m,
    f,
    act,
    prima,
    arte_suppo,
    arte_cp
  ) {
    this.calculAge();

    this.rmaService.addRMA_PALU(
      this.age,
      this.dateConsult,
      fievre,
      rdt,
      micro,
      _101,
      _110,
      _111,
      micro_p,
      m,
      f,
      act,
      prima,
      arte_suppo,
      arte_cp
    );
  }

  async openDiag() {
    if (!this.isUpdate) {
      if (this.dateConsult === '') {
        this.utils.toastError(
          "Vous devez remplir la date avant d'ajouter un diagnostic"
        );
        return;
      } else {
        this.openSearchDiag(this.patient.sexe);
      }
    } else {
      this.openSearchDiag(this.patient.sexe);
    }
  }

  async openSearchDiag(sexe) {
    this.calculAge();

    const modal = await this.modalCtrl.create({
      component: SearchDiagComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      componentProps: { sexe: sexe },
      cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.storage.getData('diag').then((d: any) => {
        if (d.id <= 74 && d.id >= 1) {
          this.rmaService.addRMA_NC(
            this.patient.sexe,
            this.age,
            d.id,
            this.dateConsult
          );

          if (d.id == 23) {
            this.rmaService.addRMA_GEN(this.dateConsult);
          }
          if (d.id === '66') {
            // Autres nouveaux cas de maladies non épidémiques ...
            this.openOtherCase();
          } else {
            this.diagnostic += '- ' + d.libelle + '\n';
          }
        } else if (d.id <= 79 && d.id >= 77) {
          this.rmaService.addRMA_MC(
            this.patient.sexe,
            this.age,
            d.id,
            this.dateConsult
          );
          this.diagnostic += '- ' + d.libelle + '\n';
        } else if (d.id <= 86 && d.id >= 80) {
          this.rmaService.addRMA_TRAUMA(
            this.patient.sexe,
            this.age,
            d.id,
            this.dateConsult
          );

          this.diagnostic += '- ' + d.libelle + '\n';
        } else if (d.id >= 87) {
          if (d.id === '87' || d.id === '88' || d.id === '89') {
            // CPN
            this.openScol(d);
          } else {
            this.rmaService.addRMA_CPN(this.dateConsult, d.libelle, '');
            this.diagnostic += '- ' + d.libelle + '\n';
          }
        }
      });
    }
  }

  async openOtherCase() {
    const modal = await this.modalCtrl.create({
      component: AutreCasComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win9-modal-web' : 'win9-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.storage.getData('newCase').then((ac: any) => {
        this.rmaService.addRMA_AC(this.dateConsult, ac);
        this.diagnostic += '- ' + ac + '\n';
      });
    }
  }

  async openScol(d) {
    const modal = await this.modalCtrl.create({
      component: AddScolComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win9-modal-web' : 'win9-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.storage.getData('scol').then((sc: any) => {
        this.rmaService.addRMA_CPN(this.dateConsult, d.libelle, sc);
        this.diagnostic += '- ' + d.libelle + '\n';
      });
    }
  }
}
