import { PDFService } from './../../../../../services/pdf.service';
import { Component, OnInit } from '@angular/core';

// PDFMake
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import {
  PopoverController,
  NavParams,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { ConsultationUpdateComponent } from '../../../crud/consultation-update/consultation-update.component';
import { PatientService } from 'src/app/services/patient.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-option-detail',
  templateUrl: './option-detail.component.html',
  styleUrls: ['./option-detail.component.scss'],
})
export class OptionDetailComponent implements OnInit {
  csID: any;
  patient: any;
  consultation: any;
  visible = false;

  momentjs: any = moment;

  constructor(
    private popCtrl: PopoverController,
    private navParams: NavParams,
    private alertCtrl: AlertController,
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public pdfService: PDFService,
    public patientService: PatientService
  ) {
    this.utils.checkPlatform();

    this.patient = this.navParams.get('patient');
    this.consultation = this.navParams.get('consultation');
    this.csID = this.consultation.cID;
    this.pdfService.patient = this.patient.nom + ' ' + this.patient.prenom;
    this.pdfService.consultation = this.consultation;
    this.pdfService.noDossier = this.consultation.noDossier;
    this.pdfService.prescripteur = this.consultation.name;
    this.pdfService.patientID = this.patient.id;
  }

  ngOnInit() {
    // this.getPrescripteur();
    this.utils.getSession();
  }

  ionViewDidEnter() {}

  toastAffiche() {
    this.utils.toastError(
      "Vous ne pouvez pas effectuer cette opération car vous n'êtes pas le prescripteur"
    );
  }

  async openEdit() {
    this.popCtrl.dismiss();
    this.modalCtrl.dismiss();

    const modal = await this.modalCtrl.create({
      component: ConsultationUpdateComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        consultation: this.consultation,
        patient: this.patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'cs-modal-web' : 'cs-modal-mobile',
    });
    return await modal.present();
  }

  delCS() {
    // this.popCtrl.dismiss();
    return new Promise(async () => {
      const body = {
        action: 'DELETE',
        consultation_id: this.csID,
      };

      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        message: 'Voulez-vous vraiment supprimer cette consultation ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.accessProviders
                .postData(body, 'consultation.php')
                .subscribe((res: any) => {
                  if (res.success === true) {
                    this.utils.toast('Suppression éffectuée');
                    this.popCtrl.dismiss();
                    this.modalCtrl.dismiss();
                    this.patientService.loadDossier(this.consultation.dateCS);
                  } else {
                    this.utils.toastError('Erreur de suppression');
                  }
                });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });

      await alert.present();
    });
  }
}
