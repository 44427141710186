import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  ModalController,
  LoadingController,
  NavController,
  MenuController,
} from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  type = true;

  login = {
    username: '',
    password: '',
  };

  isSubmitted = false;

  constructor(
    private loadingCtrl: LoadingController,
    public navCtrl: NavController,
    public accessProviders: AccessProviders,
    public formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    private authService: AuthService
  ) {}

  async dismiss() {
    await this.modalCtrl.dismiss(null, 'close');
  }

  async ngOnInit() {}

  changeType() {
    this.type = !this.type;
  }

  async submitForm() {
    this.isSubmitted = true;
    if (this.login.username == '' || this.login.password == '') {
      this.utils.toastError('Veuillez entrer les données réquises');
      return false;
    } else {
      const loader = await this.loadingCtrl.create({
        message: 'Veuillez patientez ...',
        spinner: 'bubbles',
      });
      await loader.present();

      this.authService.login(this.login.username, this.login.password);
      this.dismiss();
      loader.dismiss();
    }
  }

  openSettings() {
    this.dismiss();
    this.authService.openConnexion();
  }
}
