import { ModalController, AlertController } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { Injectable } from '@angular/core';
import { NgxNumToWordsService } from 'ngx-num-to-words';
import { ProductService } from './product.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PrestationService {
  consultations: any = [];
  actes: any = [];
  others: any = [];
  filteredData = [];

  prestation = {
    libelle: '',
    tarif: 0,
  };

  constructor(
    private accessProviders: AccessProviders,
    public ngxNumToWordsService: NgxNumToWordsService,
    public productService: ProductService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {}

  ////////// OBSERVABLES /////////////
  _consultations = new BehaviorSubject<any[]>([]);
  _actes = new BehaviorSubject<any[]>([]);
  _others = new BehaviorSubject<any[]>([]);
  _isLoading = new BehaviorSubject<boolean>(true);
  _isCSEmpty = new BehaviorSubject<boolean>(false);
  _isActEmpty = new BehaviorSubject<boolean>(false);
  _isOtherEmpty = new BehaviorSubject<boolean>(false);

  //////////// ASSECEURS ////////////
  get _isLoading$() {
    return this._isLoading.asObservable();
  }

  get _isCSEmpty$() {
    return this._isCSEmpty.asObservable();
  }

  get _isActEmpty$() {
    return this._isActEmpty.asObservable();
  }

  get _isOtherEmpty$() {
    return this._isOtherEmpty.asObservable();
  }

  get _consultations$() {
    return this._consultations.asObservable();
  }

  get _actes$() {
    return this._actes.asObservable();
  }

  get _others$() {
    return this._others.asObservable();
  }
  //////////// ASSECEURS ////////////

  clearAll() {
    this.prestation.libelle = '';
    this.prestation.tarif = 0;
  }
  async loadConsult() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL_CS',
      };

      this.accessProviders
        .postData(body, 'prestation.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isCSEmpty.next(true);
            this._isLoading.next(false);
          } else {
            this.consultations = [];
            this._isCSEmpty.next(false);
            for (const data of res.result) {
              this.consultations.push(data);
              this.filteredData = this.consultations;
              this._consultations.next(this.consultations);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  async loadActes() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL_ACTE',
      };

      this.accessProviders
        .postData(body, 'prestation.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isActEmpty.next(true);
            this._isLoading.next(false);
          } else {
            this.actes = [];
            this._isActEmpty.next(false);
            for (const data of res.result) {
              this.actes.push(data);
              this.filteredData = this.actes;
              this._actes.next(this.actes);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  async loadOthers() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL_AUTRE',
      };

      this.accessProviders
        .postData(body, 'prestation.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isOtherEmpty.next(true);
            this._isLoading.next(false);
          } else {
            this.others = [];
            this._isOtherEmpty.next(false);
            for (const data of res.result) {
              this.others.push(data);
              this.filteredData = this.others;
              this._others.next(this.others);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  async crud(type, a) {
    return new Promise((resolve) => {
      const body = {
        action: 'CRUD',
        libelle: this.prestation.libelle,
        tarif: this.prestation.tarif,
        type: type,
        crudAct: a,
      };

      this.accessProviders
        .postData(body, 'prestation.php')
        .subscribe((res: any) => {
          if (res.success === true) {
            this.modalCtrl.dismiss('', 'crud');
          }

          resolve(true);
        });
    });
  }

  filterListActes(evt) {
    this._actes.next(this.filteredData);
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._actes.next(
      this.filteredData.filter((item: { libelle: string }) => {
        return (
          item.libelle.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      })
    );
  }

  filterListCS(evt) {
    this._consultations.next(this.filteredData);
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._consultations.next(
      this.filteredData.filter((item: { libelle: string }) => {
        return (
          item.libelle.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      })
    );
  }

  filterListOther(evt) {
    this._others.next(this.filteredData);
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._others.next(
      this.filteredData.filter((item: { libelle: string }) => {
        return (
          item.libelle.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      })
    );
  }

  async deletePrestation(id) {
    return new Promise(async () => {
      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        message: 'Voulez-vous vraiment supprimer ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              // Suppression de ses consultations
              return new Promise(async () => {
                const bodyConsult = {
                  action: 'DELETE',
                  id: id,
                };

                this.accessProviders
                  .postData(bodyConsult, 'prestation.php')
                  .subscribe(() => {
                    this.loadConsult();
                    this.loadActes();
                    this.loadOthers();
                  });
              });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });
      await alert.present();
    });
  }
}
