import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maternite',
  templateUrl: './maternite.component.html',
  styleUrls: ['./maternite.component.scss'],
})
export class MaterniteComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
