import { MedocService } from './../../services/medoc.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DetailMedocComponent } from './detail-medoc/detail-medoc.component';
import { PosologieComponent } from './posologie/posologie.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';

@Component({
  selector: 'app-medoc',
  templateUrl: './medoc.component.html',
  styleUrls: ['./medoc.component.scss'],
})
export class MedocComponent implements OnInit {
  medicaments = [];
  isLoading: boolean;
  isEmpty: boolean;

  constructor(
    public accessProviders: AccessProviders,
    public platform: Platform,
    public router: Router,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public medocService: MedocService
  ) {
    this.medocService.loadMedoc();

    this.utils.checkPlatform();
  }
  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();

    this.medocService._medicaments$.subscribe((medicaments: any[]) => {
      this.medicaments = medicaments;
    });

    this.medocService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });

    this.medocService._isEmpty$.subscribe((isEmpty: any) => {
      this.isEmpty = isEmpty;
    });
  }

  async ionViewDidEnter() {
    this.medocService.loadMedoc();
  }
}
