import { AppService } from './../../../services/app.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddEventComponent } from './../add-event/add-event.component';
import { AssignRdvComponent } from './../assign-rdv/assign-rdv.component';
import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';

@Component({
  selector: 'app-rdv',
  templateUrl: './rdv.component.html',
  styleUrls: ['./rdv.component.scss'],
})
export class RdvComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public appService: AppService
  ) {
    this.utils.checkPlatform();

    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();
  }

  ngOnInit() {
    this.appService.loadRDV();
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }
}
