import {
  LocationStrategy,
  PathLocationStrategy,
  HashLocationStrategy,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'patients',
    loadChildren: () =>
      import('./pages/patients/patients.module').then(
        (m) => m.PatientsPageModule
      ),
  },
  {
    path: 'appointment',
    loadChildren: () =>
      import('./pages/appointment/appointment.module').then(
        (m) => m.AppointmentPageModule
      ),
  },
  {
    path: 'consultations',
    loadChildren: () =>
      import('./pages/consultations/consultations.module').then(
        (m) => m.ConsultationsPageModule
      ),
  },
  {
    path: 'utilisateurs',
    loadChildren: () =>
      import('./pages/utilisateurs/utilisateurs.module').then(
        (m) => m.UtilisateursPageModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'caisse',
    loadChildren: () =>
      import('./pages/caisse/caisse.module').then((m) => m.CaissePageModule),
  },
  {
    path: 'stock',
    loadChildren: () =>
      import('./pages/stock/stock.module').then((m) => m.StockPageModule),
  },
  // {
  //   path: 'medicaments',
  //   loadChildren: () =>
  //     import('./pages/medicaments/medicaments.module').then(
  //       (m) => m.MedicamentsPageModule
  //     ),
  // },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: 'dossier-medical',
    loadChildren: () =>
      import('./pages/dossier-medical/dossier-medical.module').then(
        (m) => m.DossierMedicalPageModule
      ),
  },
  {
    path: 'labo-cm',
    loadChildren: () =>
      import('./pages/labo-cm/labo-cm.module').then((m) => m.LaboCMPageModule),
  },
  {
    path: 'salle-attente',
    loadChildren: () =>
      import('./pages/salle-attente/salle-attente.module').then(
        (m) => m.SalleAttentePageModule
      ),
  },
  {
    path: 'rapport',
    loadChildren: () =>
      import('./pages/rapport/rapport.module').then((m) => m.RapportPageModule),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      // preloadingStrategy: PreloadAllModules,
      preloadingStrategy: QuicklinkStrategy,
    }),
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
