import { UtilsService } from 'src/app/services/utils.service';
import { AccessProviders } from './../providers/access-providers.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

// PDFMake
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import {
  Platform,
  ModalController,
  AlertController,
  PopoverController,
} from '@ionic/angular';
import { DetailConsultComponent } from '../components/consultations/detail-consult/detail-consult.component';
import { ConsultPrescripteurComponent } from '../components/consultations/consult-prescripteur/consult-prescripteur.component';
import { CurrentConsultComponent } from '../components/consultations/current-consult/current-consult.component';
import { ConsultationUpdateComponent } from './../components/patients/crud/consultation-update/consultation-update.component';
import { UserService } from 'src/app/services/user.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConsultationService {
  public dates: any = [];
  public mois: string;
  public consultations: any = [];
  public consultPrescr: any = [];
  dossiers: any = [];
  patientID; // patientID

  consult; // Detail CS

  // Option CS
  patient = null;
  consultation: any = [];
  noDossier: any = '';
  prescripteur: any = '';

  nbDossierPatient: number;
  nbCS: string;
  nbCs$: number;

  // Nombre de consultations
  public nbCsDossier = 0;
  public nbCsD: string;

  public dateConsult: any = '';
  public date: any;
  public consultations$: any = [];
  public dates$: any = [];

  mois$: any = [];
  annees: any = [];
  automaticClose = false;

  // Nombre de dossiers
  nbCsDossierD: number;
  nbCsDos: string;

  // consultations: any = [];
  nbConsult: number;
  nbCs: string;
  consultationJour: number;

  // For PDF
  public pdfObj = null;
  public username: string;

  public momentjs: any = moment;

  date1;
  date2;
  dateRange;

  constructor(
    private accessProviders: AccessProviders,
    private platform: Platform,
    private fileOpener: FileOpener,
    private file: File,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    private alertCtrl: AlertController,
    private popCtrl: PopoverController,
    public userService: UserService
  ) {
    this.utils.getSession();
  }

  _consultations = new BehaviorSubject<any[]>([]);
  _nbCsD = new BehaviorSubject<string>('');
  _nbCs = new BehaviorSubject<number>(0);
  _nbCsDossier = new BehaviorSubject<number>(0);
  _isLoading = new BehaviorSubject<boolean>(true);
  _isEmpty = new BehaviorSubject<boolean>(false);

  get _consultations$() {
    return this._consultations.asObservable();
  }

  get _nbCsD$() {
    return this._nbCsD.asObservable();
  }

  get _nbCs$() {
    return this._nbCs.asObservable();
  }

  get _nbCsDossier$() {
    return this._nbCsDossier.asObservable();
  }

  get _isLoading$() {
    return this._isLoading.asObservable();
  }

  get _isEmpty$() {
    return this._isEmpty.asObservable();
  }

  ngOnInit() {
    this.automaticClose = true;
  }

  async openCurrentCS() {
    const modal = await this.modalCtrl.create({
      component: CurrentConsultComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win14-modal-web' : 'win14-modal-mobile',
    });
    return await modal.present();
  }

  async openUserCS() {
    const modal = await this.modalCtrl.create({
      component: ConsultPrescripteurComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win14-modal-web' : 'win14-modal-mobile',
    });
    return await modal.present();
  }

  async loadMoisPrescr(prescripteur) {
    this.date = '';
    this.mois = '';
    this.nbCsD = '';
    this._nbCsD.next(this.nbCsD);

    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_MOIS_PRESCRIPTEUR',
        prescripteur: prescripteur,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.consultations = [];
          this._consultations.next(this.consultations);
          this.dates = [];
          for (const data of res.result) {
            this.dates.push(data);
          }
          resolve(true);
        });
    });
  }

  async nbConsultationJourPrescripteur() {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_DATE_PRESCRIPTEUR',
        prescripteur_id: this.utils.userId,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCs$ = res;
          this._nbCs.next(this.nbCs$);
        });
    });
  }

  async nbConsultationDate(date) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_DATE',
        dateCS: date,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossier = res;
          this._nbCsDossier.next(this.nbCsDossier);
          this.nbCsD = this.nbCsDossier + ' consultation(s)';
          this._nbCsD.next(this.nbCsD);
        });
    });
  }

  async nbConsultationDateRange(date1, date2) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_DATE_RANGE',
        date1: date1,
        date2: date2,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossier = res;
          this._nbCsDossier.next(this.nbCsDossier);
          this.nbCsD = this.nbCsDossier + ' consultation(s)';
          this._nbCsD.next(this.nbCsD);
        });
    });
  }

  async nbConsultationM(date) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_MONTH',
        month: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossier = res;
          this._nbCsDossier.next(this.nbCsDossier);
          this.nbCsD = this.nbCsDossier + ' consultation(s)';
          this._nbCsD.next(this.nbCsD);
        });
    });
  }

  async nbConsultationY(year) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_YEAR',
        year: year,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossier = res;
          this._nbCsDossier.next(this.nbCsDossier);
          this.nbCsD = this.nbCsDossier + ' consultation(s)';
          this._nbCsD.next(this.nbCsD);
        });
    });
  }

  async nbConsultationParJour() {
    return new Promise((_resolve) => {
      const body = {
        action: 'CONSULTATION_JOUR',
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.consultationJour = res;
        });
    });
  }

  async nbConsultationPrescripteur(prescripteur) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_PRESCRIPTEUR',
        prescripteur,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossier = res;
          this._nbCsDossier.next(this.nbCsDossier);
          this.nbCsD = this.nbCsDossier + ' consultation(s)';
          this._nbCsD.next(this.nbCsD);
        });
    });
  }

  async nbConsultationMois(mois, prescripteur) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_MOIS',
        mois,
        prescripteur,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossier = res;
          this._nbCsDossier.next(this.nbCsDossier);
          this.nbCsD = this.nbCsDossier + ' consultation(s)';
          this._nbCsD.next(this.nbCsD);
        });
    });
  }

  loadConsult(prescripteur) {
    this.username = prescripteur;
    this.nbConsultationPrescripteur(prescripteur);

    return new Promise((resolve) => {
      const body = {
        action: 'LOAD_CONSULTATION_PRESCRIPTEUR',
        prescripteur: prescripteur,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.consultations = [];
          this._consultations.next(this.consultations);
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (const data of res.result) {
              this.consultations.push(data);
              this._consultations.next(this.consultations);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  allConsult() {
    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_CONSULTATION',
        patient_id: this.patientID,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.consultations = [];
          this._consultations.next(this.consultations);
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (let data of res.result) {
              this.consultations.push(data);
              this._consultations.next(this.consultations);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  getCS(date, prescripteur) {
    this.date = '';
    this.username = prescripteur;

    this.date = this.momentjs(date).format('YYYYMM');
    this.mois =
      this.utils.formatMois(date).substring(0, 1).toUpperCase() +
      this.utils.formatMois(date).substring(1, 15).toLowerCase() +
      ' ' +
      this.utils.formatAnnee(date);
    this.nbConsultationMois(this.date, prescripteur);

    return new Promise((resolve) => {
      const body = {
        action: 'LOAD_CONSULTATION_MOIS',
        mois: this.date,
        prescripteur,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.consultations = [];
          this._consultations.next(this.consultations);
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (const data of res.result) {
              this.consultations.push(data);
              this._consultations.next(this.consultations);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  getAllConsult(date) {
    this.date = this.momentjs(date).format('YYYYMMDD');
    this.nbConsultationDate(this.date);

    return new Promise((resolve) => {
      const body = {
        action: 'GET_CONSULTATION_DATE',
        dateCS: date,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.consultations = [];
          this._consultations.next(this.consultations);
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (const data of res.result) {
              this.consultations.push(data);
              this._consultations.next(this.consultations);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  getConsultRange(date1, date2) {
    this.nbConsultationDateRange(date1, date2);

    return new Promise((resolve) => {
      const body = {
        action: 'GET_CONSULTATION_DATE_RANGE',
        date1: date1,
        date2: date2,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.consultations = [];
          this._consultations.next(this.consultations);
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (const data of res.result) {
              this.consultations.push(data);
              this._consultations.next(this.consultations);
              this._isLoading.next(false);
            }

            resolve(true);
          }
        });
    });
  }

  getConsultM(date) {
    this.consultations = [];
    this._consultations.next(this.consultations);

    this.nbConsultationM(date);

    return new Promise((resolve) => {
      const body = {
        action: 'GET_CONSULTATION_DATE_MONTH',
        month: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (const data of res.result) {
              this.consultations.push(data);
              this._consultations.next(this.consultations);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  getConsultY(year) {
    this.consultations = [];
    this._consultations.next(this.consultations);

    this.nbConsultationY(year);

    return new Promise((resolve) => {
      const body = {
        action: 'GET_CONSULTATION_DATE_YEAR',
        year: year,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (const data of res.result) {
              this.consultations.push(data);
              this._consultations.next(this.consultations);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  clearAll() {
    this.consultations = [];
    this._consultations.next(this.consultations);
    this.userService.prescripteur = '';
    this.dateConsult = '';
    this.date = '';
    this.nbCsD = '';
    this.dates = [];
    this._nbCsD.next(this.nbCsD);
  }

  toggleAnnee(index) {
    this.annees[index].open = !this.annees[index].open;

    if (this.automaticClose && this.annees[index].open) {
      this.annees
        .filter((item, itemIndex) => itemIndex != index)
        .map((item) => (item.open = false));
    }
  }

  toggleMois(index) {
    this.mois$[index].open = !this.mois$[index].open;

    if (this.automaticClose && this.mois$[index].open) {
      this.mois$
        .filter((item, itemIndex) => itemIndex != index)
        .map((item) => (item.open = false));
    }
  }

  toggleDate(index) {
    this.dates$[index].open = !this.dates$[index].open;

    if (this.automaticClose && this.dates$[index].open) {
      this.dates$
        .filter((item, itemIndex) => itemIndex != index)
        .map((item) => (item.open = false));
    }
  }

  toggleDossiers(index) {
    this.dossiers[index].open = !this.dossiers[index].open;

    if (this.automaticClose && this.dossiers[index].open) {
      this.dossiers
        .filter((item, itemIndex) => itemIndex != index)
        .map((item) => (item.open = false));
    }
  }

  async getDate(date) {
    this.dates$ = [];
    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_DATE_CONSULTATION',
        dateCS: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.dates$.push(data);
          }
          resolve(true);
        });
    });
  }
  async getMoisPatient(date) {
    this.mois$ = [];
    return new Promise((resolve) => {
      let body = {
        action: 'GET_MOIS',
        dateCS: this.momentjs(date).format('YYYY'),
        patient_id: this.patientID,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.mois$.push(data);
          }
          resolve(true);
        });
    });
  }

  async getMois(date) {
    this.mois$ = [];
    return new Promise((resolve) => {
      let body = {
        action: 'GET_MOIS_CONSULTATION',
        dateCS: this.momentjs(date).format('YYYY'),
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.mois$.push(data);
          }
          resolve(true);
        });
    });
  }

  async loadMoisPatient() {
    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_MOIS',
        patient_id: this.patientID,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.mois$.push(data);
          }
          resolve(true);
        });
    });
  }

  async loadMois() {
    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_MOIS_CONSULTATION',
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.mois$.push(data);
          }
          resolve(true);
        });
    });
  }

  async loadAnneePatient() {
    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_ANNEE',
        patient_id: this.patientID,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            for (let data of res.result) {
              this.annees.push(data);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  async loadAnnee() {
    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_ANNEE_CONSULTATION',
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            for (let data of res.result) {
              this.annees.push(data);

              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  async nbConsultationPatient() {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_DOSSIER',
        patient_id: this.patientID,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbDossierPatient = res;
          this.nbCS = this.nbDossierPatient + ' dossier(s)';

          if (this.nbDossierPatient == 0) {
            this._isLoading.next(false);
          }
        });
    });
  }

  async nbConsultJour() {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_DATE',
        dateCS: this.momentjs(new Date()).format('YYYYMMDD'),
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossierD = res;
          this._nbCsDossier.next(this.nbCsDossier);
          this.nbCsDos = this.nbCsDossier + ' consultation(s)';
        });
    });
  }

  async nbConsultationD(date) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_DATE',
        dateCS: date,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossierD = res;
          this._nbCsDossier.next(this.nbCsDossier);
          this.nbCsDos = this.nbCsDossier + ' consultation(s)';
        });
    });
  }
  async nbConsultationDossier(dossier_id) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_DOSSIER',
        patient_id: this.patientID,
        dossier_id: dossier_id,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossier = res;
          this._nbCsDossier.next(this.nbCsDossier);
          this.nbCsD = this.nbCsDossier + ' consultation(s)';
          this._nbCsD.next(this.nbCsD);
        });
    });
  }

  getConsultD(dossier) {
    this.noDossier = dossier;
    this.nbConsultationDossier(dossier);

    return new Promise((resolve) => {
      let body = {
        action: 'GET_CONSULT',
        dossier_id: dossier,
        patient_id: this.patientID,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.consultations = [];
          this._consultations.next(this.consultations);
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (let data of res.result) {
              this.consultations.push(data);
              this._consultations.next(this.consultations);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  getConsult(date) {
    this.date = date;
    this.nbConsultationD(date);
    this.consultations$ = [];

    return new Promise((resolve) => {
      let body = {
        action: 'GET_CONSULTATION_DATE',
        dateCS: this.date,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (let data of res.result) {
              this.consultations$.push(data);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  async loadDossier(dateCS) {
    this.dossiers = [];
    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_CONSULT_BY_ID',
        patient_id: this.patientID,
        dateCS: this.momentjs(dateCS).format('YYYYMM'),
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.dossiers.push(data);
            this._isLoading.next(false);
          }
          resolve(true);
        });
    });
  }

  async nbConsultation() {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION',
        // date: this.date
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbConsult = res;
          this.nbCs = this.consultation + ' consultation(s)';
        });
    });
  }

  async nbConsultationDatePrescr(date) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_DATE_PRESCRIPTEUR',
        dateCS: date,
        prescripteur_id: this.utils.userId,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossier = res;
          this._nbCsDossier.next(this.nbCsDossier);
          this.nbCsD = this.nbCsDossier + ' consultation(s)';
          this._nbCsD.next(this.nbCsD);

          if (this.nbCsDossier === 0) {
            this._isLoading.next(false);
          }
        });
    });
  }

  loadAllConsult() {
    this.consultations = [];
    this._consultations.next(this.consultations);
    this.nbConsultationDatePrescr(this.date);
    return new Promise((resolve) => {
      const body = {
        action: 'LOAD_CONSULTATION_DATE_PRESCRIPTEUR',
        dateCS: this.utils.currentDate,
        prescripteur_id: this.utils.userId,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (let data of res.result) {
              this.consultations.push(data);
              this._consultations.next(this.consultations);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  delConsult(id) {
    return new Promise(async (_resolve) => {
      const body = {
        action: 'DELETE',
        consultation_id: id,
      };

      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        message: 'Voulez-vous vraiment supprimer cette consultation ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.accessProviders
                .postData(body, 'consultation.php')
                .subscribe((res: any) => {
                  if (res.success === true) {
                    this.utils.toast('Suppression éffectuée');
                    this.getDate(this.consult.dateCS);
                    this.loadConsult(this.consult.name);
                    this.modalCtrl.dismiss();
                  } else {
                    this.utils.toastError('Erreur de suppression');
                  }
                });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });

      await alert.present();
    });
  }

  async openDetail(consult, showPrescr) {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: DetailConsultComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        consult: consult,
        showPrescr: showPrescr,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win4-modal-web' : 'win4-modal-mobile',
    });
    return await modal.present();
  }

  async loadSingleConsultation() {
    return new Promise((_resolve) => {
      const body = {
        action: 'LOAD_SINGLE_CONSULTATION',
        consultation_id: this.consult.cID,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isLoading.next(false);
            this._isEmpty.next(true);
          } else {
            this._isEmpty.next(false);
            for (const data of res.result) {
              this.consultations.push(data);
              this._consultations.next(this.consultations);
              this.prescripteur = data.name;
              this._isLoading.next(false);
            }
            _resolve(true);
          }
        });
    });
  }

  async showOptions(ev, id, noDossier) {
    const pop = await this.popCtrl.create({
      component: Option,
      event: ev,
      componentProps: {
        custom_id: id,
        data: this.consultations,
        noDossier: noDossier,
        prescripteur: this.prescripteur,
      },
    });
    await pop.present();
  }

  async openEdit() {
    this.popCtrl.dismiss(this.patientID);

    const modal = await this.modalCtrl.create({
      component: ConsultationUpdateComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        consultation_id: this.patientID,
        noDossier: this.noDossier,
      },
      backdropDismiss: false,
      cssClass: 'crud-modal',
    });
    return await modal.present();
  }

  delCS() {
    return new Promise(async () => {
      const body = {
        action: 'DELETE_FOR_PATIENT',
        patient_id: this.patientID,
      };

      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        subHeader: 'Voulez-vous vraiment supprimer cette consultation ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.accessProviders
                .postData(body, 'consultation.php')
                .subscribe((res: any) => {
                  if (res.success == true) {
                    this.utils.toast('Suppression éffectuée');
                    this.popCtrl.dismiss();
                  } else {
                    this.utils.toastError('Erreur de suppression');
                  }
                });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });

      await alert.present();
    });
  }

  createPDF() {
    let dd = {
      pageSize: 'A4',
      watermark: {
        text: 'Focus GCM',
        color: 'grey',
        opacity: 0.1,
        bold: true,
        italics: false,
      },
      content: [],
      styles: {
        f18: {
          fontSize: 16,
          margin: [0, 10, 0, 10],
        },
        strong: {
          bold: true,
        },
        title: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
        content: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
      },
    };

    // Toutes ses consultations
    dd.content.push({
      text: 'Date de consultation : ' + this.utils.formatDate(this.date),
      alignment: 'center',
      style: ['f18', 'strong'],
    });

    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });
    dd.content.push({
      columns: [
        { text: 'CONSULTATIONS : ' + this.nbCsD, bold: true, style: 'content' },
      ],
    });
    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });

    for (let i = 0; i < this.consultations.length; i++) {
      dd.content.push({
        text:
          'Patient : ' +
          this.consultations[i].nom +
          ' ' +
          this.consultations[i].prenom,
        alignment: 'left',
        style: ['f18', 'strong'],
      });
      dd.content.push({
        columns: [
          { text: 'Motif', bold: true },
          { text: this.consultations[i].motif, width: '70%' },
        ],
        style: 'content',
      });

      if (this.consultations[i].hdm !== '') {
        dd.content.push({
          columns: [
            { text: 'HDM', bold: true },
            { text: this.consultations[i].hdm, width: '70%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].IdClinique !== null) {
        dd.content.push({
          columns: [
            { text: 'Clinique', bold: true },
            { text: this.consultations[i].clinique, width: '70%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].IdParaclinique !== null) {
        dd.content.push({
          columns: [
            { text: 'Paraclinique', bold: true },
            { text: this.consultations[i].paraclinique, width: '70%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].CAT !== '') {
        dd.content.push({
          columns: [
            { text: 'Conduite à tenir', bold: true },
            { text: this.consultations[i].CAT, width: '70%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].diagnostic !== '') {
        dd.content.push({
          columns: [
            { text: 'Diagnostic', bold: true },
            { text: this.consultations[i].diagnostic, width: '70%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].name !== '') {
        dd.content.push({
          columns: [
            { text: 'Prescripteur', bold: true },
            { text: this.consultations[i].name, width: '70%' },
          ],
          style: 'content',
        });
      }
      dd.content.push({
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 595 - 2 * 40,
            y2: 5,
            lineWidth: 0.5,
          },
        ],
      });
      dd.content.push('  ');
    }

    this.pdfObj = pdfMake.createPdf(dd); //create pdf

    if (this.platform.is('cordova')) {
      this.pdfObj.getBuffer((buffer) => {
        let blob = new Blob([buffer], { type: 'application/pdf' });

        // Save the PDF to the data Directory of our App
        this.file
          .writeFile(this.file.dataDirectory, 'file.pdf', blob, {
            replace: true,
          })
          .then(() => {
            // Open the PDf with the correct OS tools
            this.fileOpener.open(
              this.file.dataDirectory + 'file.pdf',
              'application/pdf'
            );
          });
      });
    } else {
      // On a browser simply use download!
      this.pdfObj.download(this.momentjs(this.date).format('DD-MM-YYYY'));
    }
  }

  createPDFFromDate() {
    let dd = {
      pageSize: 'A4',
      watermark: {
        text: 'Focus GCM',
        color: 'grey',
        opacity: 0.05,
        bold: true,
        italics: false,
      },
      content: [],
      styles: {
        f18: {
          fontSize: 16,
          margin: [0, 10, 0, 10],
        },
        strong: {
          bold: true,
        },
        title: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
        content: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
      },
    };

    // Toutes ses consultations
    var dateCS;
    if (this.date2 != '') {
      dateCS =
        this.momentjs(this.date1).format('DD MMM YYYY') +
        ' au ' +
        this.momentjs(this.date2).format('DD MMM YYYY');
    } else {
      dateCS = this.momentjs(this.date1).format('DD MMM YYYY');
    }
    dd.content.push({
      text: 'Date de consultation : ' + dateCS,
      alignment: 'center',
      style: ['f18', 'strong'],
    });

    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });
    dd.content.push({
      columns: [
        { text: 'CONSULTATIONS : ' + this.nbCsD, bold: true, style: 'content' },
      ],
    });
    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });

    for (let i = 0; i < this.consultations.length; i++) {
      dd.content.push({
        text:
          'Patient : ' +
          this.consultations[i].nom +
          ' ' +
          this.consultations[i].prenom,
        alignment: 'left',
        style: ['f16', 'strong'],
      });
      dd.content.push({
        columns: [
          { text: 'Motif', bold: true },
          { text: this.consultations[i].motif, width: '80%' },
        ],
        style: 'content',
      });

      if (this.consultations[i].hdm !== '') {
        dd.content.push({
          columns: [
            { text: 'HDM', bold: true },
            { text: this.consultations[i].hdm, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].clinique !== '') {
        dd.content.push({
          columns: [
            { text: 'Clinique', bold: true },
            { text: this.consultations[i].clinique, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].paraclinique !== '') {
        dd.content.push({
          columns: [
            { text: 'Paraclinique', bold: true },
            { text: this.consultations[i].paraclinique, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].CAT !== '') {
        dd.content.push({
          columns: [
            { text: 'Conduite à tenir', bold: true },
            { text: this.consultations[i].CAT, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (
        this.consultations[i].diagnostic !== '' ||
        this.consultations[i].diagnostic !== '-'
      ) {
        dd.content.push({
          columns: [
            { text: 'Diagnostic', bold: true },
            { text: this.consultations[i].diagnostic, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].prescripteur !== '') {
        dd.content.push({
          columns: [
            { text: 'Prescripteur', bold: true },
            { text: this.consultations[i].prescripteur, width: '80%' },
          ],
          style: 'content',
        });
      }
      dd.content.push({
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 595 - 2 * 40,
            y2: 5,
            lineWidth: 0.5,
          },
        ],
      });
      dd.content.push('  ');
    }

    this.pdfObj = pdfMake.createPdf(dd); //create pdf

    if (this.platform.is('cordova')) {
      this.pdfObj.getBuffer((buffer) => {
        let blob = new Blob([buffer], { type: 'application/pdf' });

        // Save the PDF to the data Directory of our App
        this.file
          .writeFile(this.file.dataDirectory, 'file.pdf', blob, {
            replace: true,
          })
          .then((fileEntry) => {
            // Open the PDf with the correct OS tools
            this.fileOpener.open(
              this.file.dataDirectory + 'file.pdf',
              'application/pdf'
            );
          });
      });
    } else {
      // On a browser simply use download!
      if (this.date2 != '') {
        this.pdfObj.download(
          this.momentjs(this.date1).format('DD-MM-YYYY') +
            ' au ' +
            this.momentjs(this.date2).format('DD-MM-YYYY')
        );
      } else {
        this.pdfObj.download(this.momentjs(this.date1).format('DD-MM-YYYY'));
      }
    }
  }

  createPDFFromPrescripteur() {
    let dd = {
      pageSize: 'A4',
      watermark: {
        text: 'Focus GCM',
        color: 'grey',
        opacity: 0.05,
        bold: true,
        italics: false,
      },
      content: [],
      styles: {
        f18: {
          fontSize: 16,
          margin: [0, 10, 0, 10],
        },
        strong: {
          bold: true,
        },
        title: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
        content: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
      },
    };

    // Toutes ses consultations
    dd.content.push({
      text: 'Prescripteur : ' + this.username,
      alignment: 'center',
      style: ['f18', 'strong'],
    });

    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });
    dd.content.push({
      columns: [
        { text: 'CONSULTATIONS : ' + this.nbCsD, bold: true, style: 'content' },
      ],
    });
    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });

    for (let i = 0; i < this.consultations.length; i++) {
      dd.content.push({
        text:
          'Patient : ' +
          this.consultations[i].nom +
          ' ' +
          this.consultations[i].prenom,
        alignment: 'left',
        style: ['f16', 'strong'],
      });
      dd.content.push({
        columns: [
          { text: 'Date', bold: true },
          {
            text: this.momentjs(this.consultations[i].dateCS).format(
              'DD MMMM YYYY'
            ),
            width: '80%',
          },
        ],
        style: 'content',
      });
      dd.content.push({
        columns: [
          { text: 'Motif', bold: true },
          { text: this.consultations[i].motif, width: '80%' },
        ],
        style: 'content',
      });

      if (this.consultations[i].hdm !== '') {
        dd.content.push({
          columns: [
            { text: 'HDM', bold: true },
            { text: this.consultations[i].hdm, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].clinique != null) {
        dd.content.push({
          columns: [
            { text: 'Clinique', bold: true },
            { text: this.consultations[i].clinique, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].paraclinique != null) {
        dd.content.push({
          columns: [
            { text: 'Paraclinique', bold: true },
            { text: this.consultations[i].paraclinique, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].CAT !== '') {
        dd.content.push({
          columns: [
            { text: 'Conduite à tenir', bold: true },
            { text: this.consultations[i].CAT, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (
        this.consultations[i].diagnostic !== '' ||
        this.consultations[i].diagnostic !== '-'
      ) {
        dd.content.push({
          columns: [
            { text: 'Diagnostic', bold: true },
            { text: this.consultations[i].diagnostic, width: '80%' },
          ],
          style: 'content',
        });
      }
      dd.content.push({
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 595 - 2 * 40,
            y2: 5,
            lineWidth: 0.5,
          },
        ],
      });
      dd.content.push('  ');
    }

    this.pdfObj = pdfMake.createPdf(dd); //create pdf

    if (this.platform.is('cordova')) {
      this.pdfObj.getBuffer((buffer) => {
        let blob = new Blob([buffer], { type: 'application/pdf' });

        // Save the PDF to the data Directory of our App
        this.file
          .writeFile(this.file.dataDirectory, 'file.pdf', blob, {
            replace: true,
          })
          .then((fileEntry) => {
            // Open the PDf with the correct OS tools
            this.fileOpener.open(
              this.file.dataDirectory + 'file.pdf',
              'application/pdf'
            );
          });
      });
    } else {
      // On a browser simply use download!
      this.pdfObj.download(this.username);
    }
  }

  createPDFFromMois() {
    let dd = {
      pageSize: 'A4',
      watermark: {
        text: 'Focus GCM',
        color: 'grey',
        opacity: 0.05,
        bold: true,
        italics: false,
      },
      content: [],
      styles: {
        f18: {
          fontSize: 16,
          margin: [0, 10, 0, 10],
        },
        strong: {
          bold: true,
        },
        title: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
        content: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
      },
    };

    // Toutes ses consultations
    dd.content.push({
      text: 'Prescripteur : ' + this.username,
      alignment: 'center',
      style: ['f18', 'strong'],
    });

    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });
    dd.content.push({
      columns: [
        {
          text: 'CONSULTATIONS : ' + this.nbCsD + ' - Mois de ' + this.mois,
          bold: true,
          style: 'content',
        },
      ],
    });
    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });

    for (let i = 0; i < this.consultations.length; i++) {
      dd.content.push({
        text:
          'Patient : ' +
          this.consultations[i].nom +
          ' ' +
          this.consultations[i].prenom,
        alignment: 'left',
        style: ['f16', 'strong'],
      });
      dd.content.push({
        columns: [
          { text: 'Date', bold: true },
          {
            text: this.momentjs(this.consultations[i].dateCS).format(
              'DD MMMM YYYY'
            ),
            width: '80%',
          },
        ],
        style: 'content',
      });
      dd.content.push({
        columns: [
          { text: 'Motif', bold: true },
          { text: this.consultations[i].motif, width: '80%' },
        ],
        style: 'content',
      });

      if (this.consultations[i].hdm !== '') {
        dd.content.push({
          columns: [
            { text: 'HDM', bold: true },
            { text: this.consultations[i].hdm, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].clinique !== null) {
        dd.content.push({
          columns: [
            { text: 'Clinique', bold: true },
            { text: this.consultations[i].clinique, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].paraclinique != null) {
        dd.content.push({
          columns: [
            { text: 'Paraclinique', bold: true },
            { text: this.consultations[i].paraclinique, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (this.consultations[i].CAT !== '') {
        dd.content.push({
          columns: [
            { text: 'Conduite à tenir', bold: true },
            { text: this.consultations[i].CAT, width: '80%' },
          ],
          style: 'content',
        });
      }
      if (
        this.consultations[i].diagnostic !== '' ||
        this.consultations[i].diagnostic !== '-'
      ) {
        dd.content.push({
          columns: [
            { text: 'Diagnostic', bold: true },
            { text: this.consultations[i].diagnostic, width: '80%' },
          ],
          style: 'content',
        });
      }
      dd.content.push({
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 595 - 2 * 40,
            y2: 5,
            lineWidth: 0.5,
          },
        ],
      });
      dd.content.push('  ');
    }

    this.pdfObj = pdfMake.createPdf(dd); //create pdf

    if (this.platform.is('cordova')) {
      this.pdfObj.getBuffer((buffer) => {
        let blob = new Blob([buffer], { type: 'application/pdf' });

        // Save the PDF to the data Directory of our App
        this.file
          .writeFile(this.file.dataDirectory, 'file.pdf', blob, {
            replace: true,
          })
          .then((fileEntry) => {
            // Open the PDf with the correct OS tools
            this.fileOpener.open(
              this.file.dataDirectory + 'file.pdf',
              'application/pdf'
            );
          });
      });
    } else {
      // On a browser simply use download!
      this.pdfObj.download(this.username + '-' + this.mois);
    }
  }

  createPDFCS() {
    let dd = {
      pageSize: 'A4',
      watermark: {
        text: 'Focus GCM',
        color: 'grey',
        opacity: 0.1,
        bold: true,
        italics: false,
      },
      content: [],
      styles: {
        f18: {
          fontSize: 16,
          margin: [0, 10, 0, 10],
        },
        strong: {
          bold: true,
        },
        title: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
        content: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
        header: {
          fontSize: 8,
          margin: [0, 5, 0, 0],
          color: 'grey',
        },
      },
    };

    // Consultations
    dd.content.push({
      columns: [{ text: this.patient, bold: true, style: 'f18' }],
      alignement: 'center',
    });

    this.date = this.momentjs(this.consultation.dateCS).format('DD-MM-YYYY');
    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });

    dd.content.push({
      columns: [{ text: 'CONSULTATION', bold: true, style: 'content' }],
    });
    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });

    dd.content.push({
      columns: [
        { text: 'Date', bold: true },
        {
          text: this.momentjs(this.consultation.dateCS).format('DD MMMM YYYY'),
          width: '70%',
        },
      ],
      style: 'content',
    });
    dd.content.push({
      columns: [
        { text: 'Motif', bold: true },
        { text: this.consultation.motif, width: '70%' },
      ],
      style: 'content',
    });

    if (this.consultation.hdm !== '') {
      dd.content.push({
        columns: [
          { text: 'HDM', bold: true },
          { text: this.consultation.hdm, width: '70%' },
        ],
        style: 'content',
      });
    }
    if (this.consultation.clinique !== '') {
      dd.content.push({
        columns: [
          { text: 'Clinique', bold: true },
          { text: this.consultation.clinique, width: '70%' },
        ],
        style: 'content',
      });
    }
    if (this.consultation.paraclinique !== '') {
      dd.content.push({
        columns: [
          { text: 'Paraclinique', bold: true },
          { text: this.consultation.paraclinique, width: '70%' },
        ],
        style: 'content',
      });
    }
    if (this.consultation.CAT !== '') {
      dd.content.push({
        columns: [
          { text: 'Conduite à tenir', bold: true },
          { text: this.consultation.CAT, width: '70%' },
        ],
        style: 'content',
      });
    }
    if (
      this.consultation.diagnostic !== '' ||
      this.consultation.diagnostic !== '-'
    ) {
      dd.content.push({
        columns: [
          { text: 'Diagnostic', bold: true },
          { text: this.consultation.diagnostic, width: '70%' },
        ],
        style: 'content',
      });
    }

    dd.content.push({
      columns: [
        { text: 'Prescripteur', bold: true },
        { text: this.prescripteur, width: '70%' },
      ],
      style: 'content',
    });
    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });

    this.pdfObj = pdfMake.createPdf(dd); //create pdf

    if (this.platform.is('cordova')) {
      this.pdfObj.getBuffer((buffer) => {
        let blob = new Blob([buffer], { type: 'application/pdf' });

        // Save the PDF to the data Directory of our App
        this.file
          .writeFile(this.file.dataDirectory, 'dossierMedical.pdf', blob, {
            replace: true,
          })
          .then((fileEntry) => {
            // Open the PDf with the correct OS tools
            this.fileOpener.open(
              this.file.dataDirectory + 'dossierMedical.pdf',
              'application/pdf'
            );
          });
      });
    } else {
      // On a browser simply use download!
      this.pdfObj.download(this.patient + '-' + this.date);
    }

    this.popCtrl.dismiss(this.patientID);
  }
}
