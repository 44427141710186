import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from './../../../../services/utils.service';

@Component({
  selector: 'app-autre-cas',
  templateUrl: './autre-cas.component.html',
  styleUrls: ['./autre-cas.component.scss'],
})
export class AutreCasComponent implements OnInit {
  newCase;

  constructor(
    private modalCtrl: ModalController,
    private storage: StorageService,
    public utils: UtilsService
  ) {}

  ngOnInit() {}

  async dismiss() {
    this.modalCtrl.dismiss(null, '');
  }

  addCase(newCase) {
    this.storage.setData('newCase', newCase);

    this.modalCtrl.dismiss(null, 'crud');
  }
}
