import { Component, OnInit } from '@angular/core';
import { CaisseService } from 'src/app/services/caisse.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-caisse-sortie',
  templateUrl: './caisse-sortie.component.html',
  styleUrls: ['./caisse-sortie.component.scss'],
})
export class CaisseSortieComponent implements OnInit {
  caisseSorties = [];
  totalDep = 0;
  constructor(public caisseService: CaisseService, public utils: UtilsService) {
    caisseService.loadCS(this.utils.currentDate);
  }

  ngOnInit() {
    this.caisseService._caisseSorties$.subscribe((sorties: any[]) => {
      this.caisseSorties = sorties;
    });

    this.caisseService._totalDepense$.subscribe((totalDep: any) => {
      this.totalDep = totalDep;
    });
  }
}
