import { PatientService } from 'src/app/services/patient.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';

import * as moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');

// PDFMake
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-historique-examen',
  templateUrl: './historique-examen.component.html',
  styleUrls: ['./historique-examen.component.scss'],
})
export class HistoriqueExamenComponent implements OnInit {
  @Input() patient: any;

  isVisible = false;
  chevron = 'chevron-forward-outline';

  momentjs: any = moment;

  // PDFMake
  pdfObj = null;

  constructor(
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    public platform: Platform,
    public utils: UtilsService,
    public patientService: PatientService,
    private storage: StorageService
  ) {
    this.utils.checkPlatform();
    this.storage.getData('p_id').then((p: any) => {
      this.patient = p;
      this.patientService.patient = this.patient;
      this.patientService.IdPatient = this.patient.id;
    });
  }

  ngOnInit() {
    this.patientService.initHistorique();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  collapse() {
    this.isVisible = !this.isVisible;
    if (this.isVisible == true) {
      this.chevron = 'chevron-down-outline';
      this.patientService.initHistorique();
    } else {
      this.chevron = 'chevron-forward-outline';
    }
  }
}
