import { CertificatComponent } from './../components/patients/crud/certificat/certificat.component';
import { LaboCrudComponent } from './../components/patients/crud/labo-crud/labo-crud.component';
import { OrdonnanceCrudComponent } from './../components/patients/crud/ordonnance-crud/ordonnance-crud.component';
import { AddWaitComponent } from './../components/wait/add-wait/add-wait.component';
import { UtilsService } from './utils.service';
import {
  AlertController,
  ModalController,
  ActionSheetController,
  LoadingController,
} from '@ionic/angular';
import { AccessProviders } from './../providers/access-providers.service';
import { Injectable } from '@angular/core';
import { PatientCrudComponent } from '../components/patients/crud/patient-crud/patient-crud.component';
import { Router } from '@angular/router';
import { InfoComponent } from '../components/patients/dossier-medical/info/info.component';
import * as moment from 'moment';
import { DossierComponent } from '../components/patients/dossier-medical/dossier/dossier.component';
import { OptionComponent } from '../components/patients/option/option.component';
import { UrlService } from 'src/app/services/url.service';
import { CameraFileComponent } from '../components/patients/crud/files/camera-file/camera-file.component';
import { ChooseFileComponent } from '../components/patients/crud/files/choose-file/choose-file.component';
import { DetailDocumentComponent } from '../components/patients/crud/files/detail-document/detail-document.component';
import { TextEditorComponent } from '../components/patients/crud/files/text-editor/text-editor.component';
import { ConsultationDetailComponent } from '../components/patients/dossier-medical/consultation-detail/consultation-detail.component';
import { StorageService } from 'src/app/services/storage.service';
import { PatientInfoComponent } from '../components/patients/dossier-medical/patient-info/patient-info.component';
import { ConsultationUpdateComponent } from './../components/patients/crud/consultation-update/consultation-update.component';
import { of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  nbDossierPatient: number;
  IdPatient = null;
  lastId: string;
  lastRec: number;
  patients: any = [];
  filteredData = [];
  lastPatients = [];

  momentjs: any = moment;
  isEdit = false;
  isEmptLast = false;

  patient = {
    noPatient: '',
    nom: '',
    prenom: '',
    dateNaiss: '',
    sexe: '',
    adresse: '',
    contact: '',
    patientDepuis: '',
    creePar: '',
    modifiePar: '',
    dateModif: '',
  };

  patient$: number;
  patientJour: number;

  constructor(
    private accessProviders: AccessProviders,
    private alertCtrl: AlertController,
    private utils: UtilsService,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private router: Router,
    private loadingCtrl: LoadingController,
    private urlService: UrlService,
    private storage: StorageService
  ) {}

  ////////// OBSERVABLES /////////////
  _patients = new BehaviorSubject<any[]>([]);
  _lastPatients = new BehaviorSubject<any[]>([]);
  _nbPatient = new BehaviorSubject<number>(0);
  _patientJour = new BehaviorSubject<number>(0);
  _isLoading = new BehaviorSubject<boolean>(true);
  _isEmpty = new BehaviorSubject<boolean>(false);

  //////////// ASSECEURS ////////////
  get _patients$() {
    return this._patients.asObservable();
  }

  get _lastPatients$() {
    return this._lastPatients.asObservable();
  }

  get _nbPatient$() {
    return this._nbPatient.asObservable();
  }

  get _patientJour$() {
    return this._patientJour.asObservable();
  }

  get _isLoading$() {
    return this._isLoading.asObservable();
  }

  get _isEmpty$() {
    return this._isEmpty.asObservable();
  }
  //////////// ASSECEURS ////////////

  clearInput() {
    this.patient.noPatient = '';
    this.patient.nom = '';
    this.patient.prenom = '';
    this.patient.dateNaiss = '';
    this.patient.sexe = '';
    this.patient.adresse = '';
    this.patient.contact = '';
    this.patient.patientDepuis = '';
    this.patient.creePar = '';
    this.patient.modifiePar = '';
    this.patient.dateModif = '';
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }

  async nbPatient() {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_PATIENT',
      };

      this.accessProviders
        .postData(body, 'patient.php')
        .subscribe((res: any) => {
          this.patient$ = res;
          this._nbPatient.next(this.patient$);
          this._isLoading.next(false);
        });
    });
  }

  async nbPatientParJour() {
    return new Promise((_resolve) => {
      const body = {
        action: 'PATIENT_JOUR',
      };

      this.accessProviders
        .postData(body, 'patient.php')
        .subscribe((res: any) => {
          this.patientJour = res;
          this._patientJour.next(this.patientJour);
        });
    });
  }

  load10Last() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_LAST',
      };

      this.accessProviders
        .postData(body, 'patient.php')
        .subscribe((res: any) => {
          this.lastPatients = [];
          if (res.result == '') {
            this.isEmptLast = true;
            this._isLoading.next(false);
          } else {
            this.isEmptLast = false;
            for (const data of res.result) {
              this.lastPatients.push(data);
              this._lastPatients.next(this.lastPatients);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }
  loadSinglePatient() {
    return new Promise((_resolve) => {
      const body = {
        action: 'GET_SINGLE_PATIENT',
        id: this.IdPatient,
      };

      this.accessProviders
        .postData(body, 'patient.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isEmpty.next(true);
            this._isLoading.next(false);
          } else {
            this._isEmpty.next(false);
            for (const data of res.result) {
              this.patient.noPatient = data.noPatient;
              this.patient.nom = data.nom;
              this.patient.prenom = data.prenom;
              this.patient.dateNaiss = this.momentjs(data.dateNaiss).format(
                'yyyy-MM-DD'
              );
              this.patient.sexe = data.sexe;
              this.patient.adresse = data.adresse;
              this.patient.contact = data.contact;
              this.patient.patientDepuis = this.momentjs(
                data.patientDepuis
              ).format('yyyy-MM-DD');
              this.patient.creePar = data.creePar;
              this.patient.modifiePar = data.modifiePar;
              this.patient.dateModif = data.dateModif;

              this._isLoading.next(false);
            }
          }
        });
    });
  }

  getLastId() {
    return new Promise((_resolve) => {
      const body = {
        action: 'GET_LAST_ID',
      };

      this.accessProviders
        .postData(body, 'patient.php')
        .subscribe((res: any) => {
          for (const data of res.result) {
            // this.lastRec = parseInt(data.noPatient.substring(1, 10)) + 1;
            this.lastRec = parseInt(data.noPatient.substring(1, 10));
            this.lastId = data.noPatient;
          }
        });
    });
  }

  async crudAction(a: any) {
    // if (this.patient.noPatient === '') {
    //   this.utils.toastError('N° Patient requis');
    // } else if (this.patient.nom === '') {
    //   this.utils.toastError('Nom requis');
    // } else if (this.patient.sexe === '') {
    //   this.utils.toastError('Sexe requis');
    // } else if (this.patient.adresse === '') {
    //   this.utils.toastError('Adresse requis');
    // } else if (this.patient.patientDepuis === '') {
    //   this.utils.toastError('Date de 1ere consultation requise');
    // } else {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      const body = {
        action: 'CRUD',
        id: this.IdPatient,
        noPatient: this.patient.noPatient,
        nom: this.patient.nom,
        prenom: this.patient.prenom,
        dateNaiss:
          this.patient.dateNaiss != null
            ? this.utils.dateFormat(this.patient.dateNaiss)
            : '',
        sexe: this.patient.sexe,
        adresse: this.patient.adresse,
        contact: this.patient.contact,
        patientDepuis: this.utils.dateFormat(this.patient.patientDepuis),
        creePar: this.utils.name,
        modifiePar: this.utils.name,
        crudAct: a,
      };

      this.accessProviders.postData(body, 'patient.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            this.loadPatients();
            loader.dismiss();
            this.utils.toast(
              res.msg + ' ... mise à jour de données en cours ...'
            );
            this.modalCtrl.dismiss(null, 'crud');
          } else {
            loader.dismiss();
            this.utils.toastError(res.msg);
          }
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
    // }
  }

  refreshData() {
    this.filteredData = [];
    this.patients = [];
    this.loadPatients();
  }

  async openDetailPatient(patient) {
    const modal = await this.modalCtrl.create({
      component: PatientInfoComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        patient: patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win5-modal-web' : 'win5-modal-mobile',
    });

    await modal.present();
  }

  async openDetail(patient) {
    const modal = await this.modalCtrl.create({
      component: DossierComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win4-modal-web' : 'win4-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.refreshData();
    }
  }

  async openDetails(patient) {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: InfoComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'info-modal-web' : 'info-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.refreshData();
    }
  }

  async openWait(patient) {
    const modal = await this.modalCtrl.create({
      component: AddWaitComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win7-modal-web' : 'win7-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.refreshData();
    }
  }

  // async openDM(patient) {
  //   this.modalCtrl.dismiss();
  //   const navigationExtras: NavigationExtras = {
  //     state: { patient },
  //   };
  //   this.router.navigateByUrl('/patients/' + patient.id, navigationExtras);
  // }

  dossierM(patient) {
    this.storage.setData('p_id', patient);
    this.storage.setData('pt', true);
    this.router.navigateByUrl('/dossier-medical');
  }

  async openConsult(patient) {
    this.dismiss();
    const modal = await this.modalCtrl.create({
      component: ConsultationUpdateComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        consultation_id: 0,
        patient: patient,
        isControle: false,
        isUpdate: false,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'cs-modal-web' : 'cs-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }

  async openCTR(patient) {
    this.dismiss();
    this.nbConsultationPatient(patient.id);
    if (this.nbDossierPatient != 0) {
      const modal = await this.modalCtrl.create({
        component: ConsultationUpdateComponent,
        animated: true,
        mode: 'ios',
        componentProps: {
          consultation_id: 0,
          patient: patient,
          isControle: true,
          isUpdate: false,
        },
        backdropDismiss: false,
        cssClass: this.utils.isDesktop ? 'cs-modal-web' : 'cs-modal-mobile',
      });
      await modal.present();

      const data = await modal.onWillDismiss();

      if (data.role == 'crud') {
        this.refreshData();
      }
    } else {
      this.utils.toastError(
        "Le patient n'a pas encore un dossier.\nVous ne pouvez pas effectuer cette opération"
      );
    }
  }

  async openOrdo(patient) {
    this.dismiss();
    const modal = await this.modalCtrl.create({
      component: OrdonnanceCrudComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        patient: patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'cs-modal-web' : 'cs-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }

  async openCrud(id) {
    this.clearInput();
    const modal = await this.modalCtrl.create({
      component: PatientCrudComponent,
      animated: true,
      mode: 'ios',
      componentProps: { id },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win5-modal-web' : 'win5-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }

  async openLabo(patient) {
    this.dismiss();
    const modal = await this.modalCtrl.create({
      component: LaboCrudComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        patient: patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'cs-modal-web' : 'cs-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }

  async openCertificat(patient) {
    this.dismiss();
    const modal = await this.modalCtrl.create({
      component: CertificatComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        patient: patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'cs-modal-web' : 'cs-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }

  async openOption(patient) {
    const modal = await this.modalCtrl.create({
      component: OptionComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop
        ? 'option-modal-web'
        : 'option-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.refreshData();
    }
  }

  async openMenu(patient) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: patient.nom + ' ' + patient.prenom,
      cssClass: 'custom-actionSheet',
      buttons: [
        {
          text: 'Mettre en Attente',
          icon: 'alarm',
          handler: () => {
            this.openWait(patient);
          },
        },
        {
          text: 'Detail',
          icon: 'list',
          handler: () => {
            this.openDetail(patient);
          },
        },
        {
          text: 'Modifier',
          icon: 'create',
          handler: () => {
            this.openCrud(patient.id);
          },
        },
        {
          text: 'Dossier Médical',
          icon: 'medkit',
          handler: () => {
            this.dossierM(patient);
          },
        },
        {
          text: 'Supprimer',
          icon: 'trash',
          handler: () => {
            this.delPatient(patient.id);
          },
        },
      ],
    });
    await actionSheet.present();

    const { role, data } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role and data', role, data);
  }

  async loadPatients() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL',
      };

      this.accessProviders
        .postData(body, 'patient.php')
        .subscribe((res: any) => {
          this.patients = [];
          if (res.result == '') {
            this._isEmpty.next(true);
            this._isLoading.next(false);
          } else {
            this._isEmpty.next(false);
            for (const data of res.result) {
              this.patients.push(data);
              this.filteredData = this.patients;
              this._patients.next(this.patients);
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  async nbConsultationPatient(id) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_DOSSIER',
        patient_id: id,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbDossierPatient = res;
          if (this.nbDossierPatient == 0) {
            this._isLoading.next(false);
          }
        });
    });
  }

  filterList(evt) {
    this._patients.next(this.filteredData);
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._patients.next(
      this.filteredData.filter(
        (item: {
          noPatient: string;
          nom: string;
          prenom: string;
          sexe: string;
          adresse: string;
        }) => {
          return (
            item.noPatient.toLowerCase().indexOf(searchTerm.toLowerCase()) >
              -1 ||
            item.nom.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            item.prenom.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            item.sexe.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            item.adresse.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
          );
        }
      )
    );
  }

  delPatient(id) {
    return new Promise(async () => {
      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        message:
          'Voulez-vous vraiment supprimer ce patient et de toutes ses dossiers ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              // Suppression de ses consultations
              return new Promise(async () => {
                const bodyConsult = {
                  action: 'DELETE_FOR_PATIENT',
                  patient_id: id,
                };

                this.accessProviders
                  .postData(bodyConsult, 'consultation.php')
                  .subscribe(() => {
                    // Suppression de ses fichiers
                    return new Promise(async () => {
                      const bodyFichier = {
                        action: 'DELETE_FOR_PATIENT',
                        patient_id: id,
                      };

                      this.accessProviders
                        .postData(bodyFichier, 'fichier.php')
                        .subscribe(() => {
                          // Suppression de ses antécedents
                          return new Promise(async () => {
                            const body = {
                              action: 'DELETE_FOR_PATIENT',
                              patient_id: id,
                            };
                            this.accessProviders
                              .postData(body, 'atcd.php')
                              .subscribe(() => {
                                return new Promise(async () => {
                                  const body = {
                                    action: 'DELETE',
                                    patient_id: id,
                                  };
                                  this.accessProviders
                                    .postData(body, 'patient.php')
                                    .subscribe((res: any) => {
                                      if (res.success === true) {
                                        this.utils.toast(
                                          'Suppression éffectuée'
                                        );
                                        this.loadPatients();
                                      } else {
                                        this.utils.toastError(
                                          'Erreur de suppression'
                                        );
                                      }
                                    });
                                });
                              });
                          });
                        });
                    });
                  });
              });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });
      await alert.present();
    });
  }

  //////////// FILE //////////////
  nbFilePatient: number;
  nbFilePat: string;
  urlFile = `${this.urlService.server}uploadFile/`;
  fichiers: any = [];

  initFile() {
    this.fichiers = [];
    this.nbFilePatient = 0;
    this.loadFichiers();
    this.nbFicPatient();
  }

  async nbFicPatient() {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_FILE',
        patient_id: this.IdPatient,
      };

      this.accessProviders
        .postData(body, 'fichier.php')
        .subscribe((res: any) => {
          this.nbFilePatient = res;
          this.nbFilePat = this.nbFilePatient + ' fichier(s)';
          if (this.nbFilePatient == 0) {
            this._isLoading.next(false);
          }
        });
    });
  }

  async loadFichiers() {
    return new Promise((resolve) => {
      let body = {
        action: 'GET_FILE',
        patient_id: this.IdPatient,
      };

      this.accessProviders
        .postData(body, 'fichier.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.fichiers.push(data);
            this._isLoading.next(false);
          }
          resolve(true);
        });
    });
  }

  async openNote() {
    const modal = await this.modalCtrl.create({
      component: TextEditorComponent,
      animated: true,
      mode: 'ios',
      componentProps: { patient: this.patient },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.initFile();
    }
  }

  async openCamera() {
    const modal = await this.modalCtrl.create({
      component: CameraFileComponent,
      animated: true,
      mode: 'ios',
      componentProps: { patient: this.patient },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.initFile();
    }
  }

  async openFile() {
    const modal = await this.modalCtrl.create({
      component: ChooseFileComponent,
      animated: true,
      mode: 'ios',
      componentProps: { patient: this.patient },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win2-modal-web' : 'win2-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.initFile();
    }
  }

  async openFileOption() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Fichiers',
      cssClass: 'custom-actionSheet',
      buttons: [
        {
          text: 'Note',
          icon: 'text-outline',
          handler: () => {
            this.openNote();
          },
        },
        {
          text: 'Photos',
          icon: 'camera-outline',
          handler: () => {
            this.openCamera();
          },
        },
        {
          text: 'Fichier PDF',
          icon: 'document-outline',
          handler: () => {
            this.openFile();
          },
        },
        {
          text: 'Annuler',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }

  async openFileDetail(id) {
    const modal = await this.modalCtrl.create({
      component: DetailDocumentComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        id,
        data: this.fichiers,
        patient: this.patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win4-modal-web' : 'win4-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.initFile();
    }
  }
  //////////// FILE //////////////

  //////////// HISTORIQUE DES EXAMENS //////////////
  // Accordeon
  automaticClose = false;
  mois: any = [];
  annees: any = [];

  dossiers: any = [];
  consultations: any;
  nbCsDossier: number;
  nbCsD: string;
  nbCS: string;
  nbDossierP: number;

  nbconsultationDossier: number;
  nbCS_Dossier: string;
  noDossier: any;
  nbCS_Annee: any;

  initHistorique() {
    this.automaticClose = true;

    this.nbConsultationP();

    this.annees = [];
    this.mois = [];
    this.loadMois();
    this.loadAnnee();

    this.dossiers = [];
    this.consultations = [];
  }

  toggleAnnee(index) {
    this.annees[index].open = !this.annees[index].open;

    if (this.automaticClose && this.annees[index].open) {
      this.annees
        .filter((item, itemIndex) => itemIndex != index)
        .map((item) => (item.open = false));
    }
  }

  toggleMois(index) {
    this.mois[index].open = !this.mois[index].open;

    if (this.automaticClose && this.mois[index].open) {
      this.mois
        .filter((item, itemIndex) => itemIndex != index)
        .map((item) => (item.open = false));
    }
  }

  toggleDossiers(index) {
    this.dossiers[index].open = !this.dossiers[index].open;

    if (this.automaticClose && this.dossiers[index].open) {
      this.dossiers
        .filter((item, itemIndex) => itemIndex != index)
        .map((item) => (item.open = false));
    }
  }

  getConsult(dossier) {
    this.noDossier = dossier;
    this.nbConsultationDossier(dossier);

    this.consultations = [];
    return new Promise((resolve) => {
      let body = {
        action: 'GET_CONSULT',
        dossier_id: dossier,
        patient_id: this.IdPatient,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.consultations.push(data);
            this._isLoading.next(false);
          }
          resolve(true);
        });
    });
  }

  async getMois(date) {
    this.mois = [];
    return new Promise((resolve) => {
      let body = {
        action: 'GET_MOIS',
        dateCS: this.momentjs(date).format('YYYY'),
        patient_id: this.IdPatient,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.mois.push(data);
          }
          resolve(true);
        });
    });
  }

  async loadMois() {
    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_MOIS',
        patient_id: this.IdPatient,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.mois.push(data);
          }
          resolve(true);
        });
    });
  }

  async loadAnnee() {
    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_ANNEE',
        patient_id: this.IdPatient,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.annees.push(data);
            this._isLoading.next(false);
          }
          resolve(true);
        });
    });
  }

  async nbConsultationP() {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_DOSSIER',
        patient_id: this.IdPatient,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbDossierP = res;
          this.nbCS = this.nbDossierP + ' dossier(s)';

          if (this.nbDossierP == 0) {
            this._isLoading.next(false);
          }
        });
    });
  }

  async nbConsultationDossier(dossier_id) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULTATION_DOSSIER',
        patient_id: this.IdPatient,
        dossier_id: dossier_id,
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          this.nbCsDossier = res;
          this.nbCsD = this.nbCsDossier + ' consultation(s)';
        });
    });
  }

  async loadDossier(dateCS) {
    this.dossiers = [];
    return new Promise((resolve) => {
      let body = {
        action: 'LOAD_CONSULT_BY_ID',
        patient_id: this.IdPatient,
        dateCS: this.momentjs(dateCS).format('YYYYMM'),
      };

      this.accessProviders
        .postData(body, 'consultation.php')
        .subscribe((res: any) => {
          for (let data of res.result) {
            this.dossiers.push(data);
          }
          resolve(true);
        });
    });
  }

  async openDetailCS(consult) {
    const modal = await this.modalCtrl.create({
      component: ConsultationDetailComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        consult: consult,
        patient: this.patient,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win4-modal-web' : 'win4-modal-mobile',
    });

    return await modal.present();
  }
  //////////// HISTORIQUE DES EXAMENS //////////////
}
