import { Component, OnInit } from '@angular/core';
import { InfoService } from './../../../services/info.service';

@Component({
  selector: 'app-id-fs',
  templateUrl: './id-fs.component.html',
  styleUrls: ['./id-fs.component.scss'],
})
export class IdFsComponent implements OnInit {
  constructor(public infoService: InfoService) {}

  ngOnInit() {
    this.infoService.loadInfoFS();
  }
}
