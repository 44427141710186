import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { RMAService } from 'src/app/services/rma.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-search-diag',
  templateUrl: './search-diag.component.html',
  styleUrls: ['./search-diag.component.scss'],
})
export class SearchDiagComponent implements OnInit {
  segmentModel = 'nc';
  sexeP;
  constructor(
    public utils: UtilsService,
    private modalCtrl: ModalController,
    public rmaService: RMAService,
    private navParams: NavParams
  ) {
    this.rmaService.getDiagNC();
    this.utils.checkPlatform();
    this.sexeP = this.navParams.get('sexe');
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  segmentChanged(e) {}
}
