import { SearchPatientComponent } from '../../patients/search-patient/search-patient.component';
import { CaisseService } from 'src/app/services/caisse.service';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { UtilsService } from 'src/app/services/utils.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-encaissement',
  templateUrl: './encaissement.component.html',
  styleUrls: ['./encaissement.component.scss'],
})
export class EncaissementComponent implements OnInit {
  date: string;
  p_id: string;
  p;
  choix;
  isVisible = false;

  patient = {
    nom: '',
  };

  montant: number = 0;
  remise: number = 0;
  remiseT: number = 0;
  total: number = 0;
  paye: number = 0;
  reste: number = 0;
  aRendre: number = 0;
  momentjs: any = moment;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public caisseService: CaisseService,
    public utils: UtilsService,
    private storage: StorageService,
    private alertCtrl: AlertController
  ) {
    this.total = this.navParams.get('total');
    this.montant = this.total;
    this.reste = this.montant;
  }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

  addRemise() {
    this.isVisible = true;
    this.remiseT = (this.total * this.remise) / 100;
    this.montant = this.total - this.remiseT;
    this.caisseService.totalMedoc =
      this.caisseService.totalMedoc - this.remiseT;
    this.reste = this.montant;
  }

  paiment() {
    if (this.montant > this.paye) {
      this.reste = this.montant - this.paye;
    } else {
      this.reste = 0;

      this.aRendre = this.paye - this.montant;
    }
  }

  async addCaisse(date, pID) {
    if (this.paye == 0) {
      const alert = await this.alertCtrl.create({
        header: 'Focus GCM',
        message: "Le client n'a pas payé. Voulez-vous ajouter au créance ?",
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'Oui',
            handler: () => {
              this.caisse(date, pID);
            },
          },
          {
            text: 'Non',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });
      await alert.present();
    } else {
      this.caisse(date, pID);
    }
  }

  caisse(date, pID) {
    this.dismiss();
    this.montant = this.total - this.remiseT;

    this.caisseService.addCaisseHistorique(
      date,
      pID,
      this.paye,
      this.reste,
      this.aRendre,
      this.montant
    );

    this.caisseService.updateCaisseDetail(date, pID);

    if (this.reste > 0 && this.paye == 0) {
      this.caisseService.addCaisseCreance(
        date,
        pID,
        this.montant,
        this.paye,
        this.reste
      );
      this.caisseService.addCaisseJour(date, this.reste);
      this.caisseService.clear();
    } else if (this.reste > 0 && this.paye > 0) {
      this.caisseService.addCaisseCreance(
        date,
        pID,
        this.montant,
        this.paye,
        this.reste
      );

      this.caisseService.addCaisseJour(date, this.reste);
      // this.caisseEntree(date, pID, this.paye);
      this.caisseService.addCaisse(
        date,
        pID,
        '',
        this.paye,
        this.remiseT,
        this.total
      );

      this.caisseService.clear();
    } else {
      this.caisseService.addCaisseJour(date, 0);
      // this.caisseEntree(date, pID, this.montant);
      this.caisseService.addCaisse(
        date,
        pID,
        '',
        this.montant,
        this.remiseT,
        this.total
      );

      this.caisseService.clear();
    }
  }

  caisseEntree(date, pID, montant) {
    if (
      this.caisseService.totalCS != 0 &&
      this.caisseService.totalActe == 0 &&
      this.caisseService.totalMedoc == 0 &&
      this.caisseService.totalAutre == 0
    ) {
      this.caisseService.addCaisse(
        date,
        pID,
        'Consultation',
        montant,
        this.remiseT,
        this.total
      );
    } else if (
      this.caisseService.totalCS != 0 &&
      this.caisseService.totalActe != 0 &&
      this.caisseService.totalMedoc == 0 &&
      this.caisseService.totalAutre == 0
    ) {
      this.caisseService.addCaisse(
        date,
        pID,
        'Consultation + Acte',
        montant,
        this.remiseT,
        this.total
      );
    } else if (
      this.caisseService.totalCS != 0 &&
      this.caisseService.totalActe != 0 &&
      this.caisseService.totalMedoc != 0 &&
      this.caisseService.totalAutre == 0
    ) {
      this.caisseService.addCaisse(
        date,
        pID,
        'Consultation + Acte + Medoc',
        montant,
        this.remiseT,
        this.total
      );
    } else if (
      this.caisseService.totalCS == 0 &&
      this.caisseService.totalActe != 0 &&
      this.caisseService.totalMedoc == 0 &&
      this.caisseService.totalAutre == 0
    ) {
      this.caisseService.addCaisse(
        date,
        pID,
        'Acte',
        montant,
        this.remiseT,
        this.total
      );
    } else if (
      this.caisseService.totalCS != 0 &&
      this.caisseService.totalActe == 0 &&
      this.caisseService.totalMedoc != 0 &&
      this.caisseService.totalAutre == 0
    ) {
      this.caisseService.addCaisse(
        date,
        pID,
        'Consultation + Medicaments',
        montant,
        this.remiseT,
        this.total
      );
    } else if (
      this.caisseService.totalCS == 0 &&
      this.caisseService.totalActe != 0 &&
      this.caisseService.totalMedoc != 0 &&
      this.caisseService.totalAutre == 0
    ) {
      this.caisseService.addCaisse(
        date,
        pID,
        'Acte + Medicaments',
        montant,
        this.remiseT,
        this.total
      );
    } else if (
      this.caisseService.totalCS == 0 &&
      this.caisseService.totalActe == 0 &&
      this.caisseService.totalMedoc != 0 &&
      this.caisseService.totalAutre == 0
    ) {
      this.caisseService.addCaisse(
        date,
        pID,
        'Medicaments',
        montant,
        this.remiseT,
        this.total
      );
    } else if (
      this.caisseService.totalCS == 0 &&
      this.caisseService.totalActe == 0 &&
      this.caisseService.totalMedoc == 0 &&
      this.caisseService.totalAutre != 0
    ) {
      this.caisseService.addCaisse(
        date,
        pID,
        '',
        montant,
        this.remiseT,
        this.total
      );
    }
  }

  onItemChange(event) {
    if (event.target.value == 1) {
      this.p_id = 'CL' + this.momentjs(new Date()).format('DDMMHHSS');
    } else {
      this.p_id = '';
      this.openSearch();
    }
  }

  async openSearch() {
    const modal = await this.modalCtrl.create({
      component: SearchPatientComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop
        ? 'search-modal-web'
        : 'search-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.storage.getData('p_id').then((p: any) => {
        this.p_id = p.nom + ' ' + p.prenom;
        this.p = p.id;
      });
    }
  }
}
