import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-surv-maladies',
  templateUrl: './surv-maladies.component.html',
  styleUrls: ['./surv-maladies.component.scss'],
})
export class SurvMaladiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
