import { UrlService } from './../../../../../services/url.service';
import { environment } from 'src/environments/environment';
import { ImageViewerComponent } from './../image-viewer/image-viewer.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
import {
  InAppBrowser,
  InAppBrowserOptions,
} from '@ionic-native/in-app-browser/ngx';
import { ModalController, AlertController, NavParams } from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';
import { PatientService } from './../../../../../services/patient.service';
const { store } = Plugins;
const FILE_KEY = 'files';

@Component({
  selector: 'app-detail-document',
  templateUrl: './detail-document.component.html',
  styleUrls: ['./detail-document.component.scss'],
})
export class DetailDocumentComponent implements OnInit {
  patient: any;
  urlFile = `${this.urlService.server}uploadFile/`;
  passedId = null;
  fichiers = null;
  filename: string;

  myFiles = [];
  downloadProgress = 0;

  momentjs: any = moment;

  sliderOpts = {
    zoom: false,
  };

  constructor(
    private modalCtrl: ModalController,
    private inAppBrowser: InAppBrowser,
    public utils: UtilsService,
    private alertCtrl: AlertController,
    public accessProviders: AccessProviders,
    private navParams: NavParams,
    private urlService: UrlService,
    private patientService: PatientService
  ) {
    this.passedId = this.navParams.get('id'); // ID Fichier
    this.fichiers = this.navParams.get('data');
    this.patient = this.navParams.get('patient');
  }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

  formatDate(date: any) {
    return this.momentjs(date).format('DD/MM/YYYY');
  }

  toastAffiche() {
    this.utils.toastError(
      "Vous ne pouvez pas effectuer cette opération car vous n'êtes pas le prescripteur"
    );
  }

  async openPreview(img) {
    const modal = await this.modalCtrl.create({
      component: ImageViewerComponent,
      cssClass: 'transparent-modal',
      componentProps: {
        img: img,
      },
    });
    modal.present();
  }

  async loadFiles() {
    const videoList = await store.get({ key: FILE_KEY });
    this.myFiles = JSON.parse(videoList.value) || [];
  }

  public options: InAppBrowserOptions = {
    location: 'yes', //Or 'no'
    hidden: 'no', //Or  'yes'
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'yes', //Android only ,shows browser zoom controls
    hardwareback: 'yes',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no', //Android only
    closebuttoncaption: 'Close', //iOS only
    disallowoverscroll: 'no', //iOS only
    toolbar: 'no', //iOS only
    enableViewportScale: 'no', //iOS only
    allowInlineMediaPlayback: 'no', //iOS only
    presentationstyle: 'pagesheet', //iOS only
    fullscreen: 'yes', //Windows only
  };

  openFile(file) {
    if (file.split('.').pop() === 'pdf') {
      const data = this.urlFile + file;

      this.inAppBrowser.create(data, '_system', this.options);
    }
  }

  async delFichier(file) {
    const alert = await this.alertCtrl.create({
      header: 'Suppression',
      message: 'Voulez-vous vraiment supprimer?',
      backdropDismiss: false,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            return new Promise(async () => {
              const body = {
                action: 'DELETE',
                id: file.id,
                nomFichier: file.nomFichier,
              };

              this.accessProviders
                .postData(body, 'fichier.php')
                .subscribe((res: any) => {
                  if (res.success === true) {
                    this.utils.toast('Suppression éffectuée');
                    this.patientService.initFile();
                    this.dismiss();
                  } else {
                    this.utils.toastError('Erreur de suppression');
                  }
                });
            });
          },
        },
        {
          text: 'Annuler',
          role: 'cancel',
          handler: (_blah) => {},
        },
      ],
    });

    await alert.present();
  }
}
