import { PrestationService } from './../../../services/prestation.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { Router } from '@angular/router';
import { CaisseService } from 'src/app/services/caisse.service';

@Component({
  selector: 'app-table-acte',
  templateUrl: './table-acte.component.html',
  styleUrls: ['./table-acte.component.scss'],
})
export class TableActeComponent implements OnInit {
  item = {
    title: 'Pas de résultat trouvé',
  };

  searchInput;
  actes = [];
  isLoading: boolean;
  isActEmpty: boolean;

  constructor(
    public accessProviders: AccessProviders,
    public utils: UtilsService,
    public router: Router,
    public caisseService: CaisseService,
    public prestationService: PrestationService
  ) {
    this.prestationService.loadActes();

    this.utils.checkPlatform();
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();

    this.prestationService._actes$.subscribe((actes: any[]) => {
      this.actes = actes;
    });

    this.prestationService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });

    this.prestationService._isActEmpty$.subscribe((isActEmpty: any) => {
      this.isActEmpty = isActEmpty;
    });
  }

  async ionViewDidEnter() {
    this.refreshData();
  }

  refreshData() {
    this.prestationService.loadActes();
    this.caisseService.loadPOS();
  }
  search(ev) {
    this.searchInput = ev.target.value;
  }

  addArticle(type, cs, int) {
    this.caisseService.addArticle(type, cs, int);
    this.searchInput = '';
  }
}
