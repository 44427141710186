import { AccessProviders } from '../providers/access-providers.service';
import {
  AlertController,
  ModalController,
  NavParams,
  LoadingController,
} from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { ElementRef, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UploadService } from './upload.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FilesService {
  passedId = null;
  patient: any;

  date: string = '';
  libelle = '';
  commentaires = '';
  nomFichier = '';
  uploadResponse;

  base64Image: string;
  blob: any;
  filePath: any;

  reste: number;

  filePickerRef: ElementRef<HTMLInputElement>;
  photo: SafeResourceUrl;

  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  form: FormGroup;
  fileName: string;

  constructor(
    private formBuilder: FormBuilder,
    public utils: UtilsService,
    private alertCtrl: AlertController,
    private accessProviders: AccessProviders,
    private uploadService: UploadService,
    private imageCompress: NgxImageCompressService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController
  ) {}

  getMax() {
    this.reste = 250 - this.commentaires.length;

    if (this.commentaires.length > 250) {
      this.presentAlertCaract('Caractères max atteints !');
    }
  }

  async presentAlertCaract(a) {
    const alert = await this.alertCtrl.create({
      header: a,
      backdropDismiss: false,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async getPicture(type: string) {
    if (
      !Capacitor.isPluginAvailable('Camera') ||
      (this.utils.isDesktop && type === 'gallery')
    ) {
      const image = await Camera.getPhoto({
        quality: 100,
        source: CameraSource.Photos,
        allowEditing: false,
        resultType: CameraResultType.Base64,
      });

      this.photo = 'data:image/jpeg;base64,' + image.base64String;
      const base64 = `${this.photo}`;

      let orientation = -1;
      this.imageCompress
        .compressFile(base64, orientation, 90, 90)
        .then(async (result) => {
          this.imgResultAfterCompress = result;

          const base64 = await fetch(`${this.imgResultAfterCompress}`);
          const arr = await base64.blob();
          this.blob = new Blob([arr], { type: 'image/jpeg' });
        });
    } else {
      const image = await Camera.getPhoto({
        quality: 100,
        width: 400,
        allowEditing: false,
        resultType: CameraResultType.Base64,
      });

      this.photo = 'data:image/jpeg;base64,' + image.base64String;
      const base64 = `${this.photo}`;

      let orientation = -1;
      this.imageCompress
        .compressFile(base64, orientation, 90, 90)
        .then(async (result) => {
          this.imgResultAfterCompress = result;

          const base64 = await fetch(`${this.imgResultAfterCompress}`);
          const arr = await base64.blob();
          this.blob = new Blob([arr], { type: 'image/jpeg' });
        });
    }
  }

  onFileSelect(event) {
    const file = (event.target as HTMLInputElement).files[0];
    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file.type.match(pattern)) {
      console.log('File format not supported');
      this.utils.toastError('Fichier non supporté');

      return;
    }

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('filename').setValue(file);
      this.fileName = file.name;
    }

    reader.onload = () => {
      this.photo = reader.result.toString();
    };
    reader.readAsDataURL(file);
  }

  onFileChoose(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file.type.match(pattern)) {
      console.log('File format not supported');
      this.utils.toastError('Fichier non supporté');

      return;
    }

    reader.onload = () => {
      this.photo = reader.result.toString();
    };
    reader.readAsDataURL(file);
  }

  async uploadPhoto() {
    if (this.date === '') {
      this.utils.toastError('Date obligatoire');
    } else if (this.libelle === '') {
      this.utils.toastError('Titre obligatoire');
    } else {
      // Upload file
      const formData = new FormData();
      formData.append('id', this.passedId);
      formData.append('filename', this.blob);

      this.uploadService.uploadImage(formData).subscribe(
        (res) => {
          this.uploadResponse = res.status;
          this.nomFichier = res.filename;

          // save image
          this.saveImage();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  async saveImage() {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise((_resolve) => {
      const body = {
        action: 'FILE',
        patient_id: this.passedId,
        date: this.utils.dateFormat(this.date),
        libelle: this.libelle,
        nomFichier: this.nomFichier,
        commentaires: this.commentaires,
        prescripteur_id: this.utils.userId,
      };

      this.accessProviders.postData(body, 'fichier.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            loader.dismiss();
            this.utils.toast(
              res.msg + ' ... mise à jour de données en cours ...'
            );
            this.modalCtrl.dismiss(null, 'crud');
          } else {
            loader.dismiss();
            this.utils.presentAlert('Focus GCM', res.msg);
          }
        },
        (_err) => {
          loader.dismiss();
          // this.utils.presentAlert('Timeout');
        }
      );
    });
  }

  async saveFile() {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise((_resolve) => {
      const body = {
        action: 'FILE',
        patient_id: this.passedId,
        date: this.utils.dateFormat(this.date),
        libelle: this.libelle,
        nomFichier: this.nomFichier,
        commentaires: this.commentaires,
        prescripteur_id: this.utils.userId,
      };

      this.accessProviders.postData(body, 'fichier.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            loader.dismiss();
            this.utils.toast(res.msg);
          } else {
            loader.dismiss();
            this.utils.toast(res.msg);
          }
        },
        (_err) => {
          loader.dismiss();
          // this.utils.presentAlert('Timeout');
        }
      );
    });
  }
}
