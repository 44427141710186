import { Platform } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';

// PDFMake
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { BehaviorSubject } from 'rxjs';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class RMAService {
  // PDFMake
  pdfObj = null;
  rma = {
    date: '',
  };

  diagnostics = [];
  filteredData = [];
  isLoading = true;
  momentjs: any = moment;

  // Nouveau cas
  rmas = [];
  rmaA = []; // Maladies prioritaires
  rmaB = []; // Maladies a risque epidemique
  rmaC = []; // Maladies febriles
  rmaD = []; // Autres nouveaux cas
  rmaCPN = []; // CPN
  totalNC = 0;
  totalAC = 0;
  diagAC;
  totalCPN = 0;

  // Maladies chroniques
  rmaMC = [];

  // Cas de traumatisme
  rmaTR = [];
  totalTR = 0;

  // Controle
  rmaCTR = [];
  totalCTR = 0;

  // PCIME
  rmaPCIME = [];
  totalD = 0;
  totalSRO = 0;
  totalP = 0;
  totalAmox = 0;
  totalConseil = 0;

  // Cas ecoulement genital
  rmaGEN = [];
  totalGEN = 0;

  // Palu
  rmaPalu = [];
  rmaPalu1 = [];
  rmaPalu2 = [];
  rmaPalu3 = [];
  rmaPalu4 = [];
  rmaPalu5 = [];

  // Fievre
  totalF = 0;
  totalF1 = 0;
  totalF2 = 0;
  totalF3 = 0;
  totalF4 = 0;
  totalF5 = 0;

  // RDT
  totalRDT = 0;
  totalRDT1 = 0;
  totalRDT2 = 0;
  totalRDT3 = 0;
  totalRDT4 = 0;
  totalRDT5 = 0;

  // Micro
  totalMicro = 0;
  totalMicro1 = 0;
  totalMicro2 = 0;
  totalMicro3 = 0;
  totalMicro4 = 0;
  totalMicro5 = 0;

  // 101
  total101 = 0;
  total101_1 = 0;
  total101_2 = 0;
  total101_3 = 0;
  total101_4 = 0;
  total101_5 = 0;

  // 110
  total110 = 0;
  total110_1 = 0;
  total110_2 = 0;
  total110_3 = 0;
  total110_4 = 0;
  total110_5 = 0;

  // 111
  total111 = 0;
  total111_1 = 0;
  total111_2 = 0;
  total111_3 = 0;
  total111_4 = 0;
  total111_5 = 0;

  // Micro_p
  totalMicro_p = 0;
  totalMicro_p1 = 0;
  totalMicro_p2 = 0;
  totalMicro_p3 = 0;
  totalMicro_p4 = 0;
  totalMicro_p5 = 0;

  // M
  totalM = 0;
  totalM1 = 0;
  totalM2 = 0;
  totalM3 = 0;
  totalM4 = 0;
  totalM5 = 0;

  // F
  totalFm = 0;
  totalFm1 = 0;
  totalFm2 = 0;
  totalFm3 = 0;
  totalFm4 = 0;
  totalFm5 = 0;

  // ACT
  totalACT = 0;
  totalACT1 = 0;
  totalACT2 = 0;
  totalACT3 = 0;
  totalACT4 = 0;
  totalACT5 = 0;

  // Prima
  totalPrima = 0;
  totalPrima1 = 0;
  totalPrima2 = 0;
  totalPrima3 = 0;
  totalPrima4 = 0;
  totalPrima5 = 0;

  // Arte_suppo
  totalArte_suppo = 0;
  totalArte_suppo1 = 0;
  totalArte_suppo2 = 0;
  totalArte_suppo3 = 0;
  totalArte_suppo4 = 0;
  totalArte_suppo5 = 0;

  // Arte_cp
  totalArte_cp = 0;
  totalArte_cp1 = 0;
  totalArte_cp2 = 0;
  totalArte_cp3 = 0;
  totalArte_cp4 = 0;
  totalArte_cp5 = 0;

  _diagnostics = new BehaviorSubject<any[]>([]);

  get _diagnostics$() {
    return this._diagnostics.asObservable();
  }
  constructor(
    private accessProviders: AccessProviders,
    private platform: Platform,
    private fileOpener: FileOpener,
    private file: File
  ) {}

  addRMA_CTR(date) {
    return new Promise(() => {
      const body = {
        action: 'ADD_CTR',
        date: this.momentjs(date).format('YYYYMMDD'),
        nb_ctr: 1,
      };

      this.accessProviders.postData(body, 'rma.php').subscribe();
    });
  }

  async getSUM_CTR(date) {
    this.rmaCTR = [];
    return new Promise((_) => {
      const body = {
        action: 'NB_CTR',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.rmaCTR.push(data);
          this.totalCTR = 0;
          this.rmaCTR.forEach((e) => {
            this.totalCTR += +e.nb_ctr;
          });
        }
      });
    });
  }

  addRMA_GEN(date) {
    return new Promise(() => {
      const body = {
        action: 'ADD_GEN',
        date: this.momentjs(date).format('YYYYMMDD'),
        nb_gen: 1,
      };

      this.accessProviders.postData(body, 'rma.php').subscribe();
    });
  }

  async getSUM_GEN(date) {
    this.rmaGEN = [];
    return new Promise((_) => {
      const body = {
        action: 'NB_GEN',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.rmaGEN.push(data);
          this.totalGEN = 0;
          this.rmaGEN.forEach((e) => {
            this.totalGEN += +e.nb_gen;
          });
        }
      });
    });
  }

  // PCIME
  addRMA_PCIME(date, d, sro, p, a, c) {
    return new Promise(() => {
      const body = {
        action: 'ADD_PCIME',
        date: this.momentjs(date).format('YYYYMMDD'),
        diarrhee: d,
        sro: sro,
        pneumonie: p,
        amox: a,
        conseil: c,
      };

      this.accessProviders.postData(body, 'rma.php').subscribe();
    });
  }

  async getSUM_PCIME(date) {
    this.rmaPCIME = [];
    return new Promise((_) => {
      const body = {
        action: 'GET_PCIME',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.rmaPCIME.push(data);
          this.totalD = 0;
          this.totalSRO = 0;
          this.totalP = 0;
          this.totalAmox = 0;
          this.totalConseil = 0;
          this.rmaPCIME.forEach((e) => {
            this.totalD += +e.diarrhee;
            this.totalSRO += +e.sro;
            this.totalP += +e.pneumonie;
            this.totalAmox += +e.amox;
            this.totalConseil += +e.conseil;
          });
        }
      });
    });
  }

  async getSUM_PALU_1(date) {
    this.rmaPalu1 = [];
    return new Promise((_) => {
      const body = {
        action: 'GET_RMA_PALU_1',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.totalF1 = 0;
          this.totalRDT1 = 0;
          this.totalMicro1 = 0;
          this.total101_1 = 0;
          this.total110_1 = 0;
          this.total111_1 = 0;
          this.totalMicro_p1 = 0;
          this.totalM1 = 0;
          this.totalFm1 = 0;
          this.totalACT1 = 0;
          this.totalPrima1 = 0;
          this.totalArte_suppo1 = 0;
          this.totalArte_cp1 = 0;
          this.rmaPalu1.push(data);

          this.rmaPalu1.forEach((e) => {
            this.totalF1 += +e.fievre;
            this.totalRDT1 += +e.rdt;
            this.totalMicro1 += +e.micro;
            this.total101_1 += +e._101;
            this.total110_1 += +e._110;
            this.total111_1 += +e._111;
            this.totalMicro_p1 += +e.micro_p;
            this.totalM1 += +e.m;
            this.totalFm1 += +e.f;
            this.totalACT1 += +e.act;
            this.totalPrima1 += +e.prima;
            this.totalArte_suppo1 += +e.arte_suppo;
            this.totalArte_cp1 += +e.arte_cp;
          });
        }
      });
    });
  }

  async getSUM_PALU_2(date) {
    this.rmaPalu2 = [];
    return new Promise((_) => {
      const body = {
        action: 'GET_RMA_PALU_2',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.rmaPalu2.push(data);
          this.totalF2 = 0;
          this.totalRDT2 = 0;
          this.totalMicro2 = 0;
          this.total101_2 = 0;
          this.total110_2 = 0;
          this.total111_2 = 0;
          this.totalMicro_p2 = 0;
          this.totalM2 = 0;
          this.totalFm2 = 0;
          this.totalACT2 = 0;
          this.totalPrima2 = 0;
          this.totalArte_suppo2 = 0;
          this.totalArte_cp2 = 0;

          this.rmaPalu2.forEach((e) => {
            this.totalF2 += +e.fievre;
            this.totalRDT2 += +e.rdt;
            this.totalMicro1 += +e.micro;
            this.total101_2 += +e._101;
            this.total110_2 += +e._110;
            this.total111_2 += +e._111;
            this.totalMicro_p2 += +e.micro_p;
            this.totalM2 += +e.m;
            this.totalFm2 += +e.f;
            this.totalACT2 += +e.act;
            this.totalPrima2 += +e.prima;
            this.totalArte_suppo2 += +e.arte_suppo;
            this.totalArte_cp2 += +e.arte_cp;
          });
        }
      });
    });
  }

  async getSUM_PALU_3(date) {
    this.rmaPalu3 = [];
    return new Promise((_) => {
      const body = {
        action: 'GET_RMA_PALU_3',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.rmaPalu3.push(data);
          this.totalF3 = 0;
          this.totalRDT3 = 0;
          this.totalMicro3 = 0;
          this.total101_3 = 0;
          this.total110_3 = 0;
          this.total111_3 = 0;
          this.totalMicro_p3 = 0;
          this.totalM3 = 0;
          this.totalFm3 = 0;
          this.totalACT3 = 0;
          this.totalPrima3 = 0;
          this.totalArte_suppo3 = 0;
          this.totalArte_cp3 = 0;

          this.rmaPalu3.forEach((e) => {
            this.totalF3 += +e.fievre;
            this.totalRDT3 += +e.rdt;
            this.totalMicro3 += +e.micro;
            this.total101_3 += +e._101;
            this.total110_3 += +e._110;
            this.total111_3 += +e._111;
            this.totalMicro_p3 += +e.micro_p;
            this.totalM3 += +e.m;
            this.totalFm3 += +e.f;
            this.totalACT3 += +e.act;
            this.totalPrima3 += +e.prima;
            this.totalArte_suppo3 += +e.arte_suppo;
            this.totalArte_cp3 += +e.arte_cp;
          });
        }
      });
    });
  }

  async getSUM_PALU_4(date) {
    this.rmaPalu4 = [];
    return new Promise((_) => {
      const body = {
        action: 'GET_RMA_PALU_4',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.rmaPalu4.push(data);
          this.totalF4 = 0;
          this.totalRDT4 = 0;
          this.totalMicro4 = 0;
          this.total101_4 = 0;
          this.total110_4 = 0;
          this.total111_4 = 0;
          this.totalMicro_p4 = 0;
          this.totalM4 = 0;
          this.totalFm4 = 0;
          this.totalACT4 = 0;
          this.totalPrima4 = 0;
          this.totalArte_suppo4 = 0;
          this.totalArte_cp4 = 0;

          this.rmaPalu4.forEach((e) => {
            this.totalF4 += +e.fievre;
            this.totalRDT4 += +e.rdt;
            this.totalMicro4 += +e.micro;
            this.total101_4 += +e._101;
            this.total110_4 += +e._110;
            this.total111_4 += +e._111;
            this.totalMicro_p4 += +e.micro_p;
            this.totalM4 += +e.m;
            this.totalFm4 += +e.f;
            this.totalACT4 += +e.act;
            this.totalPrima4 += +e.prima;
            this.totalArte_suppo4 += +e.arte_suppo;
            this.totalArte_cp4 += +e.arte_cp;
          });
        }
      });
    });
  }

  async getSUM_PALU_5(date) {
    this.rmaPalu5 = [];
    return new Promise((_) => {
      const body = {
        action: 'GET_RMA_PALU_5',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.rmaPalu5.push(data);
          this.totalF5 = 0;
          this.totalRDT5 = 0;
          this.totalMicro5 = 0;
          this.total101_5 = 0;
          this.total110_5 = 0;
          this.total111_5 = 0;
          this.totalMicro_p5 = 0;
          this.totalM5 = 0;
          this.totalFm5 = 0;
          this.totalACT5 = 0;
          this.totalPrima5 = 0;
          this.totalArte_suppo5 = 0;
          this.totalArte_cp5 = 0;

          this.rmaPalu5.forEach((e) => {
            this.totalF5 += +e.fievre;
            this.totalRDT5 += +e.rdt;
            this.totalMicro5 += +e.micro;
            this.total101_5 += +e._101;
            this.total110_5 += +e._110;
            this.total111_5 += +e._111;
            this.totalMicro_p5 += +e.micro_p;
            this.totalM5 += +e.m;
            this.totalFm5 += +e.f;
            this.totalACT5 += +e.act;
            this.totalPrima5 += +e.prima;
            this.totalArte_suppo5 += +e.arte_suppo;
            this.totalArte_cp5 += +e.arte_cp;
          });
        }
      });
    });
  }
  async getSUM_PALU(date) {
    this.rmaPalu = [];
    return new Promise((_) => {
      const body = {
        action: 'GET_SUM_PALU',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.rmaPalu.push(data);
          this.totalF = 0;
          this.totalRDT = 0;
          this.totalMicro = 0;
          this.total101 = 0;
          this.total110 = 0;
          this.total111 = 0;
          this.totalMicro_p = 0;
          this.totalM = 0;
          this.totalFm = 0;
          this.totalACT = 0;
          this.totalPrima = 0;
          this.totalArte_suppo = 0;
          this.totalArte_cp = 0;

          this.rmaPalu.forEach((e) => {
            this.totalF += +e.fievre;
            this.totalRDT += +e.rdt;
            this.totalMicro += +e.micro;
            this.total101 += +e._101;
            this.total110 += +e._110;
            this.total111 += +e._111;
            this.totalMicro_p += +e.micro_p;
            this.totalM += +e.m;
            this.totalFm += +e.f;
            this.totalACT += +e.act;
            this.totalPrima += +e.prima;
            this.totalArte_suppo += +e.arte_suppo;
            this.totalArte_cp += +e.arte_cp;
          });
        }
      });
    });
  }

  addRMA_NC(sexe, age, diag, date) {
    if (sexe === 'Masculin') {
      if (age <= 0.076 && age > 0) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r1m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 0.161 && age >= 0.079) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r2m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 0.916 && age >= 0.166) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r3m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 4 && age >= 1) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r4m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 14 && age >= 5) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r5m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 24 && age >= 15) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r6m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 59 && age >= 25) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r7m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age >= 60) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r8m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      }
    } else if (sexe === 'Féminin') {
      if (age <= 0.076 && age >= 0) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r1f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 0.161 && age >= 0.079) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r2f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 0.916 && age >= 0.166) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r3f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 4 && age >= 1) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r4f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 14 && age >= 5) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r5f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 24 && age >= 15) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r6f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 59 && age >= 25) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r7f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age >= 60) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_NC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r8f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      }
    }
  }

  addRMA_AUTRE(sexe, age, diag, date) {
    if (sexe === 'Masculin') {
      if (age <= 0.076 && age > 0) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r1m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 0.161 && age >= 0.079) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r2m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 0.916 && age >= 0.166) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r3m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 4 && age >= 1) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r4m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 14 && age >= 5) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r5m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 24 && age >= 15) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r6m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 59 && age >= 25) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r7m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age >= 60) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r8m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      }
    } else if (sexe === 'Féminin') {
      if (age <= 0.076 && age >= 0) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r1f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 0.161 && age >= 0.079) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r2f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 0.916 && age >= 0.166) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r3f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 4 && age >= 1) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r4f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 14 && age >= 5) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r5f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 24 && age >= 15) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r6f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 59 && age >= 25) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r7f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age >= 60) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_AUTRE',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r8f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      }
    }
  }

  addRMA_PALU(
    age,
    date,
    fievre,
    rdt,
    micro,
    _101,
    _110,
    _111,
    micro_p,
    m,
    f,
    act,
    prima,
    arte_suppo,
    arte_cp
  ) {
    if (age <= 0.916 && age >= 0.166) {
      return new Promise(() => {
        const body = {
          action: 'ADD_RMA_PALU',
          date: this.momentjs(date).format('YYYYMMDD'),
          age_id: 1,
          fievre: fievre,
          rdt: rdt,
          micro: micro,
          _101: _101,
          _110: _110,
          _111: _111,
          micro_p,
          m: m,
          f: f,
          act: act,
          prima: prima,
          arte_suppo: arte_suppo,
          arte_cp: arte_cp,
        };

        this.accessProviders.postData(body, 'rma.php').subscribe();
      });
    } else if (age <= 5 && age >= 1) {
      return new Promise(() => {
        const body = {
          action: 'ADD_RMA_PALU',
          date: this.momentjs(date).format('YYYYMMDD'),
          age_id: 2,
          fievre: fievre,
          rdt: rdt,
          micro: micro,
          _101: _101,
          _110: _110,
          _111: _111,
          micro_p,
          m: m,
          f: f,
          act: act,
          prima: prima,
          arte_suppo: arte_suppo,
          arte_cp: arte_cp,
        };

        this.accessProviders.postData(body, 'rma.php').subscribe();
      });
    } else if (age <= 13 && age >= 6) {
      return new Promise(() => {
        const body = {
          action: 'ADD_RMA_PALU',
          date: this.momentjs(date).format('YYYYMMDD'),
          age_id: 3,
          fievre: fievre,
          rdt: rdt,
          micro: micro,
          _101: _101,
          _110: _110,
          _111: _111,
          micro_p,
          m: m,
          f: f,
          act: act,
          prima: prima,
          arte_suppo: arte_suppo,
          arte_cp: arte_cp,
        };

        this.accessProviders.postData(body, 'rma.php').subscribe();
      });
    } else if (age <= 24 && age >= 14) {
      return new Promise(() => {
        const body = {
          action: 'ADD_RMA_PALU',
          date: this.momentjs(date).format('YYYYMMDD'),
          age_id: 4,
          fievre: fievre,
          rdt: rdt,
          micro: micro,
          _101: _101,
          _110: _110,
          _111: _111,
          micro_p,
          m: m,
          f: f,
          act: act,
          prima: prima,
          arte_suppo: arte_suppo,
          arte_cp: arte_cp,
        };

        this.accessProviders.postData(body, 'rma.php').subscribe();
      });
    } else if (age >= 25) {
      return new Promise(() => {
        const body = {
          action: 'ADD_RMA_PALU',
          date: this.momentjs(date).format('YYYYMMDD'),
          age_id: 5,
          fievre: fievre,
          rdt: rdt,
          micro: micro,
          _101: _101,
          _110: _110,
          _111: _111,
          micro_p,
          m: m,
          f: f,
          act: act,
          prima: prima,
          arte_suppo: arte_suppo,
          arte_cp: arte_cp,
        };

        this.accessProviders.postData(body, 'rma.php').subscribe();
      });
    }
  }
  addRMA_MC(sexe, age, diag, date) {
    if (sexe === 'Masculin') {
      if (age <= 4 && age > 0) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r1m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 9 && age >= 5) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r2m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 14 && age >= 10) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r3m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 17 && age >= 15) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r4m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 24 && age >= 18) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r5m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 59 && age >= 25) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r6m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age >= 60) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r7m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      }
    } else if (sexe === 'Féminin') {
      if (age <= 4 && age > 0) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r1f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 9 && age >= 5) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r2f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 14 && age >= 10) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r3f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 17 && age >= 15) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r4f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 24 && age >= 18) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r5f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 59 && age >= 25) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r6f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age >= 60) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_MC',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r7f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      }
    }
  }

  addRMA_TRAUMA(sexe, age, diag, date) {
    if (sexe === 'Masculin') {
      if (age <= 4 && age > 0) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r1m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 9 && age >= 5) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r2m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 14 && age >= 10) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r3m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 17 && age >= 15) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r4m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 24 && age >= 18) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r5m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 59 && age >= 25) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r6m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age >= 60) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r7m: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      }
    } else if (sexe === 'Féminin') {
      if (age <= 4 && age > 0) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r1f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 9 && age >= 5) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r2f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 14 && age >= 10) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r3f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 17 && age >= 15) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r4f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 24 && age >= 18) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r5f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age <= 59 && age >= 25) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r6f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      } else if (age >= 60) {
        return new Promise(() => {
          const body = {
            action: 'ADD_RMA_TRAUMA',
            diag_id: diag,
            date: this.momentjs(date).format('YYYYMMDD'),
            r7f: 1,
          };

          this.accessProviders.postData(body, 'rma.php').subscribe();
        });
      }
    }
  }

  addRMA_AC(date, diag) {
    return new Promise(() => {
      const body = {
        action: 'ADD_RMA_AC',
        diag: diag,
        date: this.momentjs(date).format('YYYYMMDD'),
        nb: 1,
      };

      this.accessProviders.postData(body, 'rma.php').subscribe();
    });
  }

  addRMA_CPN(date, diag, type) {
    if (type === 'scol') {
      return new Promise(() => {
        const body = {
          action: 'ADD_RMA_CPN',
          diag: diag,
          date: this.momentjs(date).format('YYYYMMDD'),
          nb: 1,
          scol: 1,
          noScol: 0,
        };

        this.accessProviders.postData(body, 'rma.php').subscribe();
      });
    } else if (type === 'noScol') {
      return new Promise(() => {
        const body = {
          action: 'ADD_RMA_CPN',
          diag: diag,
          date: this.momentjs(date).format('YYYYMMDD'),
          nb: 1,
          scol: 0,
          noScol: 1,
        };

        this.accessProviders.postData(body, 'rma.php').subscribe();
      });
    } else if (type === '') {
      return new Promise(() => {
        const body = {
          action: 'ADD_RMA_CPN',
          diag: diag,
          date: this.momentjs(date).format('YYYYMMDD'),
          nb: 1,
          scol: 0,
          noScol: 0,
        };

        this.accessProviders.postData(body, 'rma.php').subscribe();
      });
    }
  }

  async getSUM_AC(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_SUM_AC',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.rmaD = [];
        for (const data of res.result) {
          this.rmaD.push(data);
        }

        resolve(true);
      });
    });
  }

  async getSUM_CPN(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_SUM_CPN',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.rmaCPN = [];
        for (const data of res.result) {
          this.rmaCPN.push(data);
          this.totalCPN = 0;
          this.rmaCPN.forEach((e) => {
            this.totalCPN += +e.nb;
          });
        }

        resolve(true);
      });
    });
  }

  async getSUM_NC(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_SUM_NC',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.rmas = [];
        for (const data of res.result) {
          this.rmas.push(data);
          this.totalNC = 0;
          this.rmas.forEach((e) => {
            this.totalNC +=
              +e.r1m +
              +e.r1f +
              +e.r2m +
              +e.r2f +
              +e.r3m +
              +e.r3f +
              +e.r4m +
              +e.r4f +
              +e.r5m +
              +e.r5f +
              +e.r6m +
              +e.r6f +
              +e.r7m +
              +e.r7f +
              +e.r8m +
              +e.r8f +
              +e.r9m +
              +e.r9f;
          });
        }
        resolve(true);
      });
    });
  }

  async getRMA_A(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_RMA_A',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.rmaA = [];
        for (const data of res.result) {
          this.rmaA.push(data);
        }
        resolve(true);
      });
    });
  }

  async getRMA_B(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_RMA_B',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.rmaB = [];
        for (const data of res.result) {
          this.rmaB.push(data);
        }
        resolve(true);
      });
    });
  }

  async getRMA_C(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_RMA_C',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.rmaC = [];
        for (const data of res.result) {
          this.rmaC.push(data);
        }
        resolve(true);
      });
    });
  }

  async getRMA_MC(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_RMA_MC',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.rmaMC = [];
        for (const data of res.result) {
          this.rmaMC.push(data);
        }

        resolve(true);
      });
    });
  }

  async getRMA_TRAUMA(date) {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_RMA_TRAUMA',
        date: this.momentjs(date).format('YYYYMM'),
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.rmaTR = [];
        for (const data of res.result) {
          this.rmaTR.push(data);
          this.totalTR = 0;
          this.rmaTR.forEach((e) => {
            this.totalTR +=
              +e.r1m +
              +e.r1f +
              +e.r2m +
              +e.r2f +
              +e.r3m +
              +e.r3f +
              +e.r4m +
              +e.r4f +
              +e.r5m +
              +e.r5f +
              +e.r6m +
              +e.r6f +
              +e.r7m +
              +e.r7f +
              +e.r8m +
              +e.r8f;
          });
        }
        resolve(true);
      });
    });
  }

  async getDiagNC() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_DIAG_NC',
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.diagnostics = [];
        for (const data of res.result) {
          this.diagnostics.push(data);
          this.filteredData = this.diagnostics;
          this._diagnostics.next(this.diagnostics);
          this.isLoading = false;
        }
        resolve(true);
      });
    });
  }

  async getDiagMC() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_DIAG_MC',
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.diagnostics = [];
        for (const data of res.result) {
          this.diagnostics.push(data);
          this.filteredData = this.diagnostics;
          this._diagnostics.next(this.diagnostics);
          this.isLoading = false;
        }
        resolve(true);
      });
    });
  }

  async getDiagTR() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_DIAG_TR',
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        this.diagnostics = [];
        for (const data of res.result) {
          this.diagnostics.push(data);
          this.filteredData = this.diagnostics;
          this._diagnostics.next(this.diagnostics);
          this.isLoading = false;
        }
        resolve(true);
      });
    });
  }

  async getDiagCPN() {
    this.diagnostics = [];
    return new Promise((resolve) => {
      const body = {
        action: 'GET_DIAG_CPN',
      };

      this.accessProviders.postData(body, 'rma.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.diagnostics.push(data);
          this.filteredData = this.diagnostics;
          this._diagnostics.next(this.diagnostics);
          this.isLoading = false;
        }
        resolve(true);
      });
    });
  }

  exportPDF() {
    var dd = {
      pageSize: 'A4',
      watermark: {
        text: 'Focus GCM',
        color: 'grey',
        opacity: 0.05,
        bold: true,
        italics: false,
      },
      content: [],
      styles: {
        f18: {
          fontSize: 18,
          margin: [0, 10, 0, 0],
        },
        f14: {
          fontSize: 14,
          margin: [0, 0, 0, 0],
        },
        strong: {
          bold: true,
        },
        title: {
          fontSize: 10,
          margin: [0, 10, 0, 0],
        },
        content: {
          fontSize: 10,
          margin: [0, 10, 0, 0],
        },
        header: {
          fontSize: 8,
          margin: [0, 5, 0, 0],
          color: 'grey',
        },
      },
    };

    dd.content.push(
      {
        text: "RAPPORT MENSUEL D'ACTIVITES INTEGREES",
        alignment: 'center',
        style: ['f18', 'strong'],
      },
      {
        text: 'Collecte niveau CSB',
        alignment: 'center',
        style: ['f12', 'strong'],
      },
      {
        text: '(Activités Curatives /Prévention / Maternité/ Gestion des intrants/Gestion Financière)',
        alignment: 'center',
        style: ['f10', 'italic'],
      }
    );
    dd.content.push('  ');

    // Tableau 1
    dd.content.push(
      {
        text: 'Tableau 1 : IDENTIFICATION DE LA FORMATION SANITAIRE',
        alignment: 'left',
        style: ['f14', 'strong'],
      },
      {
        columns: [
          { text: 'N° d’identification du CSB (code CSB)', bold: true },
          { text: '', width: '50%' },
        ],
        style: 'content',
      },
      {
        columns: [
          { text: 'Type et Nom du CSB :', bold: true },
          { text: '' },
          { text: 'Commune : ', bold: true },
          { text: '' },
        ],
        style: 'content',
      },
      {
        columns: [
          { text: 'Statut administratif du CSB :', bold: true },
          { text: '', width: '50%' },
        ],
        style: 'content',
      },
      {
        columns: [
          { text: 'SDSP de :', bold: true },
          { text: '', width: '50%' },
        ],
        style: 'content',
      },
      {
        columns: [
          { text: 'DRSP de :', bold: true },
          { text: '', width: '50%' },
        ],
        style: 'content',
      }
    );
    dd.content.push('  ');

    // Tableau 2
    dd.content.push(
      {
        text: 'Tableau 2 : TRANSMISSION DES RAPPORTS',
        alignment: 'left',
        style: ['f14', 'strong'],
      },
      {
        columns: [
          { text: 'Rapport adressé au SDSP le :', bold: true },
          { text: '', width: '50%' },
        ],
        style: 'content',
      },
      {
        columns: [
          { text: 'Nom / Titre / Signature :', bold: true },
          { text: '', width: '50%' },
        ],
        style: 'content',
      }
    );
    dd.content.push('  ');

    // Tableau 3
    dd.content.push(
      {
        text: 'Tableau 3 : COMMUNICATION ET SENSIBILISATION',
        alignment: 'left',
        style: ['f14', 'strong'],
      },
      {
        table: {
          widths: ['*', '*', '*', '*', '*'],
          headerRows: 2,
          // keepWithHeaderRows: 1,
          body: [
            [
              {
                text: 'Programme',
                colspan: 2,
                style: 'tableHeader',
                alignment: 'center',
              },
              { text: 'Thème', style: 'tableHeader', alignment: 'center' },
              {
                text: 'Nombre de participants aux séances',
                colspan: 2,
                style: 'tableHeader',
                alignment: 'center',
              },
              {},
              {},
            ],
            ['', '', 'Homme', 'Femme', 'Total'],
          ],
        },
      }
    );

    this.pdfObj = pdfMake.createPdf(dd); //create pdf

    if (this.platform.is('cordova')) {
      this.pdfObj.getBuffer((buffer) => {
        var blob = new Blob([buffer], { type: 'application/pdf' });

        // Save the PDF to the data Directory of our App
        this.file
          .writeFile(this.file.dataDirectory, this.rma.date + '.pdf', blob, {
            replace: true,
          })
          .then(() => {
            // Open the PDf with the correct OS tools
            this.fileOpener.open(
              this.file.dataDirectory + this.rma.date + '.pdf',
              'application/pdf'
            );
          });
      });
    } else {
      // On a browser simply use download!
      this.pdfObj.download(this.rma.date);
    }
  }

  filterList(evt) {
    this._diagnostics.next(this.filteredData);
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._diagnostics.next(
      this.filteredData.filter((item: { libelle: string }) => {
        return (
          item.libelle.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      })
    );
  }
}
