import { WaitService } from './../../../services/wait.service';
import { Component, OnInit } from '@angular/core';
import { CaisseService } from 'src/app/services/caisse.service';
import { ConsultationService } from 'src/app/services/consultation.service';
import { DashService } from 'src/app/services/dash.service';
import { PatientService } from 'src/app/services/patient.service';
import { UtilsService } from 'src/app/services/utils.service';
import { UserService } from './../../../services/user.service';
import { ProductService } from './../../../services/product.service';
import * as moment from 'moment';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss'],
})
export class DashComponent implements OnInit {
  momentjs: any = moment;
  nbPatient = 0;
  patientJour = 0;
  totalG = 0;
  nbWait = 0;
  nbCs = 0;

  date = this.momentjs(new Date()).format('YYYYMMDD');

  constructor(
    public waitService: WaitService,
    public caisseService: CaisseService,
    public utils: UtilsService,
    public dashService: DashService,
    public patientService: PatientService,
    public consultationService: ConsultationService,
    public userService: UserService,
    public productService: ProductService
  ) {
    this.utils.checkPlatform();
    this.getData();
  }

  refresh() {
    this.caisseService.caisseJour(this.date);
  }

  async ngOnInit() {
    this.utils.getSession();
    this.patientService._nbPatient$.subscribe((nb: any) => {
      this.nbPatient = nb;
    });

    this.patientService._patientJour$.subscribe((nb: any) => {
      this.patientJour = nb;
    });

    this.waitService._nbWait$.subscribe((waiting: any) => {
      this.nbWait = waiting;
    });

    this.caisseService._totalG$.subscribe((totalG: any) => {
      this.totalG = totalG;
    });

    this.consultationService._nbCs$.subscribe((nbCs: any) => {
      this.nbCs = nbCs;
    });
  }

  getData() {
    this.caisseService.caisseJour(this.date);
    this.productService.nbProduit();

    this.patientService.nbPatient();
    this.patientService.nbPatientParJour();
    this.userService.nbUser();

    this.consultationService.nbConsultation();
    this.consultationService.nbConsultationParJour();
    this.consultationService.nbConsultationJourPrescripteur();
  }
}
