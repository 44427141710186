import { ModalController } from '@ionic/angular';
import { AppService } from './../../../services/app.service';
import { UserService } from './../../../services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarView,
  DAYS_OF_WEEK,
} from 'angular-calendar';
import { CustomDateFormatter } from 'src/app/providers/custom-date-formatter.provider.service';
import { Subject } from 'rxjs';
import { DetailEventComponent } from '../detail-event/detail-event.component';
import { isSameDay, isSameMonth } from 'date-fns';

interface Appointment {
  id: number;
  title: string;
  dateTime: string;
}

@Component({
  selector: 'app-cal-scheduler',
  templateUrl: './cal-scheduler.component.html',
  styleUrls: ['./cal-scheduler.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalSchedulerComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  appointments = [];
  activeDayIsOpen: boolean = false;

  refresh = new Subject<void>();

  doctors = [];
  isLoading: boolean;

  constructor(
    public utils: UtilsService,
    public userService: UserService,
    public appService: AppService,
    private modalCtrl: ModalController
  ) {
    this.utils.checkPlatform();
    this.userService.loadDoctors();
    this.appService.fetchEvents();
    this.appService.user_id = 0;
  }

  ngOnInit(): void {
    this.doctors = [];
    this.userService._doctors$.subscribe((doctors: any[]) => {
      this.doctors = doctors;
    });

    this.userService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });
  }

  ionViewDidEnter() {}

  setIndex(index) {
    this.appService.activeIndex = index;
  }

  async dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ app: Appointment }>[];
  }): Promise<void> {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
        this.appService.addEvent(date);
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    } else {
      this.appService.addEvent(date);
    }
  }

  async eventClicked(event): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: DetailEventComponent,
      animated: true,
      mode: 'ios',
      componentProps: { event: event },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win5-modal-web' : 'win5-modal-mobile',
    });
    await modal.present();
  }
}
