import { PrestationService } from './../../../services/prestation.service';
import { ModalController } from '@ionic/angular';
import { AddOtherComponent } from './../add-other/add-other.component';
import { CaisseService } from 'src/app/services/caisse.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-table-other',
  templateUrl: './table-other.component.html',
  styleUrls: ['./table-other.component.scss'],
})
export class TableOtherComponent implements OnInit {
  item = {
    title: 'Pas de résultat trouvé',
  };

  searchInput;

  others = [];
  isLoading: boolean;
  isOtherEmpty: boolean;

  constructor(
    public accessProviders: AccessProviders,
    public utils: UtilsService,
    public router: Router,
    private modalCtrl: ModalController,
    public caisseService: CaisseService,
    public prestationService: PrestationService
  ) {
    this.prestationService.loadOthers();

    this.utils.checkPlatform();
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();

    this.prestationService._others$.subscribe((others: any[]) => {
      this.others = others;
    });

    this.prestationService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });

    this.prestationService._isOtherEmpty$.subscribe((isOtherEmpty: any) => {
      this.isOtherEmpty = isOtherEmpty;
    });
  }

  async ionViewDidEnter() {
    this.refreshData();
    this.caisseService.loadPOS();
  }

  refreshData() {
    this.prestationService.loadOthers();
  }

  search(ev) {
    this.searchInput = ev.target.value;
  }

  async openOther(produit) {
    const modal = await this.modalCtrl.create({
      component: AddOtherComponent,
      animated: true,
      mode: 'ios',
      componentProps: { article: produit },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win9-modal-web' : 'win9-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.prestationService.loadOthers();
      this.searchInput = '';
    }
  }
}
