import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { AppService } from 'src/app/services/app.service';
import { PatientService } from 'src/app/services/patient.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-update-event',
  templateUrl: './update-event.component.html',
  styleUrls: ['./update-event.component.scss'],
})
export class UpdateEventComponent implements OnInit {
  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public patientService: PatientService,
    public userService: UserService,
    public appService: AppService
  ) {
    this.appService.id = this.navParams.get('id');
    if (this.appService.id !== 0) {
      this.appService.loadSingleApp();

      console.log(this.appService.appointment.p_id);
    }
    this.utils.checkPlatform();
  }

  ngOnInit() {
    // this.patientService.loadPatients();
    this.userService.loadDoctors();
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }
}
