import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
} from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Md5 } from 'ts-md5';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-crud-user',
  templateUrl: './crud-user.component.html',
  styleUrls: ['./crud-user.component.scss'],
})
export class CrudUserComponent implements OnInit {
  type = true;

  constructor(
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public userService: UserService,
    public utils: UtilsService
  ) {
    // Recupere ID si existe
    this.userService.id = this.navParams.get('id');
  }

  ngOnInit() {
    if (this.userService.id !== 0) {
      this.userService.loadSingleUser();
    } else {
      this.userService.clearInput();
    }
  }

  changeType() {
    this.type = !this.type;
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }
}
