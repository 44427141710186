import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  pdfFile;
  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    this.pdfFile = this.navParams.get('pdf');
  }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }
}
