import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CaisseService } from 'src/app/services/caisse.service';
import { UtilsService } from 'src/app/services/utils.service';
import { UpdateCreanceComponent } from './../update-creance/update-creance.component';

@Component({
  selector: 'app-caisse-creance',
  templateUrl: './caisse-creance.component.html',
  styleUrls: ['./caisse-creance.component.scss'],
})
export class CaisseCreanceComponent implements OnInit {
  constructor(
    public caisseService: CaisseService,
    public utils: UtilsService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.caisseService.loadCreance();
  }

  async openUpdate(cc) {
    const modal = await this.modalCtrl.create({
      component: UpdateCreanceComponent,
      animated: true,
      mode: 'ios',
      componentProps: { detail: cc },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win2-modal-web' : 'win2-modal-mobile',
    });

    await modal.present();
  }
}
