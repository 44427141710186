import { ProductService } from '../../../services/product.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';
import { format } from 'date-fns';

@Component({
  selector: 'app-stock-actuel',
  templateUrl: './stock-actuel.component.html',
  styleUrls: ['./stock-actuel.component.scss'],
})
export class StockActuelComponent implements OnInit {
  date;
  item = {
    icon: 'search-outline',
    title: 'Produit non trouvé',
  };
  stock = {
    categorie: '',
  };

  searchInput = '';
  produits = [];
  nbProduit = 0;

  isLoading: boolean;
  isEmpty: boolean;

  constructor(
    public utils: UtilsService,
    public accessProviders: AccessProviders,
    public platform: Platform,
    public router: Router,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();
    this.productService.getProduits();
    this.productService.nbProduit();
    this.productService.loadCategories();

    this.date = format(Date.now(), 'MM/yyyy');

    this.productService._nbProduit$.subscribe((nbProduit) => {
      this.nbProduit = nbProduit;
    });

    this.productService._produits$.subscribe((produits: any[]) => {
      this.produits = produits;
    });

    this.productService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });

    this.productService._isEmpty$.subscribe((isEmpty: any) => {
      this.isEmpty = isEmpty;
    });
  }

  search(ev) {
    this.searchInput = ev.target.value;
  }

  sliceMonth(str) {
    var subString = str.substr(0, 2);
    return subString;
  }

  sliceYear(str) {
    var subString = str.slice(3, str.length);
    return subString;
  }
}
