import { Component, OnInit } from '@angular/core';
import { InfoService } from 'src/app/services/info.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-rcs',
  templateUrl: './rcs.component.html',
  styleUrls: ['./rcs.component.scss'],
})
export class RcsComponent implements OnInit {
  date = new Date();
  epi = { week: '' };
  constructor(public infoService: InfoService, public utils: UtilsService) {}

  weeks = [
    { sigle: 'S1', noSem: 'Semaine 1' },
    { sigle: 'S2', noSem: 'Semaine 2' },
    { sigle: 'S3', noSem: 'Semaine 3' },
    { sigle: 'S4', noSem: 'Semaine 4' },
    { sigle: 'S5', noSem: 'Semaine 5' },
    { sigle: 'S6', noSem: 'Semaine 6' },
    { sigle: 'S7', noSem: 'Semaine 7' },
    { sigle: 'S8', noSem: 'Semaine 8' },
    { sigle: 'S9', noSem: 'Semaine 9' },
    { sigle: 'S10', noSem: 'Semaine 10' },
    { sigle: 'S11', noSem: 'Semaine 11' },
    { sigle: 'S12', noSem: 'Semaine 12' },
    { sigle: 'S13', noSem: 'Semaine 13' },
    { sigle: 'S14', noSem: 'Semaine 14' },
    { sigle: 'S15', noSem: 'Semaine 15' },
    { sigle: 'S16', noSem: 'Semaine 16' },
    { sigle: 'S17', noSem: 'Semaine 17' },
    { sigle: 'S18', noSem: 'Semaine 18' },
    { sigle: 'S19', noSem: 'Semaine 19' },
    { sigle: 'S20', noSem: 'Semaine 20' },
    { sigle: 'S21', noSem: 'Semaine 21' },
    { sigle: 'S22', noSem: 'Semaine 22' },
    { sigle: 'S23', noSem: 'Semaine 23' },
    { sigle: 'S24', noSem: 'Semaine 24' },
    { sigle: 'S25', noSem: 'Semaine 25' },
    { sigle: 'S26', noSem: 'Semaine 26' },
    { sigle: 'S27', noSem: 'Semaine 27' },
    { sigle: 'S28', noSem: 'Semaine 28' },
    { sigle: 'S29', noSem: 'Semaine 29' },
    { sigle: 'S30', noSem: 'Semaine 30' },
    { sigle: 'S31', noSem: 'Semaine 31' },
    { sigle: 'S32', noSem: 'Semaine 32' },
    { sigle: 'S33', noSem: 'Semaine 33' },
    { sigle: 'S34', noSem: 'Semaine 34' },
    { sigle: 'S35', noSem: 'Semaine 35' },
    { sigle: 'S36', noSem: 'Semaine 36' },
    { sigle: 'S37', noSem: 'Semaine 37' },
    { sigle: 'S38', noSem: 'Semaine 38' },
    { sigle: 'S39', noSem: 'Semaine 39' },
    { sigle: 'S40', noSem: 'Semaine 40' },
    { sigle: 'S41', noSem: 'Semaine 41' },
    { sigle: 'S42', noSem: 'Semaine 42' },
    { sigle: 'S43', noSem: 'Semaine 43' },
    { sigle: 'S44', noSem: 'Semaine 44' },
    { sigle: 'S45', noSem: 'Semaine 45' },
    { sigle: 'S46', noSem: 'Semaine 46' },
    { sigle: 'S47', noSem: 'Semaine 47' },
    { sigle: 'S48', noSem: 'Semaine 48' },
    { sigle: 'S49', noSem: 'Semaine 49' },
    { sigle: 'S50', noSem: 'Semaine 50' },
    { sigle: 'S51', noSem: 'Semaine 51' },
    { sigle: 'S52', noSem: 'Semaine 52' },
  ];
  ngOnInit() {
    this.infoService.loadInfoFS();
  }
}
