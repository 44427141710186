import { UtilsService } from 'src/app/services/utils.service';
import { ModalController, LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { AccessProviders } from '../providers/access-providers.service';
import { DetailMedocComponent } from '../components/medoc/detail-medoc/detail-medoc.component';
import { PosologieComponent } from '../components/medoc/posologie/posologie.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MedocService {
  medicaments: any = [];
  filteredData = [];

  // Posologie
  medocId: any;
  lastID: number;
  posologie = {
    medoc: '',
    forme: '',
    qte: '',
    prise: '',
    freq: '',
    duree: '',
    frequence: '',
    pref: '',
  };

  _medicaments = new BehaviorSubject<any[]>([]);
  _isLoading = new BehaviorSubject<boolean>(true);
  _isEmpty = new BehaviorSubject<boolean>(false);

  get _medicaments$() {
    return this._medicaments.asObservable();
  }

  get _isLoading$() {
    return this._isLoading.asObservable();
  }

  get _isEmpty$() {
    return this._isEmpty.asObservable();
  }

  constructor(
    private modalCtrl: ModalController,
    public accessProviders: AccessProviders,
    private utils: UtilsService,
    private loadingCtrl: LoadingController
  ) {}

  refreshData() {
    this.medicaments = [];
    this.loadMedoc();
  }

  async loadMedoc() {
    this.medicaments = [];
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL',
      };

      this.accessProviders.postData(body, 'medoc.php').subscribe((res: any) => {
        if (res.result == '') {
          this._isEmpty.next(true);
          this._isLoading.next(false);
        } else {
          this._isEmpty.next(false);
          for (const data of res.result) {
            this.medicaments.push(data);
            this._medicaments.next(this.medicaments);
            this.filteredData = this.medicaments;
            this._isLoading.next(false);
          }
          resolve(true);
        }
      });
    });
  }

  filterList(evt) {
    this._medicaments.next(this.filteredData);

    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._medicaments.next(
      this.filteredData.filter((item: { nomCom: string; dci: string }) => {
        return (
          item.nomCom.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          item.dci.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      })
    );
  }

  async openDetail(medoc) {
    const modal = await this.modalCtrl.create({
      component: DetailMedocComponent,
      animated: true,
      mode: 'ios',
      componentProps: { medoc: medoc },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win2-modal-web' : 'win2-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }

  async openPosologie(medoc) {
    const modal = await this.modalCtrl.create({
      component: PosologieComponent,
      animated: true,
      mode: 'ios',
      componentProps: { medoc: medoc },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win2-modal-web' : 'win2-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }

  // POSOLOGIE
  async saveMedoc() {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      const body = {
        action: 'CRUD',
        medoc_id: this.medocId,
        forme: this.posologie.forme,
        posologie:
          this.posologie.qte +
          ' ' +
          this.posologie.forme +
          ', ' +
          this.posologie.prise +
          ' fois par ' +
          this.posologie.freq,
        duree: this.posologie.duree + ' ' + this.posologie.frequence,
        pref: this.posologie.pref,
      };

      this.accessProviders.postData(body, 'ordonnance.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            // Last ID
            const body = {
              action: 'GET_LAST_ID',
            };

            this.accessProviders
              .postData(body, 'ordonnance.php')
              .subscribe((res: any) => {
                for (const data of res.result) {
                  this.lastID = parseInt(data.id);
                }

                // Ordonnances
                return new Promise((_resolve) => {
                  const body = {
                    action: 'CRUD_ORDO',
                    patient_id: 1,
                    ordo_id: this.lastID,
                    date: this.utils.currentDate,
                  };

                  this.accessProviders
                    .postData(body, 'ordonnance.php')
                    .subscribe(
                      (res: any) => {
                        if (res.success === true) {
                          loader.dismiss();
                          this.utils.toast(res.msg);
                          this.modalCtrl.dismiss('', 'crud');
                        } else {
                          loader.dismiss();
                          this.utils.presentAlert(res.msg, '');
                        }
                      },
                      (_err) => {
                        loader.dismiss();
                        this.utils.presentAlert('Ordonnance', '');
                      }
                    );
                });
              });
          } else {
            loader.dismiss();
            this.utils.toastError(res.msg);
          }
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }
}
