import { UtilsService } from './../../../services/utils.service';
import { LoadingController, ModalController } from '@ionic/angular';
import { AccessProviders } from './../../../providers/access-providers.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss'],
})
export class SmsComponent implements OnInit {
  sms = {
    phone: '',
    messages: '',
  };
  constructor(
    private accessProviders: AccessProviders,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private utils: UtilsService
  ) {}

  ngOnInit() {}
  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }
  async sendSMS() {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      const body = {
        sendTo: this.sms.phone,
        sms: this.sms.messages,
      };

      this.accessProviders.postData(body, 'sms.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            loader.dismiss();
          } else {
            loader.dismiss();
            this.utils.toastError(res.msg);
          }
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'envoi");
        }
      );
    });
  }
}
