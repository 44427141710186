import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { CaisseService } from 'src/app/services/caisse.service';
import { PrestationService } from 'src/app/services/prestation.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CrudAutreComponent } from './../../crud/crud-autre/crud-autre.component';

@Component({
  selector: 'app-prestation-autre',
  templateUrl: './prestation-autre.component.html',
  styleUrls: ['./prestation-autre.component.scss'],
})
export class PrestationAutreComponent implements OnInit {
  item = {
    icon: 'search-outline',
    title: 'Pas de résultat trouvé',
  };

  others = [];
  isLoading: boolean;
  isOtherEmpty: boolean;

  constructor(
    public accessProviders: AccessProviders,
    public utils: UtilsService,
    public router: Router,
    private modalCtrl: ModalController,
    public caisseService: CaisseService,
    public prestationService: PrestationService
  ) {
    this.prestationService.loadOthers();

    this.utils.checkPlatform();
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();

    this.prestationService._others$.subscribe((others: any[]) => {
      this.others = others;
    });

    this.prestationService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });

    this.prestationService._isOtherEmpty$.subscribe((isOtherEmpty: any) => {
      this.isOtherEmpty = isOtherEmpty;
    });
  }

  refreshData() {
    this.prestationService.loadOthers();
  }

  async openCrud() {
    const modal = await this.modalCtrl.create({
      component: CrudAutreComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win10-modal-web' : 'win10-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.refreshData();
    }
  }
}
