import { Component, OnInit } from '@angular/core';
import { CaisseService } from 'src/app/services/caisse.service';
import { UtilsService } from './../../../services/utils.service';

@Component({
  selector: 'app-caisse-date',
  templateUrl: './caisse-date.component.html',
  styleUrls: ['./caisse-date.component.scss'],
})
export class CaisseDateComponent implements OnInit {
  selectedValue;
  readonly = true;
  today = new Date();

  constructor(public caisseService: CaisseService, public utils: UtilsService) {
    this.caisseService.date1 = this.utils.dateFormat(this.today);
    this.caisseService.caisseDate1(this.caisseService.date1);
  }

  ngOnInit() {}

  testDate1(date) {
    if (this.utils.dateFormat(date) > this.utils.dateFormat(new Date())) {
      this.utils.toastError('Date invalide');
    }
  }

  onClick() {
    this.readonly = true;
    this.caisseService.date2 = '';
    this.caisseService.dateRange = '';
    this.caisseService.caisses = [];
    this.caisseService.clearGT();
  }

  onChange(ev) {
    this.caisseService.date1 = '';
    this.caisseService.date2 = '';
    this.readonly = true;
    this.caisseService.caisses = [];
    this.caisseService.clearGT();

    if (ev === 'ceJour') {
      this.caisseService.caisseDate1(this.utils.dateFormat(this.today));
    } else if (ev === 'hier') {
      const yesterday = this.utils.dateFormat(
        new Date(+new Date() - 1 * 86400000)
      );
      this.caisseService.caisseDate1(yesterday);
    } else if (ev === 'semEnCours') {
      var first = this.today.getDate() - this.today.getDay() + 1;

      var monday = new Date(this.today.setDate(first));

      this.caisseService.date1 = this.utils.dateFormat(monday);
      this.caisseService.date2 = this.utils.dateFormat(this.today);

      this.caisseService.caisseDate2(
        this.caisseService.date1,
        this.caisseService.date2
      );
    } else if (ev === 'semPrec') {
      var prevMonday = new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate() - 7
      );
      var prevSunday = new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate() - 7 + 6
      );

      this.caisseService.date1 = this.utils.dateFormat(prevMonday);
      this.caisseService.date2 = this.utils.dateFormat(prevSunday);

      this.caisseService.caisseDate2(
        this.caisseService.date1,
        this.caisseService.date2
      );
    } else if (ev === 'moisEnCours') {
      this.caisseService.caisseDate3(this.today);
    } else if (ev === 'moisPrec') {
      var prevMonth = new Date(
        this.today.getFullYear(),
        this.today.getMonth() - 1
      );

      this.caisseService.caisseDate3(prevMonth);
    } else if (ev === 'anneeEnCours') {
      var year = new Date().getFullYear();

      this.caisseService.caisseDate4(year);
    } else if (ev === 'anneePrec') {
      var prevYear = new Date().getFullYear() - 1;

      this.caisseService.caisseDate4(prevYear);
    }
  }

  getCaisse1() {
    if (
      this.utils.dateFormat(this.caisseService.date1) ==
      this.utils.dateFormat(new Date())
    ) {
      this.readonly = true;
    } else {
      this.readonly = false;
    }

    this.caisseService.caisseDate1(
      this.utils.dateFormat(this.caisseService.date1)
    );
  }

  getCaisse2() {
    this.caisseService.caisseDate2(
      this.utils.dateFormat(this.caisseService.date1),
      this.utils.dateFormat(this.caisseService.date2)
    );
  }
  testDate2(date1, date2) {
    if (this.utils.dateFormat(date2) < this.utils.dateFormat(date1)) {
      this.utils.toastError('Date invalide');
    }
  }
}
