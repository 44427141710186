import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { ATCDService } from 'src/app/services/atcd.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-atcd-crud',
  templateUrl: './atcd-crud.component.html',
  styleUrls: ['./atcd-crud.component.scss'],
})
export class AtcdCrudComponent implements OnInit {
  constructor(
    public accessProviders: AccessProviders,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public atcdService: ATCDService
  ) {
    // IdATCD
    this.atcdService.patientID = this.navParams.get('id');
    this.atcdService.patient = this.navParams.get('patient');
  }

  ngOnInit() {
    if (this.atcdService.patientID !== 0) {
      this.atcdService.loadATCD();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }
}
