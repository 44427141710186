import { AppService } from './../../../services/app.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-detail-event',
  templateUrl: './detail-event.component.html',
  styleUrls: ['./detail-event.component.scss'],
})
export class DetailEventComponent implements OnInit {
  status: any;
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public utils: UtilsService,
    public appService: AppService
  ) {
    this.utils.checkPlatform();

    // Recupere event si existe
    this.appService.event = this.navParams.get('event');
  }

  ngOnInit() {
    this.appService.getStatus();
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }
}
