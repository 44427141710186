import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-add-scol',
  templateUrl: './add-scol.component.html',
  styleUrls: ['./add-scol.component.scss'],
})
export class AddScolComponent implements OnInit {
  choix;
  scol = '';
  constructor(
    private modalCtrl: ModalController,
    private storage: StorageService,
    public utils: UtilsService
  ) {}

  ngOnInit() {}

  async dismiss() {
    this.modalCtrl.dismiss(null, '');
  }

  onItemChange(event) {
    if (event.target.value == 1) {
      this.scol = 'scol';
    } else {
      this.scol = 'noScol';
    }
  }

  addScol(scol) {
    this.storage.setData('scol', scol);

    this.modalCtrl.dismiss(null, 'crud');
  }
}
