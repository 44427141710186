import { StorageService } from '../../../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, ModalController } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { PatientService } from 'src/app/services/patient.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-search-patient',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.scss'],
})
export class SearchPatientComponent implements OnInit {
  item = {
    icon: 'search-outline',
    title: 'Patient non trouvé',
  };
  searchInput = '';
  patients = [];
  isLoading: boolean;
  isEmpty: boolean;

  constructor(
    public utils: UtilsService,
    public accessProviders: AccessProviders,
    public platform: Platform,
    public router: Router,
    public patientService: PatientService,
    private modalCtrl: ModalController,
    private storage: StorageService
  ) {
    this.patientService.loadPatients();
    this.utils.checkPlatform();
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();

    this.patients = [];
    this.patientService._patients$.subscribe((patients: any[]) => {
      this.patients = patients;
    });

    this.patientService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });

    this.patientService._isEmpty$.subscribe((isEmpty: any) => {
      this.isEmpty = isEmpty;
    });
  }

  async ionViewDidEnter() {
    this.patientService.refreshData();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  addPatient(patient) {
    this.storage.setData('p_id', patient);

    this.modalCtrl.dismiss(null, 'close');
  }
}
