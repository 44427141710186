import { ConsultationService } from './../../../services/consultation.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';

@Component({
  selector: 'app-consult-prescripteur',
  templateUrl: './consult-prescripteur.component.html',
  styleUrls: ['./consult-prescripteur.component.scss'],
})
export class ConsultPrescripteurComponent implements OnInit {
  consultations = [];
  isLoading: boolean;
  nbCsDossier = 0;
  nbCsD = '';

  constructor(
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public consultationService: ConsultationService
  ) {
    this.utils.checkPlatform();
  }

  ngOnInit() {
    this.utils.getSession();

    this.consultationService._consultations$.subscribe((consultations: any) => {
      this.consultations = consultations;
    });

    this.consultationService._nbCsDossier$.subscribe((nbCsDossier: any) => {
      this.nbCsDossier = nbCsDossier;
    });

    this.consultationService._nbCsD$.subscribe((nbCsD: any) => {
      this.nbCsD = nbCsD;
    });

    this.consultationService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });
  }

  ionViewDidEnter() {
    this.consultationService.loadAllConsult();
    this.consultationService.nbConsultationDatePrescr(this.utils.currentDate);
  }

  dismiss() {
    this.modalCtrl.dismiss('', 'close');
  }
}
