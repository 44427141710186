import { UtilsService } from './utils.service';
import {
  AlertController,
  ModalController,
  LoadingController,
} from '@ionic/angular';
import { AccessProviders } from '../providers/access-providers.service';
import { Injectable, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CrudUserComponent } from '../components/utilisateurs/crud-user/crud-user.component';
import { Md5 } from 'ts-md5';
import { DetailUserComponent } from './../components/utilisateurs/detail-user/detail-user.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnInit {
  prescripteur: any;
  users: any = [];
  users$: any = []; // Doctors
  ps$: any = []; // Doctors
  nbUser$: number;
  filteredData = [];
  temp = [];
  momentjs: any = moment;

  id;
  // To API
  user = {
    name: '',
    username: '',
    password: '',
    email: '',
    compte: '',
    agentSante: '',
    medecin: '',
    onm: '',
  };

  _isLoading = new BehaviorSubject<boolean>(true);
  _isEmpty = new BehaviorSubject<boolean>(false);

  get _isLoading$() {
    return this._isLoading.asObservable();
  }

  get _isEmpty$() {
    return this._isEmpty.asObservable();
  }

  constructor(
    private accessProviders: AccessProviders,
    private alertCtrl: AlertController,
    public utils: UtilsService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController
  ) {
    this.utils.checkPlatform();
  }

  ////////// OBSERVABLES /////////////
  _users = new BehaviorSubject<any[]>([]);
  _ps = new BehaviorSubject<any[]>([]);
  _doctors = new BehaviorSubject<any[]>([]);
  _nbUser = new BehaviorSubject<number>(0);

  //////////// ASSECEURS ////////////
  get _users$() {
    return this._users.asObservable();
  }

  get _doctors$() {
    return this._doctors.asObservable();
  }

  get _ps$() {
    return this._ps.asObservable();
  }

  get _nbUser$() {
    return this._nbUser.asObservable();
  }
  //////////// ASSECEURS ////////////

  ngOnInit() {}

  refreshData() {
    this.users = [];
    this.loadUsers();
    this.loadDoctors();
  }
  async nbUser() {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_USER',
      };

      this.accessProviders.postData(body, 'user.php').subscribe((res: any) => {
        this.nbUser$ = res;
        this._nbUser.next(this.nbUser$);
      });
    });
  }
  clearInput() {
    this.user.name = '';
    this.user.username = '';
    this.user.password = '';
    this.user.email = '';
    this.user.compte = '';
    this.user.medecin = '';
    this.user.onm = '';
  }

  filterList(evt) {
    this._users.next(this.filteredData);
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._users.next(
      this.filteredData.filter((item: { name: string; username: string }) => {
        return (
          item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          item.username.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      })
    );
  }

  filterPS(evt) {
    this._ps.next(this.filteredData);
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._ps.next(
      this.filteredData.filter((item: { name: string }) => {
        return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      })
    );
  }

  async loadUsers() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL',
      };

      this.accessProviders.postData(body, 'user.php').subscribe((res: any) => {
        this.users = [];
        if (res.result == '') {
          this._isEmpty.next(true);
          this._isLoading.next(false);
        } else {
          this._isEmpty.next(false);
          for (const data of res.result) {
            this.users.push(data);
            this.filteredData = this.users;
            this._users.next(this.users);
            this._isLoading.next(false);
          }
          resolve(true);
        }
      });
    });
  }

  loadSingleUser() {
    return new Promise((_resolve) => {
      const body = {
        action: 'LOAD_SINGLE_USER',
        user_id: this.id,
      };

      this.accessProviders.postData(body, 'user.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.user.name = data.name;
          this.user.username = data.username;
          this.user.email = data.email;
          this.user.compte = data.compte;
          this.user.medecin = data.medecin;
          this.user.onm = data.ONM;
        }
      });
    });
  }

  async loadDoctors() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_DOCTOR',
      };

      this.accessProviders.postData(body, 'user.php').subscribe((res: any) => {
        this.users$ = [];
        for (const data of res.result) {
          this.users$.push(data);
          this.filteredData = this.users$;
          this._doctors.next(this.users$);
          this._isLoading.next(false);
        }
        resolve(true);
      });
    });
  }

  async loadPS() {
    this.ps$ = [];
    return new Promise((resolve) => {
      const body = {
        action: 'GET_PS',
      };

      this.accessProviders.postData(body, 'user.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.ps$.push(data);
          this._ps.next(this.ps$);
          this._isLoading.next(false);
        }
        resolve(true);
      });
    });
  }

  delUser(id) {
    return new Promise(async () => {
      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        message: 'Voulez-vous vraiment supprimer cet SECRETAIRE ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              const body = {
                action: 'DELETE',
                user_id: id,
              };
              this.accessProviders
                .postData(body, 'user.php')
                .subscribe((res: any) => {
                  if (res.success === true) {
                    this.utils.toast('Suppression éffectuée');
                    this.loadUsers();
                  } else {
                    this.utils.toastError('Erreur de suppression');
                  }
                });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });
      await alert.present();
    });
  }

  async openCrud(id) {
    const modal = await this.modalCtrl.create({
      component: CrudUserComponent,
      animated: true,
      mode: 'ios',
      componentProps: { id },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.loadUsers();
    }
  }

  async openDetail(user) {
    const modal = await this.modalCtrl.create({
      component: DetailUserComponent,
      animated: true,
      mode: 'ios',
      componentProps: { user },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
    });
    await modal.present();
  }

  async getPrescripteur(userID) {
    return new Promise((_resolve) => {
      const body = {
        action: 'GET_PRESCRIPTEUR',
        id: userID,
      };

      this.accessProviders.postData(body, 'user.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.prescripteur = data.name;
        }
        _resolve(true);
      });
    });
  }

  async crudAction(a) {
    if (a == 'CREATE' && this.user.name === '') {
      this.utils.toastError('Nom requis');
    } else if (this.user.username === '') {
      this.utils.toastError("Nom d'Utilisateur requis");
    } else if (a == 'CREATE' && this.user.password === '') {
      this.utils.toastError('Mot de passe requis');
    } else if (a == 'UPDATE' && this.user.password === '') {
      this.utils.toastError(
        'Vous devez ré-saisir votre Mot de passe ou le modifier'
      );
    } else if (this.user.compte === '') {
      this.utils.toastError('Vous devez selectionner un compte');
    } else if (
      this.user.compte === 'AGENT DE SANTE' &&
      this.user.medecin === ''
    ) {
      this.utils.toastError('Vous êtes médecin ou non');
    } else {
      const loader = await this.loadingCtrl.create({
        message: 'Veuillez patientez ...',
        spinner: 'bubbles',
      });
      await loader.present();

      return new Promise((_resolve) => {
        if (
          this.user.compte === 'AGENT DE SANTE' ||
          this.user.compte === 'ADMINISTRATEUR'
        ) {
          this.user.agentSante = '1';
        } else {
          this.user.agentSante = '0';
          this.user.medecin = '0';
          this.user.onm = '';
        }

        const body = {
          action: 'CRUD',
          id: this.id,
          name: this.user.name,
          username: this.user.username,
          email: this.user.email,
          password: Md5.hashStr(this.user.password),
          compte: this.user.compte,
          agentSante: this.user.agentSante,
          medecin: this.user.medecin,
          onm: this.user.onm,
          crudAct: a,
        };

        this.accessProviders.postData(body, 'user.php').subscribe(
          (res: any) => {
            if (res.success === true) {
              loader.dismiss();
              this.utils.toast(res.msg);
              this.modalCtrl.dismiss(null, 'crud');
            } else {
              loader.dismiss();
              this.utils.toastError(res.msg);
            }
          },
          (_err) => {
            console.log(_err);
            loader.dismiss();
            this.utils.presentAlert('SECRETAIRE', '');
          }
        );
      });
    }
  }
}
