import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageService } from './../../../../services/storage.service';

@Component({
  selector: 'app-dentisterie',
  templateUrl: './dentisterie.component.html',
  styleUrls: ['./dentisterie.component.scss'],
})
export class DentisterieComponent implements OnInit {
  readonly = true;
  activeIndex = -1;
  soins;

  acte;

  actes = [
    { title: 'Consultations' },
    { title: 'Extractions' },
    { title: 'Detartrage' },
    { title: 'Soins' },
    { title: 'Radiographie' },
  ];

  constructor(
    private modalCtrl: ModalController,
    private storage: StorageService
  ) {}

  ngOnInit() {}

  addActe(act) {
    this.acte = act;
    this.readonly = false;

    if (this.soins == null) {
      if (this.acte === 'Detartrage') {
        this.soins = this.acte;
      }
    } else {
      if (this.acte === 'Detartrage') {
        this.soins += '\n' + this.acte;
      }
    }
  }

  setIndex(index) {
    this.activeIndex = index;
  }

  onClick(d) {
    if (this.soins == null) {
      if (this.acte !== 'Detartrage') {
        this.soins = this.acte + ' : Dent N° ' + d;
      }
    } else {
      if (this.acte !== 'Detartrage') {
        this.soins += '\n' + this.acte + ' : Dent N° ' + d;
      }
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  addDentisterie() {
    this.storage.setData('dents', this.soins);

    this.modalCtrl.dismiss(null, 'crud');
  }
}
