import { Component, OnInit } from '@angular/core';
import { RMAService } from 'src/app/services/rma.service';

@Component({
  selector: 'app-maladie-chronique',
  templateUrl: './maladie-chronique.component.html',
  styleUrls: ['./maladie-chronique.component.scss'],
})
export class MaladieChroniqueComponent implements OnInit {
  constructor(public rmaService: RMAService) {}

  ngOnInit() {}
}
