import { Component, OnInit } from '@angular/core';
import {
  Platform,
  LoadingController,
  AlertController,
  ModalController,
  NavParams,
} from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements OnInit {
  passedId = null;
  patient: any;
  disabledButton = false;

  file = {
    date: '',
    libelle: '',
    commentaires: '',
  };

  nomFichier = '';

  reste: number;

  momentjs: any = moment;

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private utils: UtilsService,
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) {
    this.patient = this.navParams.get('patient');
    this.passedId = this.patient.id;
  }

  ngOnInit() {
    this.disabledButton = true;
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  formatDate(date) {
    return this.momentjs(date).format('YYYYMMDD');
  }

  testDate(date) {
    if (this.formatDate(date) > this.formatDate(new Date())) {
      this.utils.toastError('Date invalide');
    }
  }

  enableButton() {
    this.disabledButton = false;
  }

  getMax() {
    this.reste = 250 - this.file.commentaires.length;

    if (this.file.commentaires.length > 250) {
      this.presentAlertCaract('Caractères max atteints !');
    }
  }

  async presentAlertCaract(a) {
    const alert = await this.alertCtrl.create({
      header: a,
      backdropDismiss: false,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async saveNote() {
    if (this.file.date === '') {
      this.utils.toastError('Date obligatoire');
    } else if (this.file.libelle === '') {
      this.utils.toastError('Titre obligatoire');
    } else {
      const loader = await this.loadingCtrl.create({
        message: 'Veuillez patientez ...',
        spinner: 'bubbles',
      });
      await loader.present();

      return new Promise((_resolve) => {
        const body = {
          action: 'NOTE',
          patient_id: this.passedId,
          date: this.momentjs(this.file.date).format('YYYYMMDD'),
          libelle: this.file.libelle,
          nomFichier: this.nomFichier,
          commentaires: this.file.commentaires,
          prescripteur_id: this.utils.userId,
        };

        this.accessProviders.postData(body, 'fichier.php').subscribe(
          (res: any) => {
            if (res.success === true) {
              loader.dismiss();
              this.utils.toast(
                res.msg + ' ... mise à jour de données en cours ...'
              );
              this.modalCtrl.dismiss(null, 'crud');
            } else {
              loader.dismiss();
              this.utils.alert('Focus GCM', res.msg, this.saveNote());
            }
          },
          (_err) => {
            loader.dismiss();
            this.dismiss();
            // this.presentAlert('Timeout');
          }
        );
      });
    }
  }
}
