import { SearchDoctorComponent } from '../../utilisateurs/search-doctor/search-doctor.component';
import { WaitService } from './../../../services/wait.service';
import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { AppService } from 'src/app/services/app.service';
import { PatientService } from 'src/app/services/patient.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { StorageService } from 'src/app/services/storage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-add-wait',
  templateUrl: './add-wait.component.html',
  styleUrls: ['./add-wait.component.scss'],
})
export class AddWaitComponent implements OnInit {
  patient;
  doc: string;
  momentjs: any = moment;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public patientService: PatientService,
    public userService: UserService,
    public appService: AppService,
    public waitService: WaitService,
    private storage: StorageService
  ) {
    this.patient = this.navParams.get('patient');
    this.utils.checkPlatform();
  }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  async openSearch() {
    const modal = await this.modalCtrl.create({
      component: SearchDoctorComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop
        ? 'search-modal-web'
        : 'search-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.storage.getData('d_id').then((d: any) => {
        this.doc = d.name;
        this.waitService.wait.doc_id = d.id;
      });
    }
  }
}
