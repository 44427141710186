import {
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { NgxImageCompressService } from 'ngx-image-compress';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AccessProviders } from './providers/access-providers.service';
import { ReactiveFormsModule } from '@angular/forms';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SharedComponentsModule } from './components/shared-components.module';
import { NgxPaginationModule } from 'ngx-pagination';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CKEditorModule } from 'ng2-ckeditor';
import { QuillModule, QuillModules } from 'ngx-quill';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { environment } from 'src/environments/environment.prod';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { QuicklinkModule } from 'ngx-quicklink';
import { NgxEchartsModule } from 'ngx-echarts';
import { Keyboard } from '@ionic-native/keyboard/ngx';

const modules: QuillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'], // remove formatting button

    ['link', 'image', 'video'], // link and image, video
  ],
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    QuicklinkModule,
    IonCustomScrollbarModule,
    PdfViewerModule,
    CKEditorModule,
    BrowserModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    QuillModule.forRoot({
      modules,
      placeholder: '',
    }),
    environment.production
      ? ServiceWorkerModule.register('/ngsw-worker.js', {
          enabled: environment.production,
          registrationStrategy: 'registerWhenStable:5000',
        })
      : [],

    SharedComponentsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.cubeGrid,
      backdropBackgroundColour: 'rgba(255,255,255,255)',
      backdropBorderRadius: '4px',
      primaryColour: '#3880ff',
      secondaryColour: '#3880ff',
      tertiaryColour: '#3880ff',
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    SwUpdate,
    Keyboard,
    StatusBar,
    AccessProviders,
    FileOpener,
    File,
    FileTransfer,
    DocumentViewer,
    PreviewAnyFile,
    InAppBrowser,
    NgxImageCompressService,
    NavParams,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr-CA' },
    { provide: LocationStrategy, useClass: HashLocationStrategy }, // Pour eviter une erreur en rafraississant la page
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
