import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-search-doctor',
  templateUrl: './search-doctor.component.html',
  styleUrls: ['./search-doctor.component.scss'],
})
export class SearchDoctorComponent implements OnInit {
  ps = [];
  isLoading: boolean;

  constructor(
    public utils: UtilsService,
    private modalCtrl: ModalController,
    private storage: StorageService,
    public userService: UserService
  ) {
    this.userService.loadPS();
    this.utils.checkPlatform();
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();

    this.userService._ps$.subscribe((ps: any[]) => {
      this.ps = ps;
    });

    this.userService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });
  }

  async ionViewDidEnter() {
    this.userService.refreshData();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  addUser(user) {
    this.storage.setData('d_id', user);

    this.modalCtrl.dismiss(null, 'close');
  }
}
