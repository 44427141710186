import { NavParams, ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-medoc',
  templateUrl: './detail-medoc.component.html',
  styleUrls: ['./detail-medoc.component.scss'],
})
export class DetailMedocComponent implements OnInit {
  medoc;
  passedId;
  constructor(
    public utils: UtilsService,
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {     
    utils.checkPlatform();
    this.medoc = this.navParams.get('medoc');
    this.passedId = this.medoc.id;
  }

  ngOnInit() { }
  
  dismiss() {
    this.modalCtrl.dismiss();
  }

}
