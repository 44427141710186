import { Component, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { ProductService } from 'src/app/services/product.service';
import { UtilsService } from './../../../services/utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-check-product',
  templateUrl: './check-product.component.html',
  styleUrls: ['./check-product.component.scss'],
})
export class CheckProductComponent implements OnInit {
  date;
  nextMonth;
  today = new Date();
  produits = [];

  constructor(
    public utils: UtilsService,
    public productService: ProductService,
    private router: Router
  ) {
    this.productService.getProduits();
  }

  ngOnInit() {
    this.date = format(Date.now(), 'MM/yyyy');

    this.nextMonth = new Date(
      this.today.getFullYear(),
      this.today.getMonth() + 1
    );

    this.nextMonth = format(this.nextMonth, 'MM/yyyy');

    this.productService._produits$.subscribe((produits: any[]) => {
      this.produits = produits;
    });
  }

  sliceMonth(str) {
    var subString = str.substr(0, 2);
    return subString;
  }

  sliceYear(str) {
    var subString = str.slice(3, str.length);
    return subString;
  }

  toProduct() {
    this.router.navigateByUrl('/stock');
  }
}
