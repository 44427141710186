import { ProductService } from './../../../services/product.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-entree-stock',
  templateUrl: './entree-stock.component.html',
  styleUrls: ['./entree-stock.component.scss'],
})
export class EntreeStockComponent implements OnInit {
  item = {
    icon: 'search-outline',
    title: 'Produit non trouvé',
  };

  searchInput;
  entreeStocks = [];

  isUpStockEmpty: boolean;
  isLoading: boolean;

  constructor(
    public utils: UtilsService,
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    public platform: Platform,
    public router: Router,
    public productService: ProductService
  ) {
    this.productService.loadEntreeStocks();
    this.utils.checkPlatform();
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();

    this.productService._entreeStock$.subscribe((entreeStocks: any[]) => {
      this.entreeStocks = entreeStocks;
    });

    this.productService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });

    this.productService._isUpStockEmpty$.subscribe((isUpStockEmpty: any) => {
      this.isUpStockEmpty = isUpStockEmpty;
    });
  }

  async ionViewDidEnter() {
    this.refreshData();
  }

  refreshData() {
    this.productService.loadEntreeStocks();
  }

  search(ev) {
    this.searchInput = ev.target.value;
  }
}
