import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  activeIndex = 0;
  isDesktop = true;
  segmentModel = 'consult';

  constructor(public utils: UtilsService) {
    this.utils.checkPlatform();
  }

  ngOnInit() {}

  items = [
    { title: 'Consultations' },
    { title: 'Actes' },
    { title: 'Autres' },
    { title: 'Produits' },
  ];

  setIndex(index) {
    this.activeIndex = index;
  }

  segmentChanged(e) {}
}
