import { Component, OnInit } from '@angular/core';
import { RMAService } from 'src/app/services/rma.service';

@Component({
  selector: 'app-cpn',
  templateUrl: './cpn.component.html',
  styleUrls: ['./cpn.component.scss'],
})
export class CpnComponent implements OnInit {
  constructor(public rmaService: RMAService) {
    this.rmaService.getSUM_CPN(new Date());
  }

  ngOnInit() {}
}
