import { ConsultationService } from './../../../services/consultation.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit } from '@angular/core';

// PDFMake
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ModalController } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-current-consult',
  templateUrl: './current-consult.component.html',
  styleUrls: ['./current-consult.component.scss'],
})
export class CurrentConsultComponent implements OnInit {
  consultations = [];
  isLoading: boolean;
  nbCsDossier = 0;
  nbCsD = '';

  constructor(
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public consultationService: ConsultationService
  ) {
    this.utils.checkPlatform();
  }

  ngOnInit() {
    this.consultationService.getAllConsult(this.utils.currentDate);

    this.consultationService._consultations$.subscribe((consultations: any) => {
      this.consultations = consultations;
    });

    this.consultationService._nbCsDossier$.subscribe((nbCsDossier: any) => {
      this.nbCsDossier = nbCsDossier;
    });

    this.consultationService._nbCsD$.subscribe((nbCsD: any) => {
      this.nbCsD = nbCsD;
    });

    this.consultationService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
