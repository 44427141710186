import { FilesService } from '../../../../../services/files.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import {
  Platform,
  LoadingController,
  AlertController,
  ModalController,
  NavParams,
} from '@ionic/angular';
import { NgxImageCompressService } from 'ngx-image-compress';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UploadService } from 'src/app/services/upload.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-camera-file',
  templateUrl: './camera-file.component.html',
  styleUrls: ['./camera-file.component.scss'],
})
export class CameraFileComponent implements OnInit {
  constructor(
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public utils: UtilsService,
    public fileService: FilesService
  ) {
    utils.checkPlatform();

    this.fileService.patient = this.navParams.get('patient');
    this.fileService.passedId = this.fileService.patient.id;
    // this.photo = 'C:/Users/Arison Rakoto/Documents/ionic/medmanager/src/assets/placeholder.jpg';
  }

  ngOnInit() {
    this.utils.getSession();
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  testDate(date) {
    if (this.utils.dateFormat(date) > this.utils.dateFormat(new Date())) {
      this.utils.toastError('Date invalide');
    }
  }
}
