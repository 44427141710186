import { UserService } from './../../../services/user.service';
import { ConsultationService } from './../../../services/consultation.service';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-detail-consult',
  templateUrl: './detail-consult.component.html',
  styleUrls: ['./detail-consult.component.scss'],
})
export class DetailConsultComponent implements OnInit {
  showPrescr: boolean;

  constructor(
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public consultationService: ConsultationService,
    public utils: UtilsService,
    public userService: UserService
  ) {
    this.consultationService.consult = this.navParams.get('consult');
    this.showPrescr = this.navParams.get('showPrescr');
  }

  ngOnInit() {
    this.utils.getSession();
    // this.prescripteur = '';
    this.userService.getPrescripteur(
      this.consultationService.consult.prescripteur_id
    );
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
