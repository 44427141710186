import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { ExamenService } from 'src/app/services/examen.service';
import { PatientService } from 'src/app/services/patient.service';
import { RMAService } from 'src/app/services/rma.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DentisterieComponent } from '../../crud/dentisterie/dentisterie.component';
import { OphtalmoComponent } from '../../crud/ophtalmo/ophtalmo.component';
import { ParamGenComponent } from '../../crud/param-gen/param-gen.component';
import { ProtocoleComponent } from '../../protocole/protocole.component';
import { ResultatRdtComponent } from './../../crud/resultat-rdt/resultat-rdt.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss'],
})
export class ConsultationComponent implements OnInit {
  readonly = true;

  choix = 1;
  activeIndex = 0;
  fievre;
  rdt;
  rdtp;
  rdtn;
  disabledRDT = true; // RDT

  disabledF = false;
  disabledSGD = false; // SGD
  disabledConseil = false;
  disabledSRO = false;
  disabledAmox = false;
  users$ = [];

  constructor(
    public accessProviders: AccessProviders,
    public utils: UtilsService,
    private modalCtrl: ModalController,
    public examen: ExamenService,
    private storage: StorageService,
    public patientService: PatientService,
    private alertCtrl: AlertController,
    public userService: UserService,
    private rmaService: RMAService
  ) {
    this.storage.getData('p_id').then((p: any) => {
      this.patientService.patient = p;
      this.patientService.IdPatient = p.id;
      this.examen.patient = p;
      this.examen.IdPatient = p.id;
      this.examen.patient.sexe = p.sexe;
    });

    this.newConsult();
  }

  ngOnInit() {
    this.storage.removeData('protocole');
    this.examen.clearInput();
    this.userService.loadPS();

    this.userService._ps$.subscribe((users: any[]) => {
      this.users$ = users;
    });
  }

  ionViewDidLeave() {
    this.examen.clearInput();
  }

  addConsult() {
    if (this.examen.isControle) {
      this.examen.crudConsult('CREATE');
      this.rmaService.addRMA_CTR(this.examen.dateConsult);
    } else {
      this.examen.crudConsult('CREATE');
    }
  }

  buttons = [{ title: 'Nouvelle consultation' }, { title: 'Controle' }];
  buttonCS = [
    { title: 'Générale/Spécialisée' },
    { title: 'Dentisterie' },
    { title: 'Ophtalmologie' },
  ];

  async eventCheckFievre(event) {
    if (event.target.checked == true) {
      const alert = await this.alertCtrl.create({
        header: 'Focus GCM',
        message:
          "Voulez-vous ajouter : 'Maladies fébriles toutes causes confondues' dans la liste de  Diagnostic ?",
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'Oui',
            handler: () => {
              this.disabledRDT = false;
              this.disabledF = true;

              this.examen.addRMA_AUTRE(76);

              this.examen.addRMA_PALU(1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
            },
          },
          {
            text: 'Non',
            role: 'cancel',
            handler: () => {
              event.target.checked = false;
              this.disabledRDT = true;
              this.disabledF = false;
            },
          },
        ],
      });

      await alert.present();
    }
  }

  async eventCheckRDT(event) {
    if ((event.target.checked = true)) {
      this.examen.addRMA_PALU(0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
      this.disabledRDT = true;

      this.openRDT();
    }
  }

  async openRDT() {
    const modal = await this.modalCtrl.create({
      component: ResultatRdtComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win1-detail-web' : 'win1-detail-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
    }
  }

  async eventCheckAmox(event) {
    if ((event.target.checked = true)) {
      const alert = await this.alertCtrl.create({
        header: 'Focus GCM',
        message:
          "Voulez-vous ajouter : 'Pneumonie correctement traitée par Amoxi dans PCIME' ?",
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'Oui',
            handler: () => {
              this.disabledAmox = true;

              this.rmaService.addRMA_PCIME(
                this.examen.dateConsult,
                0,
                0,
                1,
                1,
                0
              );
            },
          },
          {
            text: 'Non',
            role: 'cancel',
            handler: () => {
              event.target.checked = false;
              this.disabledAmox = false;
            },
          },
        ],
      });

      await alert.present();
    }
  }

  async eventCheckSRO(event) {
    if ((event.target.checked = true)) {
      const alert = await this.alertCtrl.create({
        header: 'Focus GCM',
        message:
          "Voulez-vous ajouter : 'Diarrhée correctement traitée par Zinc/SRO dans PCIME' ?",
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'Oui',
            handler: () => {
              this.disabledSRO = true;

              this.rmaService.addRMA_PCIME(
                this.examen.dateConsult,
                1,
                1,
                0,
                0,
                0
              );
            },
          },
          {
            text: 'Non',
            role: 'cancel',
            handler: () => {
              event.target.checked = false;
              this.disabledSRO = false;
            },
          },
        ],
      });

      await alert.present();
    }
  }

  async eventCheckSGD(event) {
    if ((event.target.checked = true)) {
      const alert = await this.alertCtrl.create({
        header: 'Focus GCM',
        message:
          "Voulez-vous ajouter : 'Signes généraux de danger' dans la liste de  Diagnostic ?",
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'Oui',
            handler: () => {
              this.disabledSGD = true;

              this.examen.addRMA_AUTRE(75);
            },
          },
          {
            text: 'Non',
            role: 'cancel',
            handler: () => {
              event.target.checked = false;
              this.disabledSGD = false;
            },
          },
        ],
      });

      await alert.present();
    }
  }

  async eventCheckConseil(event) {
    if ((event.target.checked = true)) {
      const alert = await this.alertCtrl.create({
        header: 'Focus GCM',
        message:
          "Voulez-vous ajouter : 'Conseils Alimentation de l'enfant dans PCIME' ?",
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'Oui',
            handler: () => {
              this.disabledConseil = true;

              this.rmaService.addRMA_PCIME(
                this.examen.dateConsult,
                0,
                0,
                0,
                0,
                1
              );
            },
          },
          {
            text: 'Non',
            role: 'cancel',
            handler: () => {
              event.target.checked = false;
              this.disabledConseil = false;
            },
          },
        ],
      });

      await alert.present();
    }
  }

  getData() {
    this.storage.getData('protocole').then((p: any) => {
      this.examen.motif = p.libelle;
      this.examen.clinique += p.clinique;
      this.examen.paraclinique = p.paraclinique;
      this.examen.CAT = p.cat;
    });
  }

  async openDT() {
    const modal = await this.modalCtrl.create({
      component: DentisterieComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win14-modal-web' : 'win14-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.storage.getData('dents').then((d: any) => {
        this.examen.motif = 'Dentisterie';
        this.examen.readonly = false;
        this.examen.motif = 'Dentisterie';
        this.examen.clinique += d + '\n';
      });
    }
  }

  async openParam() {
    const modal = await this.modalCtrl.create({
      component: ParamGenComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win1-detail-web' : 'win1-detail-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.storage.getData('paramG').then((param: any) => {
        console.log(param);

        if (param.TAG !== '') {
          this.examen.clinique = '- TAG = ' + param.TAG + '\n';
        }
        if (param.TAD !== '') {
          this.examen.clinique += '- TAD = ' + param.TAD + '\n';
        }
        if (param.FC !== '') {
          this.examen.clinique += '- FC = ' + param.FC + ' bpm' + '\n';
        }
        if (param.temp !== '') {
          this.examen.clinique += '- T° = ' + param.temp + ' °C' + '\n';
        }
        if (param.Poids !== '') {
          this.examen.clinique += '- Poids = ' + param.poids + ' Kg' + '\n';
        }
        if (param.Taille !== '') {
          this.examen.clinique += '- Taille = ' + param.taille + ' m' + '\n';
        }
        if (param.autre !== '') {
          this.examen.clinique += '- ' + param.autre + '\n';
        }
      });
    }
  }

  async openOPHT() {
    const modal = await this.modalCtrl.create({
      component: OphtalmoComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win13-modal-web' : 'win13-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.storage.getData('ophtalmo').then((opht: any) => {
        this.examen.motif = 'Ophtalmologie';
        if (opht.avod1 !== '') {
          this.examen.clinique = 'Acuité visuelle sans correction' + '\n';
          this.examen.clinique += '- OD = ' + opht.avod1 + '/10\n';
        }

        if (opht.avog1 !== '') {
          this.examen.clinique += '- OG = ' + opht.avog1 + '/10\n';
        }

        // OD
        if (opht.axeOD !== '') {
          this.examen.clinique += '\nCorrection' + '\n';
          this.examen.clinique += '- OD = (' + opht.axeOD;
        }

        if (opht.cylOD !== '') {
          this.examen.clinique += opht.cylOD + ')';
        }

        if (opht.axeOD === '' && opht.sphOD !== '') {
          this.examen.clinique += '\nCorrection' + '\n';
          this.examen.clinique += '- OD = ' + opht.sphOD;
        }

        // OD Afocal
        if (opht.axeOD === '' && opht.cylOD === '' && opht.sphOD === '') {
          this.examen.clinique += '\nCorrection' + '\n';
          this.examen.clinique += '- OD = AFOCAL';
        }
        if (opht.avod2 !== '') {
          this.examen.clinique += ' - AV = ' + opht.avod2 + '/10\n';
        }

        // OG
        if (opht.axeOG !== '') {
          this.examen.clinique += '- OG = (' + opht.axeOG;
        }

        if (opht.cylOG !== '') {
          this.examen.clinique += opht.cylOG + ')';
        }

        if (opht.axeOG === '' && opht.sphOG !== '') {
          this.examen.clinique += '- OG = ' + opht.sphOG;
        }

        // OG Afocal
        if (opht.axeOG === '' && opht.cylOG === '' && opht.sphOG === '') {
          this.examen.clinique += '- OG = AFOCAL';
        }
        if (opht.avog2 !== '') {
          this.examen.clinique += ' - AV = ' + opht.avog2 + '/10\n';
        }

        if (opht.p !== '') {
          this.examen.clinique += '- Parinaud = ' + opht.p + '\n';
        }

        if (opht.add !== '') {
          this.examen.clinique += '- Additif = ' + opht.add + '\n';
        }

        if (opht.note !== '') {
          this.examen.clinique += '\nNotes : ' + opht.note + '\n';
        }
      });
    }
  }

  async openProtocole() {
    const modal = await this.modalCtrl.create({
      component: ProtocoleComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.getData();
    }
  }

  newConsult() {
    this.examen.dateConsult = '';
    this.examen.noDossier = '';
    this.examen.csID = 0; // IdConsultation
    this.examen.isControle = false;
    this.examen.isUpdate = false;
    this.examen.diagnostic = '';
  }

  checkCTR() {
    // CTR
    if (this.patientService.nbDossierPatient != 0) {
      this.examen.getDossier();
      this.examen.dateConsult = '';
      this.examen.csID = 0; // IdConsultation
      this.examen.isControle = true;
      this.examen.isUpdate = false;
      this.examen.motif = 'Controle';
      this.examen.diagnostic = '-';
    } else {
      this.utils.toastError(
        "Le patient n'a pas encore un dossier.\nVous ne pouvez pas effectuer cette opération"
      );
    }
  }

  onItemChange(index) {
    this.activeIndex = index;
    if (index == 0) {
      this.newConsult();
    } else {
      this.checkCTR();
    }
  }
}
