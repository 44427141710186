import { Component, OnInit } from '@angular/core';
import { CaisseService } from 'src/app/services/caisse.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-caisse-jour',
  templateUrl: './caisse-jour.component.html',
  styleUrls: ['./caisse-jour.component.scss'],
})
export class CaisseJourComponent implements OnInit {
  totalG = 0;
  date;
  constructor(public caisseService: CaisseService, public utils: UtilsService) {
    this.date = new Date();
  }

  ngOnInit() {
    this.caisseService._totalG$.subscribe((totalG: any) => {
      this.totalG = totalG;
    });
  }

  caisse(date) {
    this.caisseService.caisseJour(this.utils.formatDate(date));
    this.caisseService.loadCE(this.utils.formatDate(date));
    this.caisseService.loadCS(this.utils.formatDate(date));

    this.caisseService._totalG$.subscribe((totalG: any) => {
      this.totalG = totalG;
    });
  }

  prev() {
    this.date = new Date(+this.date - 1 * 86400000);
    this.caisse(this.date);
  }

  today() {
    this.date = new Date();
    this.caisse(this.date);
  }

  next() {
    this.date = new Date(+this.date + 1 * 86400000);
    this.caisse(this.date);
  }
}
