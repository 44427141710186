import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { PosologieComponent } from 'src/app/components/medoc/posologie/posologie.component';
import { AccessProviders } from 'src/app/providers/access-providers.service';

import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';

// PDFMake
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-ordonnance-table',
  templateUrl: './ordonnance-table.component.html',
  styleUrls: ['./ordonnance-table.component.scss'],
})
export class OrdonnanceTableComponent implements OnInit {
  passedId = null;
  ordonnances: any = [];
  dateOrdo: any;
  isDesktop = true;
  selected = [];
  momentjs: any = moment;
  ordonnance = {
    date: '',
  };

  // PDFMake
  pdfObj = null;
  consultations: any = [];
  atcds: any = [];
  nom: string;

  // Recupère le compte : Administrateur ou SECRETAIRE
  name: string;
  compte: string;

  constructor(
    public accessProviders: AccessProviders,
    public platform: Platform,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private modalCtrl: ModalController,
    private fileOpener: FileOpener,
    private file: File
  ) {
    // this.activatedRoute.queryParams.subscribe(_params => {
    //   if (this.router.getCurrentNavigation().extras.state) {
    //     this.passedId = this.router.getCurrentNavigation().extras.state.id;
    //   }
    // });

    this.passedId = 1;

    this.checkPlatform();
  }

  checkPlatform() {
    this.platform.ready().then(() => {
      if (
        this.platform.is('mobileweb') ||
        this.platform.is('android') ||
        this.platform.is('ios')
      ) {
        this.isDesktop = false;
      } else {
        this.isDesktop = true;
      }
    });
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.getSession();
  }

  getSession() {
    this.name = localStorage.getItem('name');
    this.compte = localStorage.getItem('compte');
  }

  onClear(ev) {
    // Reset the field
    ev.target.value = '';
  }

  async loadOrdo(date) {
    this.ordonnances = [];
    let dateOrdo = this.momentjs(date).format('YYYYMMDD');

    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL',
        dateOrdo: dateOrdo,
        patient_id: this.passedId,
      };

      this.accessProviders
        .postData(body, 'ordonnance.php')
        .subscribe((res: any) => {
          for (const data of res.result) {
            this.ordonnances.push(data);
          }
          resolve(true);
        });
    });
  }

  onActivate(event) {
    if (event.type == 'click') {
    }
  }

  async openPosologie(medoc) {
    const modal = await this.modalCtrl.create({
      component: PosologieComponent,
      animated: true,
      mode: 'ios',
      componentProps: { medoc: medoc },
      backdropDismiss: true,
      cssClass: this.isDesktop ? 'win2-modal-web' : 'win2-modal-mobile',
    });
    await modal.present();
  }

  printOrdo(date) {
    this.loadOrdo(date);

    var dd = {
      pageSize: 'A4',
      watermark: {
        text: 'Focus GCM',
        color: 'grey',
        opacity: 0.1,
        bold: true,
        italics: false,
      },
      content: [],
      styles: {
        f18: {
          fontSize: 16,
          margin: [0, 10, 0, 10],
        },
        strong: {
          bold: true,
        },
        title: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
        content: {
          fontSize: 10,
          margin: [0, 10, 0, 1],
        },
        header: {
          fontSize: 8,
          margin: [0, 5, 0, 0],
          color: 'grey',
        },
      },
    };

    // Informations du Patient
    // this.nom = this.patient.nom + ' ' + this.patient.prenom;
    dd.content.push({
      columns: [
        { text: 'Date : ', bold: true },
        {
          text: date,
          width: '20%',
          alignment: 'right',
        },
      ],
      style: 'title',
    });
    dd.content.push('  ');
    dd.content.push({
      text: 'ORDONNANCE',
      alignment: 'center',
      style: ['f18', 'strong'],
    });
    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });
    dd.content.push({
      columns: [
        { text: 'Nom : ', bold: true },
        {
          text: date,
          width: '90%',
        },
      ],
      style: 'title',
    });
    dd.content.push({
      canvas: [
        { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5 },
      ],
    });
    dd.content.push('  ');

    // Toutes ses ordonnances
    for (var i = 0; i < this.ordonnances.length; i++) {
      dd.content.push({
        columns: [{ text: this.ordonnances[i].medoc, width: '100%' }],
        style: 'content',
      });
      dd.content.push({
        columns: [{ text: this.ordonnances[i].posologie, width: '100%' }],
        style: 'content',
      });

      dd.content.push('  ');
    }

    this.pdfObj = pdfMake.createPdf(dd); //create pdf

    if (this.platform.is('cordova')) {
      this.pdfObj.getBuffer((buffer) => {
        var blob = new Blob([buffer], { type: 'application/pdf' });

        // Save the PDF to the data Directory of our App
        this.file
          .writeFile(this.file.dataDirectory, this.nom + '.pdf', blob, {
            replace: true,
          })
          .then(() => {
            // Open the PDf with the correct OS tools
            this.fileOpener.open(
              this.file.dataDirectory + this.nom + '.pdf',
              'application/pdf'
            );
          });
      });
    } else {
      // On a browser simply use download!
      this.pdfObj.download(this.nom);
    }
  }
  delete(ordo) {}
}
