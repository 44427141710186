import { UrlService } from './../services/url.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, finalize, map, timeout } from 'rxjs/operators';
import { UtilsService } from 'src/app/services/utils.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AccessProviders {
  constructor(
    public http: HttpClient,
    public urlService: UrlService,
    private utils: UtilsService,
    private loadingCtrl: LoadingController
  ) {}

  public items: any;

  postData(body, file) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    });

    const options = {
      headers: headers,
    };

    const server = `${this.urlService.server}api/`;
    // const server = `${environment.server}api/`;

    return (
      this.http
        .post(server + file, JSON.stringify(body), options)
        // .timeout(10000) // 10 sec
        .pipe(
          timeout(10000), // 10 sec
          map((response: any) => {
            // Success...
            return response;
          })
          // catchError((error) => {
          //   // Error...
          //   // Timeout over also handled here
          //   // I want to return an error for timeout
          //   return throwError(error || 'Timeout Exception');
          // }),
          // finalize(() => {
          //   console.log('Request it is over');
          //   this.loadingCtrl.dismiss();
          //   // this.utils.toastError('Erreur de liaison avec les données');
          // })
        )
    );
  }

  getData(getpathURL: string) {
    const server = `${this.urlService.server}api/`;
    // const server = `${environment.server}api/`;

    return this.http.get(server + getpathURL);
  }
}
