import { UtilsService } from './../../../services/utils.service';
import { CaisseService } from 'src/app/services/caisse.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CaisseDetailComponent } from '../caisse-detail/caisse-detail.component';

@Component({
  selector: 'app-caisse-entree',
  templateUrl: './caisse-entree.component.html',
  styleUrls: ['./caisse-entree.component.scss'],
})
export class CaisseEntreeComponent implements OnInit {
  caisseEntrees = [];
  totalRec = 0;
  totalCS = 0;
  totalActe = 0;
  totalMedoc = 0;
  totalAutre = 0;

  constructor(
    public caisseService: CaisseService,
    public utils: UtilsService,
    private modalCtrl: ModalController
  ) {
    this.caisseService.loadCE(this.utils.currentDate);
    this.caisseService.caisseJour(this.utils.currentDate);
  }

  ngOnInit() {
    this.caisseService._caisseEntrees$.subscribe((entrees: any[]) => {
      this.caisseEntrees = entrees;
    });

    this.caisseService._totalRecette$.subscribe((totalRec: any) => {
      this.totalRec = totalRec;
    });

    this.caisseService._totalCS$.subscribe((totalCS: any) => {
      this.totalCS = totalCS;
    });

    this.caisseService._totalActe$.subscribe((totalActe: any) => {
      this.totalActe = totalActe;
    });

    this.caisseService._totalMedoc$.subscribe((totalMedoc: any) => {
      this.totalMedoc = totalMedoc;
    });

    this.caisseService._totalAutre$.subscribe((totalAutre: any) => {
      this.totalAutre = totalAutre;
    });
  }

  async openDetail(ce) {
    const modal = await this.modalCtrl.create({
      component: CaisseDetailComponent,
      animated: true,
      mode: 'ios',
      componentProps: { detail: ce },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win5-modal-web' : 'win5-modal-mobile',
    });

    await modal.present();
  }
}
