import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { ExamenService } from 'src/app/services/examen.service';
import { PatientService } from 'src/app/services/patient.service';
import { RMAService } from 'src/app/services/rma.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SearchDiagComponent } from '../search-diag/search-diag.component';
import { Consultation } from './../../../../models/consultation.model';

@Component({
  selector: 'app-consultation-update',
  templateUrl: './consultation-update.component.html',
  styleUrls: ['./consultation-update.component.scss'],
})
export class ConsultationUpdateComponent implements OnInit {
  isVisible = false;
  chevron = 'chevron-forward-outline';
  consultation;
  diag;
  readonly = true;
  age;

  choix = 1;

  isControle = false;

  constructor(
    public accessProviders: AccessProviders,
    public utils: UtilsService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public examen: ExamenService,
    private storage: StorageService,
    private rmaService: RMAService,
    public patientService: PatientService
  ) {
    this.consultation = this.navParams.get('consultation');
    this.examen.csID = this.consultation.cID;
    this.examen.patient = this.navParams.get('patient');
    this.examen.idDossier = this.consultation.dID;
    this.examen.isUpdate = true;

    console.log(this.examen.idDossier);
  }

  ngOnInit() {
    this.examen.loadSingleConsultation();
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  getData() {
    this.storage.getData('p_id').then((p: any) => {
      this.patientService.patient = p;
      this.patientService.IdPatient = p.id;

      if (this.isControle == false) {
        this.examen.csID = 0; // IdConsultation
        this.examen.patient = p;
        this.examen.IdPatient = p.id;
        this.examen.isControle = false;
        this.examen.isUpdate = false;
      } else {
        if (this.patientService.nbDossierPatient != 0) {
          this.examen.csID = 0; // IdConsultation
          this.examen.patient = p;
          this.examen.IdPatient = p.id;
          this.examen.isControle = true;
          this.examen.isUpdate = false;
        } else {
          this.utils.toastError(
            "Le patient n'a pas encore un dossier.\nVous ne pouvez pas effectuer cette opération"
          );
        }
      }
    });
  }

  write() {
    if (this.examen.dateConsult != '') {
      this.readonly = false;
    }
  }

  onItemChange(event) {
    this.getData();

    if (event.target.value == 1) {
      this.isControle = false;
    } else {
      this.isControle = true;
    }
  }

  collapse() {
    this.isVisible = !this.isVisible;
    if (this.isVisible == true) {
      this.chevron = 'chevron-down-outline';
    } else {
      this.chevron = 'chevron-forward-outline';
    }
  }

  async openDiag() {
    const modal = await this.modalCtrl.create({
      component: SearchDiagComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop
        ? 'search-modal-web'
        : 'search-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.storage.getData('diag').then((d: any) => {
        this.diag = d.libelle;

        if (d.id <= 76 && d.id >= 1) {
          this.rmaService.addRMA_NC(
            this.examen.patient.sexe,
            this.age,
            d.id,
            this.examen.dateConsult
          );
        } else if (d.id <= 79 && d.id >= 77) {
          this.rmaService.addRMA_MC(
            this.examen.patient.sexe,
            this.age,
            d.id,
            this.examen.dateConsult
          );
        } else if (d.id <= 86 && d.id >= 80) {
          this.rmaService.addRMA_TRAUMA(
            this.examen.patient.sexe,
            this.age,
            d.id,
            this.examen.dateConsult
          );
        }

        if (d.id === '66') {
          // Autres nouveaux cas de maladies non épidémiques ...
          this.readonly = false;
          this.examen.diagnostic += '- ';
        } else {
          this.readonly = true;
          this.examen.diagnostic += '- ' + d.libelle + '\n';
        }
      });
    }
  }
}
