import { Component, OnInit } from '@angular/core';
import {
  LoadingController,
  ModalController,
  NavParams,
  Platform,
} from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ProductService } from './../../../services/product.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
  id: number;
  lastId: number;
  momentjs: any = moment;

  produit = {
    refProd: '',
    libProd: '',
    description: '',
    datePeremp: '',
    unite: '',
    prixVente: '',
    categorie: '',
    qteAlerte: '',
    qteDep: '',
  };

  isEdit = false;

  // Recupère le compte : Administrateur ou SECRETAIRE
  name: string;

  constructor(
    public utils: UtilsService,
    private loadingCtrl: LoadingController,
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public productService: ProductService
  ) {
    // Recupere ID si existe
    this.id = this.navParams.get('id');

    utils.checkPlatform();
  }

  ngOnInit() {
    this.productService.loadCategories();

    if (this.id !== 0) {
      this.loadSingleProduit();
    }
  }

  ionViewDidEnter() {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.getSession();
  }

  getSession() {
    this.name = localStorage.getItem('name');
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  formatDate(date) {
    return this.momentjs(date).format('YYYYMM');
  }

  loadSingleProduit() {
    return new Promise((_resolve) => {
      const body = {
        action: 'GET_SINGLE_PRODUCT',
        id: this.id,
      };

      this.accessProviders.postData(body, 'stock.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.produit.refProd = data.refProd;
          this.produit.libProd = data.libProd;
          this.produit.description = data.description;
          this.produit.datePeremp = data.datePeremp;
          this.produit.unite = data.unite;
          this.produit.prixVente = data.prixVente;
          this.produit.categorie = data.categorie;
          this.produit.qteAlerte = data.qteAlerte;
        }
      });
    });
  }

  async crudAction(a: any) {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez produitez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      const body = {
        action: 'CRUD',
        id: this.id,
        refProd: this.produit.refProd,
        libProd: this.produit.libProd,
        description:
          this.produit.description !== null ? this.produit.description : '',
        datePeremp:
          this.produit.datePeremp !== null ? this.produit.datePeremp : '',
        unite: this.produit.unite,
        prixVente: this.produit.prixVente,
        categorie: this.produit.categorie,
        qteAlerte: this.produit.qteAlerte,
        crudAct: a,
      };

      this.accessProviders.postData(body, 'stock.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            // Ajout QteEnStock
            if (a === 'CREATE') {
              // Récuperer le dernier ID du produit
              return new Promise((_resolve) => {
                const body = {
                  action: 'GET_LAST_ID',
                };

                this.accessProviders
                  .postData(body, 'stock.php')
                  .subscribe((res: any) => {
                    for (const data of res.result) {
                      this.lastId = data.id;
                    }

                    const body = {
                      action: 'CRUD',
                      produit_id: this.lastId,
                      qteEntree: this.produit.qteDep,
                      crudAct: 'ADD_STOCK',
                    };

                    this.accessProviders
                      .postData(body, 'stock.php')
                      .subscribe((res: any) => {
                        if (res.success === true) {
                          loader.dismiss();
                          this.utils.toast(
                            res.msg + ' ... mise à jour de données en cours ...'
                          );
                          this.modalCtrl.dismiss(null, 'crud');
                        } else {
                          loader.dismiss();
                          this.utils.toastError(res.msg);
                        }
                      });
                  });
              });
            } else {
              loader.dismiss();
              this.utils.toast('... Mise à jour des données en cours ...');
              this.modalCtrl.dismiss(null, 'crud');
            }
          } else {
            loader.dismiss();
            this.utils.toastError(res.msg);
          }
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }
}
