import { UtilsService } from 'src/app/services/utils.service';
import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { PatientService } from 'src/app/services/patient.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-files-detail',
  templateUrl: './files-detail.component.html',
  styleUrls: ['./files-detail.component.scss'],
})
export class FilesDetailComponent implements OnInit {
  @Input() patient;
  momentjs: any = moment;

  isLoading = true;
  isVisible = false;
  chevron = 'chevron-forward-outline';

  // Recupère le compte : Administrateur ou SECRETAIRE
  compte: string;

  constructor(
    public accessProviders: AccessProviders,
    public platform: Platform,
    public utils: UtilsService,
    public patientService: PatientService,
    private storage: StorageService
  ) {
    this.utils.checkPlatform();
    this.storage.getData('p_id').then((p: any) => {
      this.patient = p;
      this.patientService.IdPatient = this.patient.id;
    });

    this.utils.getSession();
  }
  ngOnInit() {
    this.patientService.initFile();
  }

  collapse() {
    this.isVisible = !this.isVisible;
    if (this.isVisible == true) {
      this.chevron = 'chevron-down-outline';
      this.patientService.initFile();
    } else {
      this.chevron = 'chevron-forward-outline';
    }
  }
}
