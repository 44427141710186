import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { format } from 'date-fns';
import { CaisseService } from 'src/app/services/caisse.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-caisse-sortie-crud',
  templateUrl: './caisse-sortie-crud.component.html',
  styleUrls: ['./caisse-sortie-crud.component.scss'],
})
export class CaisseSortieCrudComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    public caisseService: CaisseService,
    public utils: UtilsService
  ) {}

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

  save() {
    this.caisseService.caisseSortie();
  }
}
