import { Component, Input, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-detail-user',
  templateUrl: './detail-user.component.html',
  styleUrls: ['./detail-user.component.scss'],
})
export class DetailUserComponent implements OnInit {
  @Input() user;
  passedId = null;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    this.user = this.navParams.get('user');
  }

  ngOnInit() {
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }
}
