import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { RMAService } from './../../../services/rma.service';

@Component({
  selector: 'app-nouveau-cas',
  templateUrl: './nouveau-cas.component.html',
  styleUrls: ['./nouveau-cas.component.scss'],
})
export class NouveauCasComponent implements OnInit {
  constructor(public rmaService: RMAService) {}

  ngOnInit() {}

  generate() {
    const doc = new jsPDF('l', 'mm', 'a4');

    // Simple html example
    autoTable(doc, { html: '#table', styles: { halign: 'center' } });

    doc.save('table.pdf');
  }
}
