import { PatientService } from 'src/app/services/patient.service';
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-patient-crud',
  templateUrl: './patient-crud.component.html',
  styleUrls: ['./patient-crud.component.scss'],
})
export class PatientCrudComponent implements OnInit {
  constructor(
    private alertCtrl: AlertController,
    public accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public patientService: PatientService,
    public utils: UtilsService
  ) {
    // Recupere ID si existe
    this.patientService.IdPatient = this.navParams.get('id');
    this.checkUpdate();
    this.utils.checkPlatform();
  }

  ngOnInit() {}

  checkUpdate() {
    if (this.patientService.IdPatient !== 0) {
      this.patientService.loadSinglePatient();
      this.patientService.isEdit = true;
    } else {
      this.patientService.getLastId();
      this.patientService.isEdit = false;
    }
  }

  ionViewDidEnter() {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  testDate(date) {
    if (this.patientService.IdPatient === 0) {
      if (this.utils.dateFormat(date) > this.utils.dateFormat(new Date())) {
        this.utils.toastError('Date invalide');
      }
    }
  }

  async presentAlert(a, b) {
    const alert = await this.alertCtrl.create({
      header: a,
      backdropDismiss: false,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Réessayer',
          handler: () => {
            this.patientService.crudAction(b);
          },
        },
      ],
    });

    await alert.present();
  }
}
