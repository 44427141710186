import { Component, OnInit } from '@angular/core';
import { UtilsService } from './../../../services/utils.service';
import { InfoService } from './../../../services/info.service';

@Component({
  selector: 'app-info-fs',
  templateUrl: './info-fs.component.html',
  styleUrls: ['./info-fs.component.scss'],
})
export class InfoFsComponent implements OnInit {
  readonly = true;
  constructor(public utils: UtilsService, public infoService: InfoService) {}

  ngOnInit() {
    this.infoService.loadInfoFS();
  }

  updateFS() {
    this.readonly = !this.readonly;
  }

  save() {
    if (this.readonly == true) {
      this.utils.toastError('Pas de modification à enregistrer');
    } else {
      this.infoService.crudAction();
      this.readonly = true;
    }
  }
}
