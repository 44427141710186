import { StorageService } from 'src/app/services/storage.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  url: any;
  // public server: any;

  // public server = 'http://192.168.1.101/';

  // public server = 'http://127.0.0.3/';

  // public server = 'https://gcm.focusconcept-mada.com/';

  // public server = 'http://192.168.10.31/';

  // public server = 'https://jcr.focusconcept-mada.com/';

  public server = 'https://cm.madagascar-groupejcr.com/';

  constructor(private storageService: StorageService) {
    // this.storageService.getString('url').then((data: any) => {
    //   if (data.value) {
    //     this.url = data.value;
    //     this.server = `http://${this.url}/`;
    //   }
    // });
  }
}
