import { UtilsService } from './utils.service';
import { ModalController, NavParams } from '@ionic/angular';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { SmsComponent } from '../components/appointments/sms/sms.component';
import { RdvComponent } from '../components/appointments/rdv/rdv.component';
import { AccessProviders } from '../providers/access-providers.service';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class WaitService {
  waitings: any = [];
  nbWait: number = 0;
  nbCS: number = 0;
  nDo: number = 0;
  isLoading = true;
  isDesktop = true;
  momentjs: any = moment;
  currentDate;

  wait = {
    date: '',
    time: '',
    doc_id: '',
    description: '',
  };

  patient; // Add Wait

  ////////// OBSERVABLES /////////////
  _waitings = new BehaviorSubject<any[]>([]);
  _nbWait = new BehaviorSubject<number>(0);

  //////////// ASSECEURS ////////////
  get _waitings$() {
    return this._waitings.asObservable();
  }

  get _nbWait$() {
    return this._nbWait.asObservable();
  }
  //////////// ASSECEURS ////////////

  constructor(
    private utils: UtilsService,
    private accessProviders: AccessProviders,
    private router: Router,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private storage: StorageService
  ) {
    this.patient = this.navParams.get('patient');
    this.wait.date = new Date().toISOString().slice(0, 10);
  }

  async addWait(pID) {
    return new Promise(() => {
      const body = {
        action: 'CRUD',
        patient_id: pID,
        user_id: this.wait.doc_id,
        date: this.wait.date,
        heure_arrivee: this.wait.time,
        heure_consult: '',
        statut: 0,
        motif: this.wait.description,
        crudAct: 'CREATE',
      };

      this.accessProviders.postData(body, 'waiting_room.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            this.nbWaitingRoom(this.wait.date);
            this.utils.toast(
              res.msg + ' ... mise à jour de données en cours ...'
            );
            this.modalCtrl.dismiss(null, 'crud');
          } else {
            this.utils.toastError(res.msg);
          }
        },
        (_err) => {
          console.log(_err);
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }

  async loadWaitingRoom(date) {
    this.waitings = [];
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL',
        date: this.momentjs(date).format('YYYYMMDD'),
      };

      this.accessProviders
        .postData(body, 'waiting_room.php')
        .subscribe((res: any) => {
          for (const data of res.result) {
            this.waitings.push(data);
            this._waitings.next(this.waitings);
          }

          this.isLoading = false;
          resolve(true);
        });
    });
  }

  async nbWaitingRoom(date) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_WAIT',
        dateJour: this.momentjs(date).format('YYYYMMDD'),
      };

      this.accessProviders
        .postData(body, 'waiting_room.php')
        .subscribe((res: any) => {
          this.nbWait = res;
          this._nbWait.next(this.nbWait);
          this.isLoading = false;
        });
    });
  }

  async nbConsult(date) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_CONSULT',
        dateJour: this.momentjs(date).format('YYYYMMDD'),
      };

      this.accessProviders
        .postData(body, 'waiting_room.php')
        .subscribe((res: any) => {
          this.nbCS = res;
          this.isLoading = false;
        });
    });
  }

  async nbDo(date) {
    return new Promise((_resolve) => {
      const body = {
        action: 'NB_DO',
        dateJour: this.momentjs(date).format('YYYYMMDD'),
      };

      this.accessProviders
        .postData(body, 'waiting_room.php')
        .subscribe((res: any) => {
          this.nDo = res;
          this.isLoading = false;
        });
    });
  }

  toConsult(id, date) {
    return new Promise(() => {
      const body = {
        action: 'CRUD',
        id: id,
        statut: 1,
        heure_consult: this.momentjs(new Date()).format('HH:MM'),
        crudAct: 'UPDATE',
      };

      this.accessProviders.postData(body, 'waiting_room.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            this.loadWaitingRoom(date);
            this.nbWaitingRoom(date);
          }
        },
        (_err) => {
          console.log(_err);
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }

  doConsult(id, date, heure_consult) {
    return new Promise(() => {
      const body = {
        action: 'CRUD',
        id: id,
        statut: 2,
        heure_consult: heure_consult,
        crudAct: 'UPDATE',
      };

      this.accessProviders.postData(body, 'waiting_room.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            this.loadWaitingRoom(date);
            this.nbDo(date);
          }
        },
        (_err) => {
          console.log(_err);
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }

  deleteWait(id, date) {
    return new Promise(() => {
      const body = {
        action: 'DELETE',
        id: id,
      };

      this.accessProviders.postData(body, 'waiting_room.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            this.loadWaitingRoom(date);
            this.nbDo(date);
            this.nbWaitingRoom(date);
          }
        },
        (_err) => {
          console.log(_err);
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }

  async openDM(patient) {
    this.storage.setData('p_id', patient);
    this.storage.setData('pt', true);
    this.router.navigateByUrl('/dossier-medical');
  }
}
