import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { CaisseService } from 'src/app/services/caisse.service';

@Component({
  selector: 'app-add-other',
  templateUrl: './add-other.component.html',
  styleUrls: ['./add-other.component.scss'],
})
export class AddOtherComponent implements OnInit {
  montant = 0;
  article: any;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    public caisseService: CaisseService
  ) {
    this.article = this.navParams.get('article');
  }

  ngOnInit() {}

  async dismiss() {
    this.modalCtrl.dismiss(null, '');
  }

  addArticle(int) {
    this.caisseService.addArticle('autre', this.article, int);
    this.modalCtrl.dismiss(null, 'crud');
  }
}
