import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  LoadingController,
  NavController,
  ModalController,
  MenuController,
} from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss'],
})
export class ConnexionComponent implements OnInit {
  isSubmitted = false;

  connexion = {
    server: '',
  };

  constructor(
    private loadingCtrl: LoadingController,
    public navCtrl: NavController,
    public accessProviders: AccessProviders,
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    private modalCtrl: ModalController,
    private menuCtrl: MenuController,
    private utils: UtilsService
  ) { }

  async dismiss() {
    await this.modalCtrl.dismiss(null, 'close');
  }

  ionViewWillEnter() {
    this.menuCtrl.enable(false);
  }

  async ngOnInit() {}

  async save() {
    if (this.connexion.server == '') {
      this.utils.toastError('Veuillez entrer un URL');
      return false;
    } else {
      const loader = await this.loadingCtrl.create({
        message: 'Veuillez patientez ...',
        spinner: 'bubbles',
      });
      await loader.present();

      this.storageService.setData('url', this.connexion.server);
      loader.dismiss();

      this.dismiss();
    }
  }
}
