import { CertificatComponent } from './patients/crud/certificat/certificat.component';
import { SearchMCComponent } from './patients/crud/search-mc/search-mc.component';
import { SearchNCComponent } from './patients/crud/search-nc/search-nc.component';
import { SearchTRComponent } from './patients/crud/search-tr/search-tr.component';
import { EmptyComponent } from './empty/empty.component';
import { SearchDoctorComponent } from './utilisateurs/search-doctor/search-doctor.component';
import { CompteRenduComponent } from './patients/crud/compte-rendu/compte-rendu.component';
import { SearchPatientComponent } from './patients/search-patient/search-patient.component';
import { CaisseSortieComponent } from './caisse/caisse-sortie/caisse-sortie.component';
import { CaisseEntreeComponent } from './caisse/caisse-entree/caisse-entree.component';
import { AddWaitComponent } from './wait/add-wait/add-wait.component';
import { ConsultationTypeComponent } from './patients/consultation-type/consultation-type.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { WaitHeaderComponent } from './wait/wait-header/wait-header.component';
import { RmaHeaderComponent } from './rma/rma-header/rma-header.component';
import { PrestationComponent } from './settings/prestation/prestation.component';
import { DatabaseComponent } from './settings/database/database.component';
import { AddOtherComponent } from './caisse/add-other/add-other.component';
import { TableConsultComponent } from './caisse/table-consult/table-consult.component';
import { DetailMedocComponent } from './medoc/detail-medoc/detail-medoc.component';
import { PosologieComponent } from 'src/app/components/medoc/posologie/posologie.component';
import { MedocComponent } from './medoc/medoc.component';
import { LaboCrudComponent } from './patients/crud/labo-crud/labo-crud.component';
import { OrdonnanceTableComponent } from './patients/crud/ordonnance-table/ordonnance-table.component';
import { OrdonnanceCrudComponent } from './patients/crud/ordonnance-crud/ordonnance-crud.component';
import { AddQteComponent } from './caisse/add-qte/add-qte.component';
import { ConnexionComponent } from './settings/connexion/connexion.component';
import { TableComponent } from './caisse/table/table.component';
import { TableOtherComponent } from './caisse/table-other/table-other.component';
import { TableActeComponent } from './caisse/table-acte/table-acte.component';
import { TableProductComponent } from './caisse/table-product/table-product.component';
import { PointOfSaleComponent } from './caisse/point-of-sale/point-of-sale.component';
import { StockActuelComponent } from './stock/stock-actuel/stock-actuel.component';
import { SortieStockComponent } from './stock/sortie-stock/sortie-stock.component';
import { EntreeStockComponent } from './stock/entree-stock/entree-stock.component';
import { DecreaseStockComponent } from './stock/decrease-stock/decrease-stock.component';
import { IncreaseStockComponent } from './stock/increase-stock/increase-stock.component';
import { AddProductComponent } from './stock/add-product/add-product.component';
import { AddCategoryComponent } from './stock/add-category/add-category.component';
import { LoginComponent } from './login/login.component';
import { SmsComponent } from './appointments/sms/sms.component';
import { CrudUserComponent } from './utilisateurs/crud-user/crud-user.component';
import { DetailConsultComponent } from './consultations/detail-consult/detail-consult.component';
import { CurrentConsultComponent } from './consultations/current-consult/current-consult.component';
import { ConsultPrescripteurComponent } from './consultations/consult-prescripteur/consult-prescripteur.component';
import { DetailEventComponent } from './appointments/detail-event/detail-event.component';
import { AddEventComponent } from './appointments/add-event/add-event.component';
import { CalSchedulerComponent } from './appointments/cal-scheduler/cal-scheduler.component';
import { AssignRdvComponent } from './appointments/assign-rdv/assign-rdv.component';
import { RdvComponent } from './appointments/rdv/rdv.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RdvCalendarComponent } from './dashboard/rdv-calendar/rdv-calendar.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ListConsultComponent } from './consultations/list-consult/list-consult.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalHeaderComponent } from './appointments/cal-header/cal-header.component';
import { NgxLoadingModule } from 'ngx-loading';
import { AtcdCrudComponent } from './patients/crud/atcd-crud/atcd-crud.component';
import { PatientCrudComponent } from './patients/crud/patient-crud/patient-crud.component';
import { EncaissementComponent } from './caisse/encaissement/encaissement.component';
import { CameraFileComponent } from './patients/crud/files/camera-file/camera-file.component';
import { ChooseFileComponent } from './patients/crud/files/choose-file/choose-file.component';
import { DetailDocumentComponent } from './patients/crud/files/detail-document/detail-document.component';
import { ImageViewerComponent } from './patients/crud/files/image-viewer/image-viewer.component';
import { TextEditorComponent } from './patients/crud/files/text-editor/text-editor.component';
import { AtcdComponent } from './patients/dossier-medical/atcd/atcd.component';
import { ConsultationDetailComponent } from './patients/dossier-medical/consultation-detail/consultation-detail.component';
import { OptionDetailComponent } from './patients/dossier-medical/consultation-detail/option-detail/option-detail.component';
import { ConsultationComponent } from './patients/dossier-medical/consultation/consultation.component';
import { ExamenComponent } from './patients/dossier-medical/examen/examen.component';
import { FilesDetailComponent } from './patients/dossier-medical/files/files-detail.component';
import { HistoriqueExamenComponent } from './patients/dossier-medical/historique-examen/historique-examen.component';
import { InfoComponent } from './patients/dossier-medical/info/info.component';
import { DashComponent } from './dashboard/dash/dash.component';
import { CalDashHeaderComponent } from './dashboard/cal-dash-header/cal-dash-header.component';
import { TablePatientComponent } from './dashboard/table-patient/table-patient.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DossierComponent } from './patients/dossier-medical/dossier/dossier.component';
import { SearchDiagComponent } from './patients/crud/search-diag/search-diag.component';
import { UpdateEventComponent } from './appointments/update-event/update-event.component';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { ConsultationUpdateComponent } from './patients/crud/consultation-update/consultation-update.component';
import { DentisterieComponent } from './patients/crud/dentisterie/dentisterie.component';
import { OphtalmoComponent } from './patients/crud/ophtalmo/ophtalmo.component';
import { ParamGenComponent } from './patients/crud/param-gen/param-gen.component';
import { QuillModule } from 'ngx-quill';
import { CertificatDisplayComponent } from './patients/crud/certificat-display/certificat-display.component';
import { NouveauCasComponent } from './rma/nouveau-cas/nouveau-cas.component';
import { MaladieChroniqueComponent } from './rma/maladie-chronique/maladie-chronique.component';
import { TraumaComponent } from './rma/trauma/trauma.component';
import { FrequentationComponent } from './rma/frequentation/frequentation.component';
import { PaluComponent } from './rma/palu/palu.component';
import { NeoColComponent } from './rma/neo-col/neo-col.component';
import { PcimeComponent } from './rma/pcime/pcime.component';
import { IstComponent } from './rma/ist/ist.component';
import { NutritionComponent } from './rma/nutrition/nutrition.component';
import { CpnComponent } from './rma/cpn/cpn.component';
import { MaterniteComponent } from './rma/maternite/maternite.component';
import { PevComponent } from './rma/pev/pev.component';
import { PfComponent } from './rma/pf/pf.component';
import { VisiteScolaireComponent } from './rma/visite-scolaire/visite-scolaire.component';
import { IdFsComponent } from './rma/id-fs/id-fs.component';
import { InfoFsComponent } from './settings/info-fs/info-fs.component';
import { ProtocoleComponent } from './patients/protocole/protocole.component';
import { CrudProtocoleComponent } from './patients/crud-protocole/crud-protocole.component';
import { ActesMedicauxComponent } from './patients/dossier-medical/actes-medicaux/actes-medicaux.component';
import { ResultatRdtComponent } from './patients/crud/resultat-rdt/resultat-rdt.component';
import { PrestationCsComponent } from './settings/prestation/list/prestation-cs/prestation-cs.component';
import { PrestationActeComponent } from './settings/prestation/list/prestation-acte/prestation-acte.component';
import { PrestationAutreComponent } from './settings/prestation/list/prestation-autre/prestation-autre.component';
import { CrudConsultComponent } from './settings/prestation/crud/crud-consult/crud-consult.component';
import { CrudActeComponent } from './settings/prestation/crud/crud-acte/crud-acte.component';
import { CrudAutreComponent } from './settings/prestation/crud/crud-autre/crud-autre.component';
import { CaisseDetailComponent } from './caisse/caisse-detail/caisse-detail.component';
import { CaisseSortieCrudComponent } from './caisse/caisse-sortie-crud/caisse-sortie-crud.component';
import { PatientInfoComponent } from './patients/dossier-medical/patient-info/patient-info.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './patients/crud/files/pdf-viewer/pdf-viewer.component';
import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { CaisseJourComponent } from './caisse/caisse-jour/caisse-jour.component';
import { CaisseCreanceComponent } from './caisse/caisse-creance/caisse-creance.component';
import { UpdateCreanceComponent } from './caisse/update-creance/update-creance.component';
import { CaisseDateComponent } from './caisse/caisse-date/caisse-date.component';
import { CheckProductComponent } from './dashboard/check-product/check-product.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { CaisseChartComponent } from './dashboard/caisse-chart/caisse-chart.component';
import { OpenPriceComponent } from './caisse/open-price/open-price.component';
import { ExploFonctionnelleComponent } from './patients/dossier-medical/explo-fonctionnelle/explo-fonctionnelle.component';
import { AutreCasComponent } from './patients/crud/autre-cas/autre-cas.component';
import { OtherCaseComponent } from './rma/other-case/other-case.component';
import { SearchCpnComponent } from './patients/crud/search-cpn/search-cpn.component';
import { AddScolComponent } from './patients/crud/add-scol/add-scol.component';
import { RhaHeaderComponent } from './rha/rha-header/rha-header.component';
import { RmaListComponent } from './rma/rma-list/rma-list.component';
import { RhaListComponent } from './rha/rha-list/rha-list.component';
import { RcsComponent } from './rha/rcs/rcs.component';
import { RecapDecesComponent } from './rha/recap-deces/recap-deces.component';
import { SurvMaladiesComponent } from './rha/surv-maladies/surv-maladies.component';

registerLocaleData(localeFr);

export const components = [
  // Wait
  WaitHeaderComponent,
  AddWaitComponent,
  SearchDoctorComponent,

  // Das
  CalDashHeaderComponent,
  CaisseChartComponent,
  CheckProductComponent,
  RdvCalendarComponent,

  // Auth
  LoginComponent,
  ConnexionComponent,

  // Appointment
  RdvComponent,
  AssignRdvComponent,
  CalSchedulerComponent,
  CalHeaderComponent,
  AddEventComponent,
  DetailEventComponent,
  SmsComponent,
  UpdateEventComponent,

  // Patient
  ConsultationDetailComponent,
  OptionDetailComponent,
  AtcdCrudComponent,
  ConsultationUpdateComponent,
  PatientCrudComponent,
  InfoComponent,
  AtcdComponent,
  ConsultationComponent,
  FilesDetailComponent,
  ExamenComponent,
  OrdonnanceCrudComponent,
  OrdonnanceTableComponent,
  LaboCrudComponent,
  MedocComponent,
  PosologieComponent,
  DetailMedocComponent,
  HistoriqueExamenComponent,
  ConsultationTypeComponent,
  DossierComponent,
  CertificatComponent,
  DentisterieComponent,
  CameraFileComponent,
  ChooseFileComponent,
  DetailDocumentComponent,
  ImageViewerComponent,
  TextEditorComponent,
  CompteRenduComponent,
  OphtalmoComponent,
  ParamGenComponent,
  CertificatDisplayComponent,
  ProtocoleComponent,
  CrudProtocoleComponent,
  ConsultationComponent,
  ActesMedicauxComponent,
  ResultatRdtComponent,
  PatientInfoComponent,
  PdfViewerComponent,
  ExploFonctionnelleComponent,
  AddScolComponent,

  // Diag
  SearchNCComponent,
  SearchMCComponent,
  SearchTRComponent,
  SearchDiagComponent,
  AutreCasComponent,
  SearchCpnComponent,

  // Dashboard
  RdvCalendarComponent,
  DashComponent,
  TablePatientComponent,

  // Consultation
  ListConsultComponent,
  ConsultPrescripteurComponent,
  CurrentConsultComponent,
  DetailConsultComponent,

  // utilisateurs
  CrudUserComponent,

  // Stock
  AddCategoryComponent,
  AddProductComponent,
  IncreaseStockComponent,
  DecreaseStockComponent,
  EntreeStockComponent, // Table
  SortieStockComponent, // Table
  StockActuelComponent, // Table

  // caisse
  PointOfSaleComponent,
  TableProductComponent,
  TableConsultComponent,
  TableActeComponent,
  TableOtherComponent,
  TableComponent,
  AddQteComponent,
  AddOtherComponent,
  EncaissementComponent,
  CaisseEntreeComponent,
  CaisseSortieComponent,
  SearchPatientComponent,
  CaisseDetailComponent,
  CaisseSortieCrudComponent,
  CaisseJourComponent,
  CaisseCreanceComponent,
  UpdateCreanceComponent,
  CaisseDateComponent,
  OpenPriceComponent,

  // Settings
  DatabaseComponent,
  PrestationComponent,
  InfoFsComponent,

  // RMA
  RmaHeaderComponent,
  NouveauCasComponent,
  MaladieChroniqueComponent,
  TraumaComponent,
  FrequentationComponent,
  PaluComponent,
  NeoColComponent,
  PcimeComponent,
  IstComponent,
  NutritionComponent,
  CpnComponent,
  MaterniteComponent,
  PevComponent,
  PfComponent,
  DentisterieComponent,
  VisiteScolaireComponent,
  IdFsComponent,
  OtherCaseComponent,
  RmaListComponent,
  // RHA
  RhaHeaderComponent,
  RhaListComponent,
  RcsComponent,
  RecapDecesComponent,
  SurvMaladiesComponent,

  EmptyComponent,

  // Settings
  PrestationCsComponent,
  PrestationActeComponent,
  PrestationAutreComponent,
  CrudConsultComponent,
  CrudActeComponent,
  CrudAutreComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    NgxEchartsModule,
    IonCustomScrollbarModule,
    PdfViewerModule,
    TextareaAutosizeModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
    RouterModule,
    NgxDatatableModule,
    // NgbModalModule,
    NgxLoadingModule,
    NgxPaginationModule,
    NgApexchartsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    QuillModule,
  ],
  exports: [...components],
  providers: [
    // NumberToLetter,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr-CA' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedComponentsModule {}
