import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, LoadingController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError, finalize, map, timeout } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { ConnexionComponent } from '../components/settings/connexion/connexion.component';
import { AccessProviders } from '../providers/access-providers.service';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  token: any;

  constructor(
    private router: Router,
    private accessProviders: AccessProviders,
    private storageService: StorageService,
    private utils: UtilsService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController
  ) {}

  async login(username: string, password: string) {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise((resolve) => {
      const body = {
        action: 'LOGIN',
        username: username,
        password: Md5.hashStr(password),
      };

      this.accessProviders
        .postData(body, 'user.php')
        .pipe(
          timeout(10000), // 10 sec
          map((response: any) => {
            // Success...
            return response;
          }),
          catchError((error) => {
            // Error...
            // Timeout over also handled here
            // I want to return an error for timeout
            return throwError(error || 'Timeout Exception');
          }),
          finalize(() => {
            console.log('Request it is over');
            this.loadingCtrl.dismiss();
            // this.utils.toastError('Erreur de liaison avec les données');
          })
        )
        .subscribe(
          (res: any) => {
            if (res.success == true) {
              loader.dismiss();
              this.isLoggedIn = true;
              this.utils.toast('Connexion réussie');
              this.storageService.setData('storage_session', res.result);
              localStorage.setItem('authenticated', '1');

              // Add user
              localStorage.setItem('userId', res.result.id);
              localStorage.setItem('name', res.result.name);
              localStorage.setItem('compte', res.result.compte);
              localStorage.setItem('agentSante', res.result.agentSante);
              localStorage.setItem('medecin', res.result.medecin);
              localStorage.setItem('onm', res.result.onm);

              // Create storage_session
              this.storageService.setData('auth', 1); // Auth : 1 (Menu - MM)
              this.router.navigateByUrl('/dashboard');
            } else {
              loader.dismiss();
              this.utils.toastError('Utilisateur et/ou Mot de passe incorrect');
            }
            resolve(true);
          }
          // () => {
          //   loader.dismiss();
          //   this.utils.toastError('Utilisateur et/ou Mot de passe incorrect');
          //   // this.utils.alert(
          //   //   'Focus GCM',
          //   //   'Erreur de liaison avec les données',
          //   //   this.login(username, password)
          //   // );
          // }
        );
    });
  }

  async openConnexion() {
    const modal = await this.modalCtrl.create({
      component: ConnexionComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: false,
      cssClass: 'connexion-modal',
    });
    await modal.present();
  }

  authenticated(): boolean {
    return this.isLoggedIn;
  }
}
