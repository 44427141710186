import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ordonnance-crud',
  templateUrl: './ordonnance-crud.component.html',
  styleUrls: ['./ordonnance-crud.component.scss'],
})
export class OrdonnanceCrudComponent implements OnInit {
  @Input() patient;

  isVisible = false;
  chevron = 'chevron-forward-outline';

  constructor(
    private modalCrl: ModalController
  ) { }

  ngOnInit() { }
  
  collapse() {
    this.isVisible = !this.isVisible;
    if (this.isVisible == true) {
      this.chevron = 'chevron-down-outline';
    } else {
      this.chevron = 'chevron-forward-outline';
    }
  }

  dismiss() {
    this.modalCrl.dismiss();
  }

}
