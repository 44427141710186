import { ModalController, LoadingController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StockService {
  entree = {
    date: '',
    product: '',
    qte: '',
    prixAchat: '',
  };

  sortie = {
    date: '',
    product: '',
    qte: '',
    motif: '',
  };

  constructor(
    private loadingCtrl: LoadingController,
    private accessProviders: AccessProviders,
    private utils: UtilsService,
    private modalCtrl: ModalController
  ) {}

  clearE() {
    this.entree.qte = '';
    this.entree.prixAchat = '';
  }

  clearS() {
    this.sortie.motif = '';
    this.sortie.qte = '';
  }

  async increase(produit) {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez produitez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      const body = {
        action: 'CRUD_UP_STOCK',
        dateAppro: this.entree.date,
        produit_id: produit.pID,
        qte: this.entree.qte,
        prixAchat: this.entree.prixAchat,
      };

      this.accessProviders.postData(body, 'stock.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            // Mettre à jour la quantité du Produit dans le stock
            new Promise(() => {
              const body = {
                action: 'CRUD',
                produit_id: produit.pID,
                qteEntree: this.entree.qte,
                crudAct: 'QTE_INC',
              };

              this.accessProviders
                .postData(body, 'stock.php')
                .subscribe((res: any) => {
                  if (res.success === true) {
                    loader.dismiss();
                    this.utils.toast(
                      res.msg + ' ... mise à jour de données en cours ...'
                    );
                    this.modalCtrl.dismiss(null, 'crud');
                  } else {
                    loader.dismiss();
                    this.utils.toastError(res.msg);
                  }
                });
            });
          } else {
            loader.dismiss();
            this.utils.toastError(res.msg);
          }
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }

  async decrease(product, user_id) {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez produitez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      const body = {
        action: 'CRUD_DOWN_STOCK',
        dateSortie: this.sortie.date,
        produit_id: product.pID,
        qte: this.sortie.qte,
        motif: this.sortie.motif,
        user_id: user_id,
      };

      this.accessProviders.postData(body, 'stock.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            // Mettre à jour la quantité du Produit dans le stock
            new Promise(() => {
              const body = {
                action: 'CRUD',
                produit_id: product.pID,
                qteSortie: this.sortie.qte,
                crudAct: 'QTE_DEC',
              };

              this.accessProviders
                .postData(body, 'stock.php')
                .subscribe((res: any) => {
                  if (res.success === true) {
                    loader.dismiss();
                    this.utils.toast(
                      res.msg + ' ... mise à jour de données en cours ...'
                    );
                    this.modalCtrl.dismiss(null, 'crud');
                  } else {
                    loader.dismiss();
                    this.utils.toastError(res.msg);
                  }
                });
            });
          } else {
            loader.dismiss();
            this.utils.toastError(res.msg);
          }
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }
}
