import { Component, OnInit } from '@angular/core';
import { RMAService } from 'src/app/services/rma.service';

@Component({
  selector: 'app-palu',
  templateUrl: './palu.component.html',
  styleUrls: ['./palu.component.scss'],
})
export class PaluComponent implements OnInit {
  constructor(public rmaService: RMAService) {}

  ngOnInit() {}
}
