import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-neo-col',
  templateUrl: './neo-col.component.html',
  styleUrls: ['./neo-col.component.scss'],
})
export class NeoColComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
