import { Component, OnInit } from '@angular/core';
import { RMAService } from 'src/app/services/rma.service';

@Component({
  selector: 'app-ist',
  templateUrl: './ist.component.html',
  styleUrls: ['./ist.component.scss'],
})
export class IstComponent implements OnInit {
  constructor(public rmaService: RMAService) {}

  ngOnInit() {}
}
