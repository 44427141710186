import { UrlService } from './url.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(
    private httpClient: HttpClient,
    private urlService: UrlService
  ) { }

  public uploadFile(data) {
    const uploadURL = `${this.urlService.server}api/uploadFile.php`;
    return this.httpClient.post<any>(uploadURL, data);
  }

  public uploadImage(data) {
    const uploadURL = `${this.urlService.server}api/uploadImage.php`;
    return this.httpClient.post<any>(uploadURL, data);
  }
}
