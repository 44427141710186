import { CaisseService } from 'src/app/services/caisse.service';
import { UtilsService } from './../../../services/utils.service';
import { NavParams, ModalController } from '@ionic/angular';
import { AccessProviders } from './../../../providers/access-providers.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-add-qte',
  templateUrl: './add-qte.component.html',
  styleUrls: ['./add-qte.component.scss'],
})
export class AddQteComponent implements OnInit {
  qte = 1;
  article: any;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    public caisseService: CaisseService
  ) {
    this.article = this.navParams.get('article');
  }

  ngOnInit() {}

  async dismiss() {
    this.modalCtrl.dismiss(null, '');
  }

  addArticle(qte) {
    this.caisseService.addArticle('medoc', this.article, qte);
    this.modalCtrl.dismiss(null, 'crud');
  }
}
