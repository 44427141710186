import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { PrestationService } from 'src/app/services/prestation.service';

@Component({
  selector: 'app-crud-acte',
  templateUrl: './crud-acte.component.html',
  styleUrls: ['./crud-acte.component.scss'],
})
export class CrudActeComponent implements OnInit {
  constructor(
    public utils: UtilsService,
    private modalCtrl: ModalController,
    public prestationService: PrestationService
  ) {
    this.prestationService.clearAll();
  }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
