import { OptionDetailComponent } from './option-detail/option-detail.component';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { PopoverController, ModalController, NavParams } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-consultation-detail',
  templateUrl: './consultation-detail.component.html',
  styleUrls: ['./consultation-detail.component.scss'],
})
export class ConsultationDetailComponent implements OnInit {
  @Input() consultation: any;
  consultID = null;
  patient = null;
  prescripteur: any;

  momentjs: any = moment;

  constructor(
    public accessProviders: AccessProviders,
    private popCtrl: PopoverController,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public utils: UtilsService
  ) {
    this.consultation = this.navParams.get('consult');
    this.consultID = this.consultation.id;
    this.patient = this.navParams.get('patient');
  }

  ngOnInit() {
    this.getPrescripteur();
    this.utils.getSession();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async getPrescripteur() {
    return new Promise((_resolve) => {
      const body = {
        action: 'GET_PRESCRIPTEUR',
        id: this.consultation.prescripteur_id,
      };

      this.accessProviders.postData(body, 'user.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.prescripteur = data.name;
        }
        _resolve(true);
      });
    });
  }

  async showOptions(ev) {
    const pop = await this.popCtrl.create({
      component: OptionDetailComponent,
      event: ev,
      backdropDismiss: true,
      componentProps: {
        consultation: this.consultation,
        patient: this.patient,
      },
    });
    await pop.present();
  }
}
