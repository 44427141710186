import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { PatientService } from 'src/app/services/patient.service';
import { ProtocoleService } from 'src/app/services/protocole.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CrudProtocoleComponent } from '../crud-protocole/crud-protocole.component';

@Component({
  selector: 'app-protocole',
  templateUrl: './protocole.component.html',
  styleUrls: ['./protocole.component.scss'],
})
export class ProtocoleComponent implements OnInit {
  protocoles = [];
  isLoading: boolean;
  constructor(
    public utils: UtilsService,
    public accessProviders: AccessProviders,
    public platform: Platform,
    public router: Router,
    public protocoleService: ProtocoleService,
    private modalCtrl: ModalController,
    private storage: StorageService
  ) {
    this.protocoleService.id = 0;
    this.utils.checkPlatform();
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();
    this.protocoleService.refreshData();

    this.protocoles = [];
    this.protocoleService._protocoles$.subscribe((protocoles: any[]) => {
      this.protocoles = protocoles;
    });

    this.protocoleService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  useProtocole() {
    this.storage.setData('protocole', this.protocoleService.protocole);

    this.modalCtrl.dismiss(null, 'close');
  }

  showProtocole(protocole) {
    this.protocoleService.protocole = protocole;
    this.protocoleService.loadSingleProtocole(protocole.id);
    this.protocoleService.id = protocole.id;
  }

  async openCrud(id) {
    this.dismiss();
    const modal = await this.modalCtrl.create({
      component: CrudProtocoleComponent,
      animated: true,
      mode: 'ios',
      componentProps: { id },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      const modal = await this.modalCtrl.create({
        component: ProtocoleComponent,
        animated: true,
        mode: 'ios',
        backdropDismiss: true,
        cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
      });

      await modal.present();

      this.protocoleService.refreshData();
      this.protocoleService.clearInput();
    }
  }
}
