import { ModalController, NavParams } from '@ionic/angular';
import { PatientService } from 'src/app/services/patient.service';
import { Component, OnInit } from '@angular/core';
import { ExamenService } from 'src/app/services/examen.service';
import { SearchDiagComponent } from '../../crud/search-diag/search-diag.component';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { RMAService } from 'src/app/services/rma.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DentisterieComponent } from './../../crud/dentisterie/dentisterie.component';
import { OphtalmoComponent } from './../../crud/ophtalmo/ophtalmo.component';
import { ParamGenComponent } from './../../crud/param-gen/param-gen.component';
import { ProtocoleComponent } from './../../protocole/protocole.component';

@Component({
  selector: 'app-examen',
  templateUrl: './examen.component.html',
  styleUrls: ['./examen.component.scss'],
})
export class ExamenComponent implements OnInit {
  segmentModel = 'cs';
  isVisible = false;
  chevron = 'chevron-forward-outline';

  readonly = true;

  choix = 1;
  activeIndex = 0;

  constructor(
    public accessProviders: AccessProviders,
    public utils: UtilsService,
    private modalCtrl: ModalController,
    public examen: ExamenService,
    private storage: StorageService,
    private rmaService: RMAService,
    public patientService: PatientService
  ) {}

  ngOnInit() {
    this.storage.removeData('protocole');
    this.examen.clearInput();
  }

  ionViewDidLeave() {
    this.examen.clearInput();
  }

  segmentChanged(e) {}

  collapse() {
    this.isVisible = !this.isVisible;
    if (this.isVisible == true) {
      this.chevron = 'chevron-down-outline';
      this.utils.isVisibleDM = false;
    } else {
      this.chevron = 'chevron-forward-outline';
      this.utils.isVisibleDM = true;
    }
  }
}
