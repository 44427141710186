import { AppService } from './../../../services/app.service';
import { UserService } from './../../../services/user.service';
import { PatientService } from './../../../services/patient.service';
import { Component, OnInit } from '@angular/core';
import { NavParams } from 'node_modules/@ionic/angular';
import { ModalController } from 'node_modules/@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { StorageService } from 'src/app/services/storage.service';
import { SearchDoctorComponent } from '../../utilisateurs/search-doctor/search-doctor.component';
import * as moment from 'moment';
import { SearchPatientComponent } from '../../patients/search-patient/search-patient.component';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
})
export class AddEventComponent implements OnInit {
  doc: string;
  choix;
  p_id: string;

  momentjs: any = moment;
  readonly = true;
  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    public patientService: PatientService,
    public userService: UserService,
    public appService: AppService,
    private storage: StorageService
  ) {
    this.appService.id = this.navParams.get('id');
    if (this.appService.id !== 0) {
      this.appService.loadSingleApp();
    } else {
      this.appService.date = this.navParams.get('date');
    }
    this.utils.checkPlatform();
  }

  ngOnInit() {
    this.patientService.loadPatients();
    this.userService.loadDoctors();
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  async openSearch() {
    const modal = await this.modalCtrl.create({
      component: SearchDoctorComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win5-modal-web' : 'win5-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.storage.getData('d_id').then((d: any) => {
        this.doc = d.name;
        this.appService.appointment.doc_id = d.id;
      });
    }
  }

  onItemChange(event) {
    if (event.target.value == 1) {
      this.readonly = false;
      this.appService.appointment.p_id = '';
    } else {
      this.readonly = true;
      this.appService.appointment.p_id = '';
      this.searchPatient();
    }
  }

  async searchPatient() {
    const modal = await this.modalCtrl.create({
      component: SearchPatientComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win5-modal-web' : 'win5-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.storage.getData('p_id').then((p: any) => {
        this.appService.appointment.p_id = p.nom + ' ' + p.prenom;
      });
    }
  }
}
