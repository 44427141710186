import { AccessProviders } from '../providers/access-providers.service';
import { UtilsService } from './utils.service';
import { ModalController, LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { AtcdCrudComponent } from '../components/patients/crud/atcd-crud/atcd-crud.component';

@Injectable({
  providedIn: 'root',
})
export class ATCDService {
  patientID = null;
  patient = null;
  atcds: any = [];
  isLoading = true;
  noATCD: number;

  medicaux: string = '';
  chirurgicaux: string = '';
  allergies: string = '';
  toxiques: string = '';
  familiaux: string = '';

  constructor(
    private loadingCtrl: LoadingController,
    private accessProviders: AccessProviders,
    private modalCtrl: ModalController,
    public utils: UtilsService
  ) {
    this.initATCD();
  }

  async crudAction() {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise((_resolve) => {
      const body = {
        action: 'CRUD',
        medicaux: this.medicaux,
        chirurgicaux: this.chirurgicaux,
        allergies: this.allergies,
        toxiques: this.toxiques,
        familiaux: this.familiaux,
        patient_id: this.patientID,
      };

      this.accessProviders.postData(body, 'atcd.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            loader.dismiss();
            this.utils.toast(res.msg);
          } else {
            loader.dismiss();
            this.utils.alert('Focus GCM', res.msg, this.crudAction());
          }
        },
        (_err) => {
          loader.dismiss();
          this.utils.alert(
            'Focus GCM',
            'Erreur de liaison avec les données',
            this.crudAction()
          );
        }
      );
    });
  }

  async loadATCD() {
    return new Promise((_resolve) => {
      const body = {
        action: 'GET_ATCD',
        patient_id: this.patientID,
      };

      this.accessProviders.postData(body, 'atcd.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.medicaux = data.medicaux;
          this.chirurgicaux = data.chirurgicaux;
          this.allergies = data.allergies;
          this.toxiques = data.toxiques;
          this.familiaux = data.familiaux;

          if (
            data.medicaux === '' &&
            data.chirurgicaux === '' &&
            data.allergies === '' &&
            data.toxiques === '' &&
            data.familiaux === ''
          ) {
            this.noATCD = 0;
          }
        }
      });
    });
  }

  delMed() {
    this.medicaux = '';
  }

  delChir() {
    this.chirurgicaux = '';
  }

  delAllergie() {
    this.allergies = '';
  }

  delToxique() {
    this.toxiques = '';
  }

  delFamiliaux() {
    this.familiaux = '';
  }

  async openATCD(id) {
    const modal = await this.modalCtrl.create({
      component: AtcdCrudComponent,
      animated: true,
      mode: 'ios',
      componentProps: { id, patient: this.patient },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
    });
    return await modal.present();
  }

  initATCD() {
    this.loadATCD();
  }
}
