import { AccessProviders } from '../providers/access-providers.service';
import { UtilsService } from './utils.service';
import {
  ModalController,
  LoadingController,
  AlertController,
} from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  info = {
    id: '',
    code: '',
    nom: '',
    adresse: '',
    contact: '',
    commune: '',
    statut: '',
    sdsp: '',
    drsp: '',
  };

  constructor(
    private utils: UtilsService,
    private accessProviders: AccessProviders,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController
  ) {}

  loadInfoFS() {
    return new Promise((_resolve) => {
      const body = {
        action: 'GET_INFO',
      };

      this.accessProviders.postData(body, 'info.php').subscribe((res: any) => {
        for (const data of res.result) {
          this.info.id = data.id;
          this.info.code = data.code;
          this.info.nom = data.nom;
          this.info.adresse = data.adresse;
          this.info.contact = data.contact;
          this.info.commune = data.commune;
          this.info.statut = data.statut;
          this.info.sdsp = data.sdsp;
          this.info.drsp = data.drsp;
        }
      });
    });
  }

  async crudAction() {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      const body = {
        action: 'CRUD',
        id: this.info.id,
        code: this.info.code,
        nom: this.info.nom,
        adresse: this.info.adresse,
        contact: this.info.contact,
        commune: this.info.commune,
        statut: this.info.statut,
        sdsp: this.info.sdsp,
        drsp: this.info.drsp,
      };

      this.accessProviders.postData(body, 'info.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            loader.dismiss();
            this.utils.toast(res.msg);
          } else {
            loader.dismiss();
            this.utils.alert('Focus GCM', res.msg, this.crudAction());
          }
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }
}
