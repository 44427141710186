import { Component, OnInit } from '@angular/core';
import { AccessProviders } from './../../../providers/access-providers.service';
import { UtilsService } from './../../../services/utils.service';
import { LoadingController } from '@ionic/angular';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss'],
})
export class DatabaseComponent implements OnInit {
  bdd = {
    restoreDb: '',
    backupDb: '',
  };

  form: FormGroup;
  constructor(
    private accessProviders: AccessProviders,
    public utils: UtilsService,
    private loadingCtrl: LoadingController,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      restoreDb: [''],
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('restoreDb').setValue(file);
      this.bdd.restoreDb = file.name;
      console.log(this.bdd.restoreDb);
    }
  }

  async restoreDb() {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      const body = {
        dbName: this.bdd.restoreDb,
      };

      this.accessProviders.postData(body, 'lib/restoreDb.php').subscribe(
        (res: any) => {
          loader.dismiss();
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }

  async backupDb() {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      this.accessProviders.postData('', 'lib/backupDb.php').subscribe(
        (res: any) => {
          loader.dismiss();
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }
}
