import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
} from '@ionic/angular';
import * as moment from 'moment';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import { UploadService } from 'src/app/services/upload.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-choose-file',
  templateUrl: './choose-file.component.html',
  styleUrls: ['./choose-file.component.scss'],
})
export class ChooseFileComponent implements OnInit {
  passedId = null;
  patient: any;
  form: FormGroup;
  uploadResponse;
  image = '../../assets/placeholder.jpg';
  reste: number;

  file = {
    date: '',
    libelle: '',
    commentaires: '',
  };

  nomFichier = '';
  fileName: string;
  disabledButton;

  // Recupère le compte : Administrateur ou SECRETAIRE
  userId: string;

  momentjs: any = moment;

  constructor(
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    private alertCtrl: AlertController,
    public utils: UtilsService,
    public accessProviders: AccessProviders,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) {
    this.patient = this.navParams.get('patient');
    this.passedId = this.patient.id;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      filename: [''],
    });

    this.userId = localStorage.getItem('userId');
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  getMax() {
    this.reste = 250 - this.file.commentaires.length;

    if (this.file.commentaires.length > 250) {
      this.presentAlertCaract('Caractères max atteints !');
    }
  }

  testDate(date) {
    if (this.utils.dateFormat(date) > this.utils.dateFormat(new Date())) {
      this.utils.toastError('Date invalide');
    }
  }

  async presentAlertCaract(a) {
    const alert = await this.alertCtrl.create({
      header: a,
      backdropDismiss: false,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('filename').setValue(file);
      this.fileName = file.name;
    }
  }

  onSubmit() {
    if (this.file.date === '') {
      this.utils.toastError('Date obligatoire');
    } else if (this.file.libelle === '') {
      this.utils.toastError('Titre obligatoire');
    } else if (this.form.get('filename').value === '') {
      this.utils.toastError('Fichier vide');
    } else if (
      this.fileName.split('.').pop() !== 'pdf' ||
      this.fileName.split('.').pop() === 'jpg' ||
      this.fileName.split('.').pop() === 'png' ||
      this.fileName.split('.').pop() === 'bmp'
    ) {
      this.utils.toastError(
        'Fichier non suporté ! \nVeuillez choisir un fichier PDF'
      );
      this.form.get('filename').value === '';
    } else {
      // Upload file
      const formData = new FormData();
      formData.append('id', this.passedId);
      formData.append('filename', this.form.get('filename').value);

      this.uploadService.uploadFile(formData).subscribe(
        (res) => {
          this.disabledButton = false;
          this.uploadResponse = res.status;
          this.nomFichier = res.filename;
          // save document
          this.saveFile();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  async saveFile() {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise((_resolve) => {
      const body = {
        action: 'FILE',
        patient_id: this.passedId,
        date: this.momentjs(this.file.date).format('YYYYMMDD'),
        libelle: this.file.libelle,
        nomFichier: this.nomFichier,
        commentaires: this.file.commentaires,
        prescripteur_id: this.userId,
      };

      this.accessProviders.postData(body, 'fichier.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            loader.dismiss();
            this.utils.toast(res.msg);
            this.modalCtrl.dismiss(null, 'crud');
          } else {
            loader.dismiss();
            this.utils.toast(res.msg);
          }
        },
        (_err) => {
          loader.dismiss();
          this.dismiss();
          // this.utils.presentAlert('Timeout');
        }
      );
    });
  }
}
