import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-labo-crud',
  templateUrl: './labo-crud.component.html',
  styleUrls: ['./labo-crud.component.scss'],
})
export class LaboCrudComponent implements OnInit {
  standard: any;
  reins: any;
  checkedList: any;

  constructor() {
    this.standard = [
      { id: 1, value: 'NFS', isSelected: false },
      { id: 2, value: 'Créatinine', isSelected: false },
      { id: 3, value: 'Iono sanguin', isSelected: false },
      { id: 4, value: 'Cholestérol total', isSelected: false },
      { id: 5, value: 'HDL Cholestérol', isSelected: false },
      { id: 6, value: 'LDL Cholestérol', isSelected: false },
      { id: 7, value: 'Triglycéridémie', isSelected: false },
      { id: 8, value: 'ASAT', isSelected: false },
      { id: 9, value: 'ALAT', isSelected: false },
      { id: 10, value: 'G-GT', isSelected: false },
      { id: 11, value: 'Calcium', isSelected: false },
      { id: 12, value: 'Magnésium', isSelected: false },
    ];

    this.reins = [
      { value: 'Urée', id: '1', isSelected: false },
      { value: 'Créatinine', id: '2', isSelected: false },
      { value: 'Ionogramme sanguin', id: '3', isSelected: false },
      { value: 'Iono urinaire', id: '4', isSelected: false },
      { value: 'Protéinurie', id: '5', isSelected: false },
    ];
    this.getCheckedItemList(this.standard);
  }
  ngOnInit(): void {}

  // The master checkbox will check/ uncheck all items
  checkUncheckAll(selected, checkList) {
    for (var i = 0; i < checkList.length; i++) {
      checkList[i].isSelected = selected;
    }
    this.getCheckedItemList(checkList);
  }

  // Check All Checkbox Checked
  isAllSelected(selected, checkList) {
    selected = checkList.every(function (item: any) {
      return item.isSelected == true;
    });
    this.getCheckedItemList(checkList);
  }

  // Get List of Checked Items
  getCheckedItemList(checkList) {
    this.checkedList = [];
    for (var i = 0; i < checkList.length; i++) {
      if (checkList[i].isSelected) this.checkedList.push(checkList[i].value);
    }

    this.checkedList = this.checkedList;
    // this.checkedList = JSON.stringify(this.checkedList);
  }
}
