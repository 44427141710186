import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CaisseService } from 'src/app/services/caisse.service';

@Component({
  selector: 'app-caisse-chart',
  templateUrl: './caisse-chart.component.html',
  styleUrls: ['./caisse-chart.component.scss'],
})
export class CaisseChartComponent implements OnInit {
  momentjs: any = moment;
  today = new Date();

  option2 = {
    xAxis: {
      type: 'category',
      data: [
        this.momentjs(this.today).format('DD MMM'),
        this.momentjs(new Date(+new Date() + 1 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() + 2 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() + 3 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() + 4 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() + 5 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() + 6 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() + 7 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() + 8 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() + 9 * 86400000)).format('DD MMM'),
      ],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [150, 230, 224, 218, 135, 147, 260, 300, 356, 233],
        type: 'line',
      },
    ],
    // tooltip: { trigger: 'axis' },
  };

  option = {
    title: {
      text: 'Caisse journalière',
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {},
    toolbox: {
      show: true,
      feature: {
        magicType: { type: ['line', 'bar'] },
        restore: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [
        this.momentjs(new Date(+new Date() - 9 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() - 8 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() - 7 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() - 6 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() - 5 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() - 4 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() - 3 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() - 2 * 86400000)).format('DD MMM'),
        this.momentjs(new Date(+new Date() - 1 * 86400000)).format('DD MMM'),
        this.momentjs(this.today).format('DD MMM'),
      ],
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} Ar',
      },
    },
    series: [
      {
        name: 'Recette',
        type: 'line',
        data: [150, 230, 224, 218, 135, 147, 260, 300, 356, 233],
        markPoint: {
          data: [
            { type: 'max', name: 'Max' },
            { type: 'min', name: 'Min' },
          ],
        },
        markLine: {
          data: [{ type: 'average', name: 'Avg' }],
        },
      },
      {
        name: 'Depense',
        type: 'line',
        data: [
          10000, 35000, 20000, 50000, 30000, 20000, 2500, 12000, 6000, 8000,
        ],
        markPoint: {
          data: [
            { type: 'max', name: 'Max' },
            { type: 'min', name: 'Min' },
          ],
        },
        markLine: {
          data: [{ type: 'average', name: 'Avg' }],
        },
      },
    ],
  };

  constructor(public caisseService: CaisseService) {}
  ngOnInit(): void {
    this.caisseService.caisse10Jour();
    console.log(this.caisseService.totalRec10);
  }

  ionViewDidEnter() {}
}
