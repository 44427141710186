import { UtilsService } from './utils.service';
import {
  AlertController,
  ModalController,
  LoadingController,
} from '@ionic/angular';
import { AccessProviders } from '../providers/access-providers.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { StorageService } from 'src/app/services/storage.service';
import { ProtocoleComponent } from '../components/patients/protocole/protocole.component';
import { ExamenService } from 'src/app/services/examen.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProtocoleService {
  id = null;
  protocoles: any = [];
  filteredData = [];

  momentjs: any = moment;
  isEdit = false;

  protocole = {
    libelle: '',
    clinique: '',
    paraclinique: '',
    cat: '',
  };

  constructor(
    private accessProviders: AccessProviders,
    private alertCtrl: AlertController,
    private utils: UtilsService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private storage: StorageService,
    private examen: ExamenService
  ) {}

  ////////// OBSERVABLES /////////////
  _protocoles = new BehaviorSubject<any[]>([]);
  _isLoading = new BehaviorSubject<boolean>(true);
  _isEmpty = new BehaviorSubject<boolean>(false);

  //////////// ASSECEURS ////////////
  get _protocoles$() {
    return this._protocoles.asObservable();
  }

  get _isLoading$() {
    return this._isLoading.asObservable();
  }

  get _isEmpty$() {
    return this._isEmpty.asObservable();
  }

  //////////// ASSECEURS ////////////

  dismiss() {
    this.modalCtrl.dismiss();
  }

  getData() {
    this.storage.getData('protocole').then((p: any) => {
      this.examen.motif = p.libelle;
      this.examen.clinique = p.clinique;
      this.examen.paraclinique = p.paraclinique;
      this.examen.CAT = p.cat;
    });
  }

  clearInput() {
    this.protocole.libelle = '';
    this.protocole.clinique = '';
    this.protocole.paraclinique = '';
    this.protocole.cat = '';
  }

  loadSingleProtocole(id) {
    return new Promise((_resolve) => {
      const body = {
        action: 'GET_SINGLE',
        id: id,
      };

      this.accessProviders
        .postData(body, 'protocole.php')
        .subscribe((res: any) => {
          for (const data of res.result) {
            this.protocole.libelle = data.libelle;
            this.protocole.clinique = data.clinique;
            this.protocole.paraclinique = data.paraclinique;
            this.protocole.cat = data.cat;
          }
        });
    });
  }

  async crudProtocole(a: any) {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez protocoleez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      const body = {
        action: 'CRUD',
        id: this.id,
        libelle: this.protocole.libelle,
        clinique: this.protocole.clinique,
        paraclinique: this.protocole.paraclinique,
        cat: this.protocole.cat,
        crudAct: a,
      };

      this.accessProviders.postData(body, 'protocole.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            this.loadProtocoles();
            loader.dismiss();
          } else {
            loader.dismiss();
            this.utils.toastError(res.msg);
          }
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }

  refreshData() {
    this.filteredData = [];
    this.protocoles = [];
    this.loadProtocoles();
  }

  async loadProtocoles() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_ALL',
      };

      this.accessProviders
        .postData(body, 'protocole.php')
        .subscribe((res: any) => {
          if (res.result == '') {
            this._isEmpty.next(true);
            this._isLoading.next(false);
          } else {
            this._isEmpty.next(false);
            for (const data of res.result) {
              this.protocoles.push(data);
              this._protocoles.next(this.protocoles);
              this.filteredData = this.protocoles;
              this._isLoading.next(false);
            }
            resolve(true);
          }
        });
    });
  }

  filterList(evt) {
    this._protocoles.next(this.filteredData);
    const searchTerm = evt.srcElement.value;
    if (!searchTerm) {
      return;
    }
    this._protocoles.next(
      this.filteredData.filter(
        (item: {
          libelle: string;
          clinique: string;
          paraclinique: string;
          cat: string;
        }) => {
          return (
            item.libelle.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            item.clinique.toLowerCase().indexOf(searchTerm.toLowerCase()) >
              -1 ||
            item.paraclinique.toLowerCase().indexOf(searchTerm.toLowerCase()) >
              -1 ||
            item.cat.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
          );
        }
      )
    );
  }

  delProtocole(id) {
    this.modalCtrl.dismiss();
    return new Promise(async () => {
      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        message:
          'Voulez-vous vraiment supprimer ce protocole et de toutes ses dossiers ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              // Suppression de ses consultations
              return new Promise(async () => {
                const bodyConsult = {
                  action: 'DELETE',
                  id: id,
                };

                this.accessProviders
                  .postData(bodyConsult, 'protocole.php')
                  .subscribe((res: any) => {
                    if (res.success === true) {
                      this.utils.toast('Suppression éffectuée');
                      this.loadProtocoles();
                      this.openProtocole();
                    } else {
                      this.utils.toastError('Erreur de suppression');
                    }
                  });
              });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });
      await alert.present();
    });
  }

  async openProtocole() {
    const modal = await this.modalCtrl.create({
      component: ProtocoleComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win3-modal-web' : 'win3-modal-mobile',
    });

    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'close') {
      this.getData();
      this.refreshData();
    }
  }
}
