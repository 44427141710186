import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CaisseService } from 'src/app/services/caisse.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-update-creance',
  templateUrl: './update-creance.component.html',
  styleUrls: ['./update-creance.component.scss'],
})
export class UpdateCreanceComponent implements OnInit {
  detail;

  readonly = true;
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public caisseService: CaisseService,
    public utils: UtilsService
  ) {
    this.detail = this.navParams.get('detail');
    this.creance.montant = this.detail.reste;
  }

  creance = {
    date: '',
    montant: '',
    dateR: '',
    remarque: '',
  };
  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

  onItemChange(ev) {
    if (ev.target.checked) {
      this.readonly = false;
    } else this.readonly = true;
  }

  updateCreance() {
    this.caisseService.updateCaisseCreance(
      this.detail.date,
      this.detail.p_id,
      this.creance.dateR,
      this.creance.remarque
    );

    this.caisseService.addCaisse(
      this.creance.dateR,
      this.detail.p_id,
      'Paiment creance du ' + this.utils.formatD(this.detail.date),
      this.creance.montant,
      '',
      this.creance.montant
    );

    this.caisseService.updateCaisseJour(
      this.creance.dateR,
      this.creance.montant
    );

    this.caisseService.loadCreance();
    this.dismiss();
  }
}
