import { UtilsService } from 'src/app/services/utils.service';
import { ConsultationService } from './../../../services/consultation.service';
import { DetailConsultComponent } from './../detail-consult/detail-consult.component';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers.service';
import * as moment from 'moment';

@Component({
  selector: 'app-list-consult',
  templateUrl: './list-consult.component.html',
  styleUrls: ['./list-consult.component.scss'],
})
export class ListConsultComponent implements OnInit {
  momentjs: any = moment;
  isLoading: boolean;
  isEmpty: boolean;

  constructor(
    private loadingCtrl: LoadingController,
    public accessProviders: AccessProviders,
    public platform: Platform,
    public consultationService: ConsultationService,
    public utils: UtilsService,
    private modalCtrl: ModalController
  ) {
    this.utils.checkPlatform();
  }

  async ngOnInit() {
    this.consultationService.automaticClose = true;

    this.consultationService.annees = [];
    this.consultationService.mois$ = [];
    this.consultationService.dates = [];
    this.consultationService.consultations = [];

    this.consultationService.nbConsultation();

    this.consultationService.loadAnnee();
    this.consultationService.loadMois();

    this.consultationService._isEmpty$.subscribe((isEmpty: any) => {
      this.isEmpty = isEmpty;
    });

    this.consultationService._isLoading$.subscribe((isLoading: any) => {
      this.isLoading = isLoading;
    });
  }

  ionViewDidEnter() {
    // this.loadDateConsultation();
    this.consultationService.annees = [];
    this.consultationService.mois$ = [];
    this.consultationService.dates = [];
    this.consultationService.consultations = [];

    this.consultationService.nbConsultation();

    this.consultationService.loadAnnee();
    this.consultationService.loadMois();

    this.utils.getSession();
  }

  async doRefresh(event) {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    loader.present();

    this.ionViewDidEnter();
    event.target.complete();

    loader.dismiss();
  }

  async openDetail(consult) {
    const modal = await this.modalCtrl.create({
      component: DetailConsultComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        consult: consult,
        showPrescr: true,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win4-modal-web' : 'win4-modal-mobile',
    });
    await modal.present();
  }

  // async showOptions(ev, id) {
  //   let pop = await this.popCtrl.create({
  //     component: OptionPage,
  //     event: ev,
  //     componentProps: {
  //       custom_id: id,
  //       data: this.consultations
  //     }
  //   });
  //   await pop.present();
  // }

  refresh() {
    this.ngOnInit();
  }
}
