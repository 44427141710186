import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RMAService } from 'src/app/services/rma.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-search-mc',
  templateUrl: './search-mc.component.html',
  styleUrls: ['./search-mc.component.scss'],
})
export class SearchMCComponent implements OnInit {
  item = {
    icon: 'search-outline',
    title: 'Diagnostic non trouvé',
  };

  searchInput = '';
  diagnostics = [];
  constructor(
    public utils: UtilsService,
    private modalCtrl: ModalController,
    private storage: StorageService,
    public rmaService: RMAService
  ) {
    this.rmaService.getDiagMC();
    this.utils.checkPlatform();
  }

  ngOnInit(): void {
    // Recupère le compte : Administrateur ou SECRETAIRE
    this.utils.getSession();

    this.rmaService._diagnostics$.subscribe((diagnostics: any[]) => {
      this.diagnostics = diagnostics;
    });
  }

  async ionViewDidEnter() {
    this.rmaService.getDiagMC();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  addDiag(diag) {
    this.storage.setData('diag', diag);

    this.modalCtrl.dismiss(null, 'crud');
  }

  search(ev) {
    this.searchInput = ev.target.value;
  }
}
