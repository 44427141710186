import { WaitService } from './services/wait.service';
import { ThemeService } from './services/theme.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { StorageService } from './services/storage.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// Execute a function only when certain events on certain target element have or have not occured within given timeout.
import { Idle } from 'idlejs';
import { UtilsService } from './services/utils.service';
import { Subscription } from 'rxjs';
// import { SwUpdate } from '@angular/service-worker';
import { Keyboard } from '@ionic-native/keyboard/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  selectedTheme: String;

  public medMenus = [
    { title: 'Tableau de Bord', url: '/dashboard', icon: 'grid' },
    { title: "Salle d'attente", url: '/salle-attente', icon: 'alarm' },
    { title: 'Patients', url: '/patients', icon: 'people' },
    { title: 'Dossier Medical', url: '/dossier-medical', icon: 'folder' },
    // { title: 'Examen/Certificat', url: '/labo-cm', icon: 'reader' },
    { title: 'Consultations', url: '/consultations', icon: 'medkit' },
    { title: 'Rapports', url: '/rapport', icon: 'book' },
    { title: 'Rendez-vous', url: '/appointment', icon: 'calendar' },
    // { title: 'Caisse', url: '/caisse', icon: 'cash' },
    // { title: 'Stocks', url: '/stock', icon: 'server' },
    // { title: 'Médicaments', url: '/medicaments', icon: 'medkit' },
    { title: 'Utilisateurs', url: '/utilisateurs', icon: 'person-circle' },
    { title: 'Paramètres', url: '/settings', icon: 'settings' },
  ];

  // SECRETAIRE
  public secMenus = [
    { title: 'Tableau de Bord', url: '/dashboard', icon: 'grid' },
    {
      title: "Salle d'attente",
      url: '/salle-attente',
      icon: 'alarm',
    },
    { title: 'Patients', url: '/patients', icon: 'people' },
    { title: 'Rapports', url: '/rapport', icon: 'book' },
    {
      title: 'Rendez-vous',
      url: '/appointment',
      icon: 'calendar',
    },
    // { title: 'Caisse', url: '/caisse', icon: 'cash' },
    // { title: 'Stocks', url: '/stock', icon: 'server' },
    // { title: 'Médicaments', url: '/medicaments', icon: 'medkit' },
    { title: 'Utilisateurs', url: '/utilisateurs', icon: 'person-circle' },
    { title: 'Paramètres', url: '/settings', icon: 'settings' },
  ];

  // CAISSIERE
  public caisseMenus = [
    { title: 'Tableau de Bord', url: '/dashboard', icon: 'grid' },
    // { title: 'Caisse', url: '/caisse', icon: 'cash' },
    // { title: 'Stocks', url: '/stock', icon: 'server' },
    // { title: 'Médicaments', url: '/medicaments', icon: 'medkit' },
    { title: 'Utilisateurs', url: '/utilisateurs', icon: 'person-circle' },
    { title: 'Paramètres', url: '/settings', icon: 'settings' },
  ];

  themeData: any;

  nbWait = 0;

  constructor(
    // private swUpdate: SwUpdate,
    private router: Router,
    private storageService: StorageService,
    private alertCtrl: AlertController,
    private themeService: ThemeService,
    public utils: UtilsService,
    public waitService: WaitService
  ) {
    this.themeService
      .getActiveTheme()
      .subscribe((val) => (this.selectedTheme = val));

    // this.storageService.getData('url').then((data: any) => {
    //   if (data == null) {
    //     storageService.setData('url', '127.0.0.1');
    //   }
    // });

    this.initializeApp();
    this.utils.getSession();
    // this.getStorage();

    this.waitService.nbWaitingRoom(this.utils.currentDate);

    this.waitService._nbWait$.subscribe((waiting: any) => {
      this.nbWait = waiting;
    });
  }

  ionViewDidLoad() {
    SplashScreen.hide();
  }

  initializeApp() {
    // if (this.swUpdate.isEnabled) {
    //   this.swUpdate.available.subscribe(() => {
    //     // if (confirm('A new version is available. Load it?'))
    //     // window.location.reload();
    //     // });
    //   });
    // }

    // this.utils.checkInteractive(this.logout());

    this.themeData = localStorage.getItem('theme');
    if (this.themeData == null) {
      document.body.setAttribute('color-theme', 'light');
    } else {
      document.body.setAttribute('color-theme', this.themeData);
    }
  }

  getStorage() {
    this.storageService.getData('storage_session').then((data: any) => {
      if (data == null) {
        this.router.navigateByUrl('/auth');
      } else {
        this.router.navigateByUrl('/dashboard');

        // localStorage.setItem('userId', data.id);
        // localStorage.setItem('name', data.name);
        // localStorage.setItem('agentSante', data.agentSante);
        // localStorage.setItem('compte', data.compte);
        // localStorage.setItem('agentSante', data.agent_sante);
        // localStorage.setItem('medecin', data.medecin);
        // localStorage.setItem('onm', data.onm);

        // this.utils.compte = data.compte;
      }
    });
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Focus GCM',
      message: "Voulez-vous vraiment quitter l'application ?",
      backdropDismiss: false,
      cssClass: 'alert-class',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Quitter',
          handler: () => {
            navigator['app'].exitApp();
          },
        },
      ],
    });

    await alert.present();
  }

  logout() {
    // this.storageService.removeItem('storage_session');
    this.storageService.clearData();
    localStorage.clear();
    this.router.navigateByUrl('/auth');
  }
}
