import { AccessProviders } from './../providers/access-providers.service';
import { UtilsService } from './utils.service';
import {
  ModalController,
  LoadingController,
  AlertController,
} from '@ionic/angular';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { SmsComponent } from '../components/appointments/sms/sms.component';
import { RdvComponent } from '../components/appointments/rdv/rdv.component';
import { AddEventComponent } from '../components/appointments/add-event/add-event.component';

import { CalendarEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { Subject } from 'rxjs';
import { colors } from '../utils/colors';
import { AssignRdvComponent } from '../components/appointments/assign-rdv/assign-rdv.component';
import { UpdateEventComponent } from './../components/appointments/update-event/update-event.component';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  user_id;
  activeIndex = -1;
  event: any; // Detail Event
  status: string;

  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  appointments = [];
  activeDayIsOpen: boolean = false;

  refresh = new Subject<void>();

  nbUser: string;
  isLoading = true;
  // isEmpty = false;
  filteredData = [];
  temp = [];
  momentjs: any = moment;

  id: any;
  date;

  rdv: any;
  doctor: any;

  appointment = {
    doc_id: '',
    p_id: '',
    // type:"",
    description: '',
    time: '',
    statut: '',
  };

  constructor(
    private utils: UtilsService,
    private modalCtrl: ModalController,
    private accessProviders: AccessProviders,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController
  ) {}

  dismiss() {
    this.modalCtrl.dismiss(null, 'close');
  }

  clearInput() {
    this.appointment.doc_id = '';
    this.appointment.p_id = '';
    this.appointment.description = '';
    this.appointment.time = '';
    this.appointment.statut = '';
  }
  loadSingleApp() {
    return new Promise((_resolve) => {
      const body = {
        action: 'GET_SINGLE_APP',
        id: this.id,
      };

      this.accessProviders
        .postData(body, 'appointment.php')
        .subscribe((res: any) => {
          for (const data of res.result) {
            console.log(data);

            this.appointment.p_id = data.p_id;
            this.appointment.doc_id = data.doc_id;
            this.date = this.utils.formatDate(data.dateTime);
            this.appointment.time = this.utils.formatHeure(data.dateTime);
            // this.appointment.type = data.type,
            this.appointment.description = data.description;
            this.appointment.statut = data.statut;
          }
        });
    });
  }

  async assignRDV(a: any) {
    if (this.doctor === '') {
      this.utils.toastError('Médecin obligatoire');
    } else {
      const loader = await this.loadingCtrl.create({
        message: 'Veuillez patientez ...',
        spinner: 'bubbles',
      });
      await loader.present();

      return new Promise(() => {
        const body = {
          action: 'CRUD',
          affiliation_id: this.rdv.p_id,
          user_id: this.doctor,
          dateTime: this.rdv.dateTime,
          type: this.rdv.type,
          description: this.rdv.description,
          status: 1,
          crudAct: a,
        };

        this.accessProviders.postData(body, 'appointment.php').subscribe(
          (res: any) => {
            if (res.success === true) {
              loader.dismiss();
              this.delRDV(this.rdv.id);
              this.modalCtrl.dismiss(null, 'crud');
            } else {
              loader.dismiss();
              this.utils.toastError(res.msg);
            }
          },
          (_err) => {
            console.log(_err);
            loader.dismiss();
            this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
          }
        );
      });
    }
  }

  delRDV(id) {
    return new Promise(async (_resolve) => {
      const body = {
        action: 'DELETE_RDV',
        id: id,
      };
      this.accessProviders
        .postData(body, 'appointment.php')
        .subscribe((res: any) => {
          if (res.success === true) {
            // this.utils.toast('Suppression éffectuée');
            this.dismiss();
          } else {
            // this.utils.toastError('Erreur de suppression');
          }
        });
    });
  }

  async crudAction(a: any) {
    const loader = await this.loadingCtrl.create({
      message: 'Veuillez patientez ...',
      spinner: 'bubbles',
    });
    await loader.present();

    return new Promise(() => {
      const body = {
        action: 'CRUD',
        id: this.id,
        p_id: this.appointment.p_id,
        user_id: this.appointment.doc_id,
        dateTime:
          this.utils.formatDate(this.date) + ' ' + this.appointment.time,
        description: this.appointment.description,
        statut: this.appointment.statut,
        crudAct: a,
      };

      this.accessProviders.postData(body, 'appointment.php').subscribe(
        (res: any) => {
          if (res.success === true) {
            loader.dismiss();
            this.clearInput();
            this.modalCtrl.dismiss(null, 'crud');
          } else {
            loader.dismiss();
            this.utils.toastError(res.msg);
          }
        },
        (_err) => {
          console.log(_err);
          loader.dismiss();
          this.utils.presentAlert('Focus GCM', "Erreur d'enregistrement");
        }
      );
    });
  }

  async openRDV() {
    const modal = await this.modalCtrl.create({
      component: RdvComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win8-modal-web' : 'win8-modal-mobile',
    });
    await modal.present();
  }

  async openSMS() {
    const modal = await this.modalCtrl.create({
      component: SmsComponent,
      animated: true,
      mode: 'ios',
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win1-detail-web' : 'win1-detail-mobile',
    });
    await modal.present();
  }

  sendId(id) {
    localStorage.setItem('user_id', id);
  }

  async addEvent(date) {
    const modal = await this.modalCtrl.create({
      component: AddEventComponent,
      animated: true,
      mode: 'ios',
      componentProps: { date: date, id: 0 },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win5-modal-web' : 'win5-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.fetchEvents();
    }
  }

  getEvents(id) {
    new Promise((resolve) => {
      const body = {
        action: 'GET_ALL_APP_USER',
        user_id: id,
      };

      this.accessProviders
        .postData(body, 'appointment.php')
        .subscribe((res: any) => {
          this.events = [];
          this.appointments = [];
          for (const data of res.result) {
            this.appointments.push(data);
          }

          this.isLoading = false;
          // if ((this.appointments = [])) {
          // } else {
          for (let index = 0; index < this.appointments.length; index++) {
            const app = this.appointments[index];
            const p = '<b><i>' + app.p_id + '</i></b>'; // Patient
            var title = '';

            title =
              'Rendez-vous de ' +
              p +
              ' à ' +
              '<b><u>' +
              this.utils.formatHeure(app.dateTime) +
              '</u></b>';

            this.events.push({
              title: title,
              color: colors.blue,
              start: new Date(app.dateTime),
              meta: {
                appointment: app,
              },
            });
          }
          // }

          resolve(true);
          this.refresh.next();
        });
    });
  }

  fetchEvents(): void {
    this.activeIndex = -1; // Deselectionne les personnels de Sante
    new Promise((resolve) => {
      const body = {
        action: 'GET_ALL_APP',
      };

      this.accessProviders
        .postData(body, 'appointment.php')
        .subscribe((res: any) => {
          this.events = [];
          this.appointments = [];
          for (const data of res.result) {
            this.appointments.push(data);
            this.isLoading = false;
          }

          for (let index = 0; index < this.appointments.length; index++) {
            const app = this.appointments[index];

            const s = '<b>' + app.name + '</b>'; // Professionel de sante
            const p = '<b><i>' + app.p_id + '</i></b>'; // Patient
            var title = '';

            title =
              s +
              ' : Rendez-vous avec ' +
              p +
              ' à ' +
              '<b><u>' +
              this.utils.formatHeure(app.dateTime) +
              '</u></b>';

            this.events.push({
              title: title,
              color: colors.blue,
              start: new Date(app.dateTime),
              meta: {
                appointment: app,
              },
            });
          }
          this.refresh.next();
          resolve(true);
        });
    });
  }

  // DETAIL EVENT
  getStatus() {
    switch (this.event.meta.appointment.statut) {
      case '0':
        this.status = 'En attente';
        break;
      case '1':
        this.status = 'Confirmé';
        break;
      case '2':
        this.status = 'Reprogrammé';
        break;
      case '3':
        this.status = 'Fait';
        break;
      case '4':
        this.status = 'Annulé';
        break;
      default:
        this.status = 'Inconnu';
        break;
    }
  }

  async editEvent(appID) {
    this.dismiss();
    const modal = await this.modalCtrl.create({
      component: UpdateEventComponent,
      animated: true,
      mode: 'ios',
      componentProps: { id: appID },
      backdropDismiss: true,
      cssClass: this.utils.isDesktop ? 'win6-modal-web' : 'win6-modal-mobile',
    });
    await modal.present();
  }

  deleteEvent(id) {
    this.dismiss();
    return new Promise(async () => {
      const alert = await this.alertCtrl.create({
        header: 'Suppression',
        message: 'Voulez-vous vraiment supprimer ce Rendez-vous ?',
        backdropDismiss: false,
        cssClass: 'alert-class',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              return new Promise(async () => {
                const body = {
                  action: 'DELETE',
                  id: id,
                };
                this.accessProviders
                  .postData(body, 'appointment.php')
                  .subscribe((res: any) => {
                    if (res.success === true) {
                      this.utils.toast('Suppression éffectuée');
                      this.fetchEvents();
                    } else {
                      this.utils.toastError('Erreur de suppression');
                    }
                  });
              });
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
            handler: (_blah) => {},
          },
        ],
      });
      await alert.present();
    });
  }

  // RDV
  async loadRDV() {
    return new Promise((resolve) => {
      const body = {
        action: 'GET_RDV',
      };

      this.accessProviders
        .postData(body, 'appointment.php')
        .subscribe((res: any) => {
          this.rdv = [];
          for (const data of res.result) {
            this.rdv.push(data);
            this.isLoading = false;
          }
          resolve(true);
        });
    });
  }

  async openAssignRDV(rdv) {
    this.dismiss();

    const modal = await this.modalCtrl.create({
      component: AssignRdvComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        rdv: rdv,
      },
      backdropDismiss: false,
      cssClass: this.utils.isDesktop ? 'win5-modal-web' : 'win5-modal-mobile',
    });
    await modal.present();

    const data = await modal.onWillDismiss();

    if (data.role == 'crud') {
      this.loadRDV();
    }
  }

  delRdv(id) {
    // TODO
  }
}
